import {
  FC,
  SyntheticEvent,
  useCallback,
  useState,
  useMemo,
  useEffect,
  HTMLAttributes,
} from "react";
import { TextField, ThemeProvider, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { sortBy } from "lodash";
import { useAuthContext } from "../../../../context/AuthContext";
import {
  OrgData,
  useFindAlertTemplatesQuery,
} from "../../../../graphql/operations";
import { ActionDialog } from "../../../../shared/components/ActionDialog/ActionDialog";
import { isSuperAdmin } from "../../../../shared/components/WithPermissions";
import { useFormTheme } from "../../../../shared/hooks/theme/useFormTheme";
import { useAvailableOrgs } from "../../../../shared/hooks/useAvailableOrgs";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../AssetsView/TableView/hooks";

interface OptionType {
  label: string;
  id: string;
  key: string;
  isCustom: boolean;
  templateId?: string;
}

interface ICreateDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (type: any, isCustomTemplate: boolean, templateId?: string) => void;
}

export const CreateAlertDialog: FC<ICreateDialogProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const formTheme = useFormTheme();
  const name = "create-alert";
  const dialogTitle = "Add New Alert";
  const dialogTextContent = "Please select the alert type from the list below";
  const dialogSelectLabel = "Alert Type";

  const [alertType, setAlertType] = useState<any | undefined>();
  const [templateId, setTemplateId] = useState<string | undefined>();
  const [isCustomTemplate, setIsCustomTemplate] = useState(false);
  const alertDefaultTypes = useNomenclatures(NOMENCLATURE_NAMES.alertType).map(
    (x) => ({ id: x.label, label: x.value, key: x.label, isCustom: false })
  );
  const [alertCustomTypeOptions, setAlertCustomTypeOptions] = useState<
    OptionType[]
  >([]);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const { userInfo } = useAuthContext();
  const availableOrgs: OrgData[] = useAvailableOrgs();
  const isSuperAdminUser = isSuperAdmin(userInfo?.groups || []);

  const { data: alertTemplates, isLoading: isAlertTemplatesLoading } =
    useFindAlertTemplatesQuery({ input: { skip: 0, limit: 1000 } });

  const availableFeatures = useMemo(
    () => [
      "Geofence",
      "Cargo",
      "Dwell",
      "Custom",
      "Moving without Primary or Secondary Power",
      "Speeding",
      "Wheel End",
      ...alertCustomTypeOptions.map((x) => x.label),
    ],
    [alertCustomTypeOptions]
  );

  const handleClose = useCallback(() => {
    setAlertType(undefined);
    setDisplayErrorMessage(false);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    if (alertType) {
      onSubmit(alertType, isCustomTemplate, templateId);
      handleClose();
    } else {
      setDisplayErrorMessage(true);
    }
  }, [onSubmit, alertType, handleClose, templateId, isCustomTemplate]);

  const isOptionEqualToValue = useCallback(
    (option: OptionType, value: OptionType) => {
      return option.id === value.id && option.templateId === value.templateId;
    },
    []
  );

  const handleSelectReportType = useCallback(
    (_event: SyntheticEvent, option: OptionType) => {
      setAlertType(option.id);
      setTemplateId(option.templateId);
      setIsCustomTemplate(option.isCustom);
      setDisplayErrorMessage(false);
    },
    [setAlertType, setDisplayErrorMessage]
  );

  const isOptionDisabled = useCallback(
    (option: OptionType) => !availableFeatures.includes(option.label),
    [availableFeatures] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const isSubmitDisabled = !alertType;

  useEffect(() => {
    if (alertTemplates?.findAlertTemplates?.alertTemplates) {
      const templates =
        alertTemplates?.findAlertTemplates?.alertTemplates || [];
      const customAlertTemplates = templates.map((x) => ({
        id: x.type,
        label: x.name,
        key: x._id,
        templateId: x._id,
        isCustom: true,
      }));
      setAlertCustomTypeOptions(customAlertTemplates);
    }
  }, [alertTemplates, isSuperAdminUser, availableOrgs]);

  const sortedAlertCustomTypeOptions = sortBy(alertCustomTypeOptions, "label");
  const sortedAlertDefaultTypes = sortBy(alertDefaultTypes, "label");

  return (
    <ActionDialog
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      name={name}
      title={dialogTitle}
      textContent={dialogTextContent}
      submitButtonText="Add Alert"
      isSubmitDisabled={isSubmitDisabled}
    >
      <ThemeProvider theme={formTheme}>
        <Typography
          data-testid={`${name}-type-autocomplete-label`}
          className="!mb-1 !text-sm !font-bold"
        >
          {dialogSelectLabel}
        </Typography>
        <Autocomplete
          loading={isSuperAdminUser ? isAlertTemplatesLoading : false}
          options={[
            ...sortedAlertDefaultTypes,
            ...sortedAlertCustomTypeOptions,
          ]}
          groupBy={(option) =>
            option.isCustom ? "Custom alerts" : "Built-in alerts"
          }
          renderOption={(
            props: HTMLAttributes<HTMLLIElement>,
            option: OptionType
          ) => {
            return (
              <li {...props} key={option.key}>
                <p className="ml-1">{option.label}</p>
              </li>
            );
          }}
          getOptionDisabled={isOptionDisabled}
          isOptionEqualToValue={isOptionEqualToValue}
          data-testid={`${name}-type-autocomplete`}
          onChange={handleSelectReportType}
          disableClearable
          renderInput={(params) => (
            <TextField
              error={displayErrorMessage}
              helperText={
                displayErrorMessage
                  ? "Please make a selection before continuing"
                  : ""
              }
              {...params}
              data-testid={`${name}-type-autocomplete-field`}
              variant="standard"
            />
          )}
        />
      </ThemeProvider>
    </ActionDialog>
  );
};
