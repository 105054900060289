import { useCallback, memo, ReactNode } from "react";
import { Box, Button } from "@mui/material";
import classNames from "classnames";
import { isArray } from "lodash";
import { SelectedValueType } from "../../../views/AssetsView/shared/AssetsFilterControls/Filters/AssetFilters/AssetFilters";

export type ToggleButtonOption = {
  label: string;
  subLabel?: string;
  value: string | number | Date | boolean | null | undefined;
  style?: string;
  isFalsy?: boolean;
  icon?: ReactNode;
};

const ToggleButtons = ({
  id,
  value,
  options,
  onChange,
  outlined,
  parentStyle,
  buttonStyle,
  customColor,
}: {
  id?: string;
  value: SelectedValueType;
  options: ToggleButtonOption[];
  onChange: (value: SelectedValueType) => void;
  outlined?: boolean;
  parentStyle?: string;
  buttonStyle?: string;
  customColor?: string;
}) => {
  const isSelected = useCallback(
    (option: ToggleButtonOption) =>
      (isArray(value) && value.includes(option.value)) ||
      (!isArray(value) && value === option.value),
    [value]
  );

  const onOptionClick = useCallback(
    (option: ToggleButtonOption) => {
      let newValue: SelectedValueType;
      if (isSelected(option)) {
        if (isArray(value)) {
          newValue = value.filter((v) => v !== option.value);
        }
      } else {
        if (isArray(value)) {
          newValue = [...value, option.value];
        } else {
          newValue = option.value;
        }
      }
      onChange(newValue);
    },
    [isSelected, onChange, value]
  );

  return (
    <Box className={parentStyle ?? "grid grid-cols-2 gap-3"} data-testid={id}>
      {options.map((option) => (
        <Button
          key={option.label}
          data-title={option.label}
          data-testid={option.label}
          variant={outlined ? "outlined" : "contained"}
          className={
            buttonStyle ??
            classNames("!rounded-[4px] !normal-case !text-white", {
              "!bg-brand !font-bold": isSelected(option),
              "!bg-toggle-button": !isSelected(option),
            })
          }
          sx={{
            color: isSelected(option)
              ? "white"
              : (option.style || customColor) ?? "white",
            fontWeight: isSelected(option) ? "bold !important" : "normal",
            borderColor: isSelected(option) ? "transparent" : option.style,
            backgroundColor: isSelected(option)
              ? "var(--primary-blue)"
              : "transparent",
            "&:hover": {
              backgroundColor: isSelected(option)
                ? "var(--primary-blue-transparent-80)"
                : "rgba(0, 0, 0, 0.04)",
            },
          }}
          onClick={() => onOptionClick(option)}
        >
          {option.label}
        </Button>
      ))}
    </Box>
  );
};

export default memo(ToggleButtons);
