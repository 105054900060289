import {
  FC,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box } from "@mui/system";
import {
  GridFilterModel,
  GridRowParams,
  GridSortDirection,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import isUndefined from "lodash/isUndefined";
import { PAGE_SNACKBAR } from "../../../../constants";
import { useAppContext } from "../../../../context/AppContext";
import {
  Automation,
  FilterListInput,
  FiltersInput,
  useGetAutomationsQuery,
  TableViewType,
  TableSortingInput,
  SortOrder,
} from "../../../../graphql/operations";
import { getRows, Table } from "../../../../shared/components/Table";
import { useExportedFileName } from "../../../../shared/hooks/useExportedFileName";
import { validateTableFilters } from "../../../../utils/validateTableFilters";
import {
  automationColumns,
  automationsVisibleColumns,
  searchKeys,
} from "./AutomationsUtils";

export type automationTableProps = {
  onRowClick: (value: GridRowParams) => void;
  refreshAutomations: MutableRefObject<Function>;
  apiRef: MutableRefObject<GridApiPremium>;
};

const AutomationsTable: FC<automationTableProps> = (
  props: automationTableProps
) => {
  const [tableRows, setTableRows] = useState<Automation[]>([]);
  const [filters, setFilters] = useState<FilterListInput | null>(null);

  const [sort, setSort] = useState<TableSortingInput | null>(null);
  const [paginationSkip, setPaginationSkip] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 100;

  const fileName = useExportedFileName("Automations");
  const { onRowClick, apiRef } = props;

  const {
    state: { appConfig },
    dispatch,
  } = useAppContext();

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      const sort = sortModel.map((sortItem) => ({
        field: sortItem.field,
        order: sortItem.sort as SortOrder,
      }));
      setSort(sort[0]);
    },
    [setSort]
  );

  const onFilterChange = useCallback(
    (data: { items: FiltersInput[]; linkOperator: string }) => {
      setCurrentPage(1);
      setPaginationSkip(0);

      if (data.items && !data.items?.length) {
        setFilters(null);
      }

      const newFilters = validateTableFilters(data);
      if (newFilters) {
        setFilters(newFilters);
      }
    },
    []
  );

  const onPageChange = useCallback((page: number) => {
    setCurrentPage(page);
    setPaginationSkip(pageSize * (page - 1));
  }, []);

  const {
    data: automationsData,
    isSuccess,
    isLoading: isDataLoading,
    refetch: refetchAutomationsList,
  } = useGetAutomationsQuery(
    {
      input: {
        search: "" || undefined,
        sort,
        skip: paginationSkip,
        limit: pageSize,
        filterList: filters ? [filters] : null,
      },
    },
    {
      onSettled: (_, error: unknown) => {
        if (error) {
          const displayError = error
            ? (error as { message: string }).message
            : "Something Went Wrong.";
          dispatch({
            type: PAGE_SNACKBAR,
            payload: {
              title: "Automations load failed!",
              text: displayError,
              severity: "error",
            },
          });
        }
      },
    }
  );

  useEffect(() => {
    props.refreshAutomations.current = refetchAutomationsList;
  }, [refetchAutomationsList, props]);

  useMemo(() => {
    const automations = automationsData?.getAutomations ?? [];

    setTableRows(
      getRows<typeof automations>(automations, automationColumns, "", "", "_id")
    );
    return automations;
  }, [automationsData?.getAutomations]);

  return (
    <Box className="w-full h-[100%] pb-4">
      <Table
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterChange}
        onPageChange={onPageChange}
        currentPage={currentPage}
        data-testid="automations-table"
        tableType={TableViewType.Automations}
        showToolbar
        onRowClick={onRowClick}
        rows={tableRows ?? []}
        apiRef={apiRef}
        rowCount={tableRows?.length ?? 0}
        columns={automationColumns}
        loading={isDataLoading}
        error={!isDataLoading && !isSuccess ? true : null}
        searchThreshold={0.1}
        columnVisibilityModel={automationsVisibleColumns}
        searchExactMatch
        enableSearch
        disableRowGrouping
        pagination={true}
        searchKeys={searchKeys}
        sortKeys={searchKeys}
        searchMinLength={appConfig.searchMinLength}
        tableName="automations"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
      />
    </Box>
  );
};

export default memo(AutomationsTable);
