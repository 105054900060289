import { memo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { confirmForgotPassword, forgotPassword } from "../../services/aws/auth";
import Logo from "../../shared/components/Svg/Logo";
import { NavigationRoutes } from "../../utils/routes/routesUtils";

const CONFIRMATION_CODE_LENGTH = 6;

export enum ConfirmPasswordReason {
  Forgot = "forgot",
  Reset = "reset",
}

const ConfirmForgotPassword = () => {
  const [confirmationCode, setConfirmationCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username") ?? "";
  const reason = searchParams.get("reason") ?? ConfirmPasswordReason.Forgot;
  const passwordsMatch = password === confirmPassword;
  const passwordBorderColor = passwordsMatch ? "border-black" : "border-error";
  const codeBorderColor =
    !confirmationCode || confirmationCode.length === CONFIRMATION_CODE_LENGTH
      ? "border-black"
      : "border-error";
  const validPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{6,})/;

  const validateForm = () => {
    setError("");

    if (!username) {
      setError("Username is missing");
      return false;
    }

    if (confirmationCode.length !== CONFIRMATION_CODE_LENGTH) {
      setError(
        `Confirmation code must be exactly ${CONFIRMATION_CODE_LENGTH} characters`
      );
      return false;
    }

    if (!validPasswordRegex.test(password)) {
      setError(
        "Password must contain at least 6 characters, one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*)"
      );
      return false;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return false;
    }

    return true;
  };

  const handleConfirmPasswordReset = async () => {
    const isValid = validateForm();
    if (!isValid) return;

    try {
      await confirmForgotPassword(username, confirmationCode, password);
      navigate(NavigationRoutes.CustomLogin);
    } catch (error) {
      error instanceof Error
        ? setError(error.message)
        : setError("An unknown error occurred");
    }
  };

  const sendVerificationCode = async () => {
    await forgotPassword(username);
  };

  const title =
    reason === ConfirmPasswordReason.Forgot
      ? "Forgot your password?"
      : "Reset your password";

  return (
    <Box className="w-full h-full flex flex-col justify-center items-center bg-gray">
      <Box className="w-64 h-full flex flex-col justify-center items-center bg-gray">
        <Logo />
        <Box className="mt-16 text-lg font-medium">{title}</Box>
        <Box className="my-4 text-sm font-thin">
          Please enter the verification code from your E-mail together with your
          new password
        </Box>
        {error && (
          <Box className="mb-4 text-sm font-medium text-error">{error}</Box>
        )}
        <Box className="flex flex-col justify-center w-[200px]">
          <input
            className={`mb-2 px-2 w-[200px] h-[40px] text-sm bg-gray rounded border ${codeBorderColor}`}
            data-testid="forgot-pwd-code"
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
            placeholder="Confirmation code"
            autoComplete="one-time-code"
            required
          />
          <input
            className={`mb-2 px-2 w-[200px] h-[40px] text-sm bg-gray rounded border ${passwordBorderColor}`}
            data-testid="forgot-pwd-password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="New password"
            autoComplete="new-password"
            required
          />
          <input
            className={`mb-2 px-2 w-[200px] h-[40px] text-sm bg-gray rounded border ${passwordBorderColor}`}
            data-testid="forgot-pwd-confirmation"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm new password"
            autoComplete="new-password"
            required
          />
          <button
            className="mb-2 h-[40px] rounded bg-primary-blue text-white hover:bg-brand-light-blue"
            onClick={handleConfirmPasswordReset}
          >
            Reset my password
          </button>
          <button
            className="mb-2 h-[40px] rounded bg-primary-blue text-white hover:bg-brand-light-blue"
            onClick={sendVerificationCode}
          >
            Send verification code
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ConfirmForgotPassword);
