import { Box, Divider, Icon, Typography } from "@mui/material";
import { TooltipProps } from "recharts";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import { TooltipLabelMapping } from "../../../../../enums/chartsTooltipMapping";
import { DATE_FORMAT, formatDateToUTC } from "../../../../../utils";
import { convertKmToMiles } from "../../../../../utils/convertUnits";
import { formatNumber } from "../../../../../utils/formatters";
import { colorMap } from "../../../../DashboardsView/DashboardView/components/widgets/ReportingDevicesPerPeriodWidget";

export enum ChartType {
  TripDetails = "tripDetails",
  OdometerTrends = "odometerTrends",
  ReportingDevicesPerPeriod = "reportingDevicesPerPeriod",
  AlertsHistogram = "alertsHistogram",
}

export interface ChartTooltipProps {
  type: ChartType;
  payload?: TooltipProps<string, string>["payload"];
}

export const ChartTooltip: React.FC<ChartTooltipProps> = ({
  type,
  payload,
}) => {
  if (!payload?.length) {
    return null;
  }

  const getReportingDevicesPerPeriodTooltipTitle = () => {
    const value = payload?.reduce((acc, { value }) => {
      acc += Number(value ?? 0);
      return acc;
    }, 0);
    return `${formatNumber(value ?? 0)} Devices`;
  };

  const generateTooltipTitle = () => {
    switch (type) {
      case ChartType.ReportingDevicesPerPeriod: {
        payload = payload?.reverse();
        return (
          <>
            <Typography
              className="!text-lg !font-semibold !leading-3 !ml-4"
              data-testid={`chart-tooltip-label-${type}`}
            >
              {getReportingDevicesPerPeriodTooltipTitle()}
            </Typography>
            <Divider className="w-full !mt-2" />
          </>
        );
      }
      case ChartType.AlertsHistogram:
        return (
          <Typography
            className="!text-xs !text-sub-header-text !font-medium !leading-3"
            data-testid={`chart-tooltip-label-${type}-title`}
          >
            {formatDateToUTC(new Date(payload?.[0].payload?.date)).toFormat(
              DATE_FORMAT
            )}
          </Typography>
        );
      default:
        return (
          <Typography
            className="!text-xs !text-sub-header-text !font-medium !leading-3"
            data-testid={`chart-tooltip-label-${type}-title`}
          >
            {payload?.[0].payload?.date}
          </Typography>
        );
    }
  };

  const generateTooltipValue = (item: Payload<string, string>) => {
    switch (type) {
      case ChartType.ReportingDevicesPerPeriod: {
        return (
          <Typography className="!mr-4">
            <Icon
              sx={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                bgcolor: `var(--${colorMap[item?.dataKey as string]})`,
                marginRight: "8px",
              }}
            />
            {`${item?.dataKey} - ${formatNumber(Number(item?.value ?? 0))}
            devices`}
          </Typography>
        );
      }
      case ChartType.TripDetails: {
        const value = Number(item?.value);
        const hours = (!isNaN(value) ? value : 0).toFixed(1);
        return `${hours} hours`;
      }
      case ChartType.OdometerTrends: {
        const value = Number(item?.value);
        const miles = convertKmToMiles(!isNaN(value) ? value : 0);
        return `${miles} mi`;
      }
      case ChartType.AlertsHistogram: {
        return formatNumber(Number(item?.value ?? 0));
      }
      default:
        return "";
    }
  };

  return (
    <Box className="rounded-lg bg-custom-tooltip-background border border-sub-header-border p-3 flex flex-col gap-3">
      <Typography
        className="!text-xs !text-sub-header-text !font-medium !leading-3"
        data-testid={`chart-tooltip-label-${type}`}
      >
        {generateTooltipTitle()}
      </Typography>

      {payload.map((item, index) => {
        if (!item) return null;

        const label =
          TooltipLabelMapping[
            item?.dataKey as keyof typeof TooltipLabelMapping
          ];

        return (
          <Box
            key={item.dataKey}
            className="flex items-center gap-2"
            data-testid={`chart-tooltip-value-${type}-${index}`}
          >
            <Typography className="!text-xs !text-sub-header-text !font-medium !leading-3">
              {label}
            </Typography>
            <Typography className="!text-xs !text-sub-header-text !font-black !leading-3">
              {generateTooltipValue(item)}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
