import {
  GetAssetsTagsInput,
  useGetAssetsTagsOsQuery,
  useGetAssetsTagsQuery,
} from "../../../graphql/operations";
import { useFeatureFlag } from "../../../utils";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";

export const useGetAssetsTags = (
  input: GetAssetsTagsInput
): {
  tags: string[];
  isLoading: boolean;
} => {
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const { isLoading: isLoadingAssetsTags, data: assetsTagsData } =
    useGetAssetsTagsQuery(
      {
        input,
      },
      { enabled: !fetchAssetsFromOpenSearchFeatureFlag }
    );

  const { isLoading: isLoadingAssetsTagsOS, data: assetsTagsDataOS } =
    useGetAssetsTagsOsQuery(
      {
        input,
      },
      { enabled: fetchAssetsFromOpenSearchFeatureFlag }
    );

  const data = fetchAssetsFromOpenSearchFeatureFlag
    ? assetsTagsDataOS?.getAssetsTagsOS
    : assetsTagsData?.getAssetsTags;
  const isLoading = fetchAssetsFromOpenSearchFeatureFlag
    ? isLoadingAssetsTagsOS
    : isLoadingAssetsTags;
  const tags = data ?? [];

  return {
    tags,
    isLoading,
  };
};
