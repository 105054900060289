import { FC, useCallback, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Box, ThemeProvider } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import {
  Alert,
  AlertReminderV2,
  AlertType,
  AlertTypeV2,
  AlertV2,
  CreateAlertV2Mutation,
  DistanceUnit,
  UpdateAlertV2Mutation,
} from "../../../../graphql/operations";
import { ActionDialog } from "../../../../shared/components/ActionDialog/ActionDialog";
import { LeaveModal } from "../../../../shared/components/LeaveModal";
import { NameWithBackButton } from "../../../../shared/components/NameWithBackButton";
import { ChangeNameDialog } from "../../../../shared/components/NameWithBackButton/ChangeNameDialog";
import { SubHeader } from "../../../../shared/components/SubHeader";
import {
  SubHeaderActionProps,
  SubHeaderActionType,
} from "../../../../shared/components/SubHeader/components/SubHeaderAction/SubHeaderAction";
import {
  UserAccessScope,
  isSuperAdmin,
} from "../../../../shared/components/WithPermissions";
import { useCurrentOrg } from "../../../../shared/hooks/useCurrentOrg";
import { usePrompt } from "../../../../shared/hooks/usePrompt";
import { useSpinner } from "../../../../shared/hooks/useSpinner";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { useAlertApi } from "../../hooks/useAlertApi";
import { useAlertTheme } from "../../hooks/useAlertTheme";
import { useCurrentForm } from "../../hooks/useCurrentForm";
import {
  createAlertOnErrorCallback,
  getAlertInitialValues,
  getAlertParametersByType,
  onSuccessCreateCallback,
  onSuccessDeleteCallback,
  onSuccessUpdateCallback,
  prepareAlertReminderForSubmit,
  deleteAlertOnErrorCallback,
  onSuccessCreateTemplateCallback,
  createAlertTemplateOnErrorCallback,
} from "../../utils";
import { AlertHistoryTable } from "../AlertEventHistoryTable";
import {
  AlertCargoForm,
  AlertDwellForm,
  AlertGeofenceForm,
  AlertSpeedForm,
  AlertCustomForm,
  AlertMovingWithoutPrimOrSecPowerForm,
} from "../AlertForm";
import { AlertWheelEndForm } from "../AlertForm/AlertWheelEndForm";
import { NewAlertsType } from "../NewAlertsType/NewAlertsType";

interface AlertProps {
  alert: AlertV2;
  alertType: AlertTypeV2;
  isTemplate?: boolean;
}

export const AlertComponent: FC<AlertProps> = ({
  alert: alertProp,
  isTemplate,
  alertType,
}: AlertProps) => {
  const alertTheme = useAlertTheme();
  const [alert, setAlert] = useState(alertProp);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertName, setAlertName] = useState<string>(
    isTemplate ? `${alertProp.name} Alert` : alertProp.name
  );
  const { dispatch } = useAppContext();
  const org = useCurrentOrg();
  const { userInfo } = useAuthContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [shouldPrompt, setShouldPrompt] = useState(false);
  const [shouldCreateAlert, setShouldCreateAlert] = useState(false);
  const [shouldCreateAlertTemplate, setShouldCreateAlertTemplate] =
    useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);
  const [showCustomValidationModal, setShowCustomValidationModal] =
    useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);

  const { isLeaveModalOpen, setIsLeaveModalOpen, setIsConfirmed } =
    usePrompt(shouldPrompt);

  const speedValueUnit =
    org?.distance_unit_preference ?? DistanceUnit.Kilometers;
  const initialValues = getAlertInitialValues(
    alert,
    speedValueUnit,
    userInfo?.email
  );
  const isSuperAdminUser = isSuperAdmin(userInfo?.groups || []);
  const { form } = useCurrentForm(alertType, initialValues);
  const dirtyFields = Object.keys(form.formState.dirtyFields);
  const formValues = form.watch();
  const updatePromptState = useCallback(() => {
    // When there are some unsaved changes on the alert, when clicking on “Archive“ - dismiss all changes without showing the “Unsaved changes“ dialog and archive the alert
    if (isArchiving) {
      setShouldPrompt(false);
      return;
    }
    const condition =
      (!!dirtyFields.length || alert.name !== alertName) &&
      !shouldCreateAlert &&
      !shouldCreateAlertTemplate;
    if (condition) {
      setShouldPrompt(true);
    } else {
      setShouldPrompt(false);
    }
    return condition;
  }, [
    isArchiving,
    dirtyFields,
    alert.name,
    alertName,
    shouldCreateAlert,
    shouldCreateAlertTemplate,
  ]);

  useEffect(() => {
    updatePromptState();
  }, [
    alertName,
    alert.name,
    alertProp,
    formValues,
    dirtyFields,
    shouldCreateAlert,
    shouldCreateAlertTemplate,
    updatePromptState,
  ]);

  const createAlertOnSuccess = async (data: CreateAlertV2Mutation) => {
    await onSuccessCreateCallback({
      alertMessage,
      dispatch,
      created: data.createAlertV2,
      queryClient,
      navigate,
      setAlert,
      setLoading,
      setShouldCreateAlert,
    });
  };

  const createAlertTemplateOnSuccess = async () => {
    await onSuccessCreateTemplateCallback({
      dispatch,
      setLoading,
      navigate,
      queryClient,
    });
  };

  const updateAlertOnSuccess = (data: UpdateAlertV2Mutation) => {
    onSuccessUpdateCallback({
      alert: data.updateAlertV2,
      setAlert,
      queryClient,
      dispatch,
      setLoading,
      loadingState: formValues.saveAsNewAlertsType,
    });
  };

  const deleteAlertOnSuccess = async () => {
    await onSuccessDeleteCallback({
      queryClient,
      dispatch,
      setLoading,
      navigate,
    });
  };

  const createAlertOnError = (error: unknown) => {
    createAlertOnErrorCallback({ error, dispatch, setLoading });
  };

  const createAlertTemplateOnError = (error: unknown) => {
    createAlertTemplateOnErrorCallback({ error, dispatch, setLoading });
  };

  const updateAlertOnError = (error: unknown) => {
    updateAlertOnError({ error, dispatch, setLoading });
  };

  const deleteAlertOnError = (error: unknown) => {
    deleteAlertOnErrorCallback({ error, dispatch, setLoading });
  };
  // Temporary solution
  const { createAlert, updateAlert, deleteAlert, createAlertTemplate } =
    useAlertApi({
      createAlertOnSuccess,
      createAlertTemplateOnSuccess,
      updateAlertOnSuccess,
      deleteAlertOnSuccess,
      createAlertOnError,
      createAlertTemplateOnError,
      updateAlertOnError,
      deleteAlertOnError,
    });

  const handleFormSubmit = async (data: FieldValues) => {
    const valid = await form.trigger();
    if (!valid) {
      return;
    }
    if (data.saveAsNewAlertsType) {
      setShouldCreateAlertTemplate(true);
      createAlertTemplate({
        type: data.type,
        name: alertName,
        orgIds: data.alertTemplateOrgIds,
        message: data.message,
        query: data.trigger,
      });
      setLoading(true);
      return;
    }
    if (data.type === AlertType.Custom && !data.trigger) {
      setShowCustomValidationModal(true);
      return;
    }
    const reminder = prepareAlertReminderForSubmit(data) as AlertReminderV2;
    const trigger = isEmpty(data.trigger) ? null : data.trigger;

    if (isTemplate) {
      // create alert
      setShouldCreateAlert(true);
      setAlertMessage("Alert was created successfully!");
      createAlert(
        {
          message: data.message,
          name: alertName,
          orgId: org!._id,
          reminder,
          type: data.type,
          trigger,
          escalationTime: data.escalationTime,
          escalationUsers: data.escalationUsers,
          escalationSendSms: data.escalationSendSms,
        },
        JSON.stringify(
          getAlertParametersByType(data.type, speedValueUnit, data as Alert)
        )
      );
    } else {
      // update alert
      updateAlert(
        {
          _id: alert._id,
          message: data.message,
          name: alertName,
          reminder,
          trigger,
          escalationTime: data.escalationTime,
          escalationUsers: data.escalationUsers,
          escalationSendSms: data.escalationSendSms,
        },
        JSON.stringify(
          getAlertParametersByType(data.type, speedValueUnit, data as Alert)
        )
      );
    }
    setLoading(true);
  };

  const handleDuplicateAlert = () => {
    const data = form.getValues();
    // check if everything is saved
    const condition = updatePromptState();
    if (condition || isTemplate) {
      setUnsavedChanges(true);
      return;
    }
    const reminder = prepareAlertReminderForSubmit(data) as AlertReminderV2;
    const trigger = isEmpty(data.trigger) ? null : data.trigger;

    const escalationTime = data.escalationTime;
    const escalationUsers = data.escalationUsers;
    const escalationSendSms = data.escalationSendSms;

    setShouldCreateAlert(true);
    setLoading(true);
    setAlertMessage("Alert was duplicated successfully!");
    createAlert(
      {
        message: data.message,
        name: `Copy of ${alertName}`,
        orgId: org!._id,
        reminder,
        type: data.type,
        trigger,
        escalationTime,
        escalationUsers,
        escalationSendSms,
      },
      JSON.stringify(
        getAlertParametersByType(data.type, speedValueUnit, data as Alert)
      )
    );
  };

  const handleSubmitClick = () => {
    form.handleSubmit((data) => handleFormSubmit(data))();
  };

  const handleCloseEditNameDialog = () => {
    setIsDialogOpen(false);
  };
  const handleSubmitEditNameDialog = (name: string) => {
    setAlertName(name);
    setIsDialogOpen(false);
  };

  const handleLeaveModalClose = () => {
    setIsLeaveModalOpen(false);
    setIsConfirmed(false);
  };

  const handleArchiveClick = () => {
    setIsArchiving(true);
    deleteAlert(alert._id);
    setIsArchiveDialogOpen(false);
    setLoading(true);
  };

  const archiveActionClick = () => {
    setIsArchiveDialogOpen(true);
  };

  const subHeaderActions: SubHeaderActionProps[] = [
    {
      type: SubHeaderActionType.Button,
      icon: <DeleteOutlineIcon />,
      title: "Delete",
      handler: archiveActionClick,
      accessScope: "alerts.remove" as UserAccessScope,
      disabled: isTemplate,
    },
    {
      type: SubHeaderActionType.Button,
      title: "Duplicate",
      icon: <ContentCopyIcon />,
      handler: handleDuplicateAlert,
      accessScope: "alerts.create" as UserAccessScope,
    },
    {
      type: SubHeaderActionType.Button,
      icon: <SaveIcon />,
      title: "Save",
      primary: true,
      handler: handleSubmitClick,
      accessScope: "alerts.create" as UserAccessScope,
    },
  ];

  const renderForm = () => {
    switch (alertType) {
      case AlertTypeV2.Cargo:
        return <AlertCargoForm form={form} />;
      case AlertTypeV2.Geofence:
        return <AlertGeofenceForm form={form} />;
      case AlertTypeV2.Dwell:
        return <AlertDwellForm form={form} />;
      case AlertTypeV2.Custom:
        return <AlertCustomForm form={form} />;
      case AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower:
        return <AlertMovingWithoutPrimOrSecPowerForm form={form} />;
      case AlertTypeV2.Speeding:
        return <AlertSpeedForm form={form} />;
      case AlertTypeV2.WheelEnd:
        return <AlertWheelEndForm form={form} />;
      default:
        return "";
    }
  };

  // Show spinner while alert is loading
  useSpinner(loading);

  return (
    <Box
      data-testid={isTemplate ? "alert-template" : "alert-record"}
      className="pb-8"
    >
      <SubHeader title="Alerts" actions={subHeaderActions} hideOrgName />
      <NameWithBackButton
        name={alertName}
        moveBackRoute={NavigationRoutes.Alerts}
        setIsDialogOpen={setIsDialogOpen}
      />
      <ThemeProvider theme={alertTheme}>
        {isSuperAdminUser && <NewAlertsType form={form} />}

        {renderForm()}

        {!isTemplate ? <AlertHistoryTable alertId={alert._id} /> : ""}
      </ThemeProvider>
      <ChangeNameDialog
        name={alertName}
        nameType="alert"
        open={isDialogOpen}
        onClose={handleCloseEditNameDialog}
        onSubmit={handleSubmitEditNameDialog}
      />
      <LeaveModal
        isOpen={isLeaveModalOpen}
        confirmNavigation={() => setIsConfirmed(true)}
        onClose={handleLeaveModalClose}
      />
      <ActionDialog
        open={unsavedChanges}
        onClose={() => setUnsavedChanges(false)}
        title="Unsaved Changes"
        textContent="You have unsaved changes. Please save Alert before duplicating."
        name="unsaved-changes"
        onSubmit={() => {}}
        submitButtonText=""
        cancelButtonText="Close"
        hideSubmitButton
      />
      <ActionDialog
        open={showCustomValidationModal}
        onClose={() => setShowCustomValidationModal(false)}
        title="Query is empty"
        textContent="Please add at least one rule."
        name="query-is-empty"
        onSubmit={() => {}}
        submitButtonText=""
        cancelButtonText="Close"
        hideSubmitButton
      />
      <ActionDialog
        open={isArchiveDialogOpen}
        onClose={() => setIsArchiveDialogOpen(false)}
        onSubmit={handleArchiveClick}
        name="archive-alert"
        title="Confirm Alert Deleting"
        textContent="Are you sure?"
        submitButtonText="Confirm"
        cancelButtonText="Cancel"
      />
    </Box>
  );
};
