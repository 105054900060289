import { FC, useRef } from "react";
import { useParams } from "react-router-dom";
import Page from "../../../../shared/components/Page";
import { Automation } from "./Automation";

export const AutomationView: FC = () => {
  const page = useRef();
  const { id } = useParams();

  return (
    <Page ref={page} className={"bg-background"}>
      <Automation isTemplate={id === "template"} />
    </Page>
  );
};
