import { ColorsPalette } from "../../../../../../design-system/colors-palette";
import { AggregatedEventHistory } from "../../../../../../graphql/operations";

export const BAR_HEIGHT = 24;

export type CargoData = {
  eventHistoryData?: {
    cargo: Partial<AggregatedEventHistory["cargo"] | null>;
    date?: string | null;
  };
} | null;

export function indexOfMax(arr: (number | null)[]) {
  const hasPositive = arr.some((num) => num !== null && num > 0);
  // if there are no positive numbers or the array is empty, return -1
  if (!hasPositive || !arr.length) return -1;
  // if there are null values in the array, transform them to 0 to keep the index
  const transformedArr = arr.map((num) => (num === null ? 0 : num));
  // return the index of the max value in the array
  return arr.indexOf(Math.max(...transformedArr));
}

export function calculateCargoStatusLast30Days(data: Array<CargoData>) {
  let emptyCargoDays = 0;
  let loadedCargoDays = 0;

  data.forEach((aggregatedData: CargoData) => {
    let cargoLoadedEvents =
      aggregatedData?.eventHistoryData?.cargo?.cargoSummary?.loaded;
    if ((cargoLoadedEvents ?? 0) > 0) {
      loadedCargoDays += 1;
    } else {
      emptyCargoDays += 1;
    }
  });

  return {
    emptyCargoDays,
    loadedCargoDays,
  };
}

export function calculateCargoStatusInLocationsLast30Days(
  data: Array<CargoData>
) {
  let emptyCargoDaysInsideGeofence = 0;
  let emptyCargoDaysOutsideGeofence = 0;
  let loadedCargoDaysInsideGeofence = 0;
  let loadedCargoDaysOutsideGeofence = 0;

  data.forEach((aggregatedData: CargoData) => {
    const loadedCountGeofence =
      aggregatedData?.eventHistoryData?.cargo?.cargoSummary
        ?.loadedCountGeofence ?? 0;
    const loadedCountOutGeofence =
      aggregatedData?.eventHistoryData?.cargo?.cargoSummary
        ?.loadedCountOutGeofence ?? 0;

    const unloadedCountGeofence =
      aggregatedData?.eventHistoryData?.cargo?.cargoSummary
        ?.unloadedCountGeofence ?? 0;
    const unloadedCountOutGeofence =
      aggregatedData?.eventHistoryData?.cargo?.cargoSummary
        ?.unloadedCountOutGeofence ?? 0;

    let maxValueIndex = indexOfMax([
      loadedCountGeofence,
      loadedCountOutGeofence,
      unloadedCountGeofence,
      unloadedCountOutGeofence,
    ]);

    if (maxValueIndex !== -1) {
      switch (maxValueIndex) {
        case 0:
          loadedCargoDaysInsideGeofence += 1;
          break;
        case 1:
          loadedCargoDaysOutsideGeofence += 1;
          break;
        case 2:
          emptyCargoDaysInsideGeofence += 1;
          break;
        case 3:
          emptyCargoDaysOutsideGeofence += 1;
          break;
        default:
          console.log("Error calculating cargo state for current day!");
          break;
      }
    }
  });

  return {
    emptyCargoDaysInsideGeofence,
    emptyCargoDaysOutsideGeofence,
    loadedCargoDaysInsideGeofence,
    loadedCargoDaysOutsideGeofence,
  };
}

export const getBoxColor = (eventsCount: number) => {
  if (eventsCount === 0) return "border";
  if (eventsCount > 0) return "bg-brand-light-blue";
  return "bg-box-shadow";
};

export const getBoxBorderColor = (eventsCount: number) => {
  if (eventsCount === 0) return ColorsPalette.RoyalBlue;
  if (eventsCount > 0) return ColorsPalette.Concrete;
  return "bg-grayscale-feather-gray";
};
