export enum StatusCardsType {
  Chassis = "Cargo",
  CameraVision = "Cargo Vision",
  Regulator = "Regulator",
  AirBag = "Air Bag",
  Door = "Door",
  ABSFaultCodes = "ABS Fault Code",
  TPMS = "TMPS",
  AirTank = "Air Tank",
  AirSupply = "Air Supply",
  AtisAlpha = "ATIS",
  LiteSentryGamma = "Light Circuit",
  TemperatureInternal = "Temperature (Internal)",
  WheelEndTemperature = "Wheel End Temperature",
  DualImbalance = "Dual Imbalance",
  Liftgate = "Liftgate",
}
