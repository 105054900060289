import { memo, useCallback, useMemo } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Divider,
  TextField,
} from "@mui/material";
import { isArray, pick, property, startCase, uniqBy } from "lodash";
import { LastReportedDateOptions } from "../../../../../../constants/map";
import { AssetType, SensorStatus } from "../../../../../../graphql/operations";
import { SwitchButtons } from "../../../../../../shared/components/SwitchButtons";
import ToggleButtons, {
  ToggleButtonOption,
} from "../../../../../../shared/components/ToggleButtons";
import { useGetAssetsManufacturers } from "../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useGetAssetsManufacturers";
import { useGetAssetsTags } from "../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useGetAssetsTags";
import { useCurrentTheme } from "../../../../../../shared/hooks/theme/useCurrentTheme";
import { useAssetTypes } from "../../../../../../shared/hooks/useAssetTypes";
import { useProductsList } from "../../../../../../shared/hooks/useProductsList";
import { useRegionsList } from "../../../../../../shared/hooks/useRegionsList";
import { FormFieldDropdownOption } from "../../../../../../types";
import { useGetZonesData } from "../../../../../AdminPanel/tabs/Zones/hooks/useGetZonesData";
import { useDropdownOptions } from "../../../../MapView/hooks/useDropdownOptions";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../../TableView/hooks";
import { useAssetsDataContext } from "../../../AssetsDataContext";
import AssetYearSlider from "./components/sliders/AssetYearSlider";
import TotalMileageSlider from "./components/sliders/TotalMileageSlider";
import WeightStatusSlider from "./components/sliders/WeightStatusSlider";

export type SelectedValueType =
  | ToggleButtonOption["value"]
  | Array<ToggleButtonOption["value"]>
  | undefined;

const ASSET_FILTERS_TYPES = [
  AssetType.Trailer,
  AssetType.Chassis,
  AssetType.Container,
  AssetType.Other,
];

const SUB_ORGS_OPTIONS: ToggleButtonOption[] = [
  {
    value: true,
    label: "Include",
    isFalsy: false,
  },
  {
    value: false,
    label: "Exclude",
    isFalsy: true,
  },
];

const ASSET_HEALTH_OPTIONS = [
  {
    label: SensorStatus.Healthy,
    value: SensorStatus.Healthy,
    style: "var(--success)",
  },
  {
    label: SensorStatus.Warning,
    value: SensorStatus.Warning,
    style: "var(--caution)",
  },
  {
    label: SensorStatus.Alert,
    value: SensorStatus.Alert,
    style: "var(--warning)",
  },
  {
    label: SensorStatus.Critical,
    value: SensorStatus.Critical,
    style: "var(--error)",
  },
];

const PRODUCT_CODE_REGEX = new RegExp("\\s\\([^)]+\\)$");

const AssetFilters: React.FC = () => {
  const {
    currentFilter: {
      assetTags,
      assetTypes,
      productNames,
      assetHealth,
      numberOfAxles,
      numberOfTires,
      manufacturers,
      regions,
      lastReportedDateRange,
      subOrganizations,
      zones: zonesFilter,
    },
    onChangeFilters,
  } = useAssetsDataContext();

  const muiTheme = useCurrentTheme();

  const assetNumberOfAxlesOptions = useNomenclatures(
    NOMENCLATURE_NAMES.assetNumberOfAxles
  );
  const assetNumberOfTiresOptions = useNomenclatures(
    NOMENCLATURE_NAMES.assetWheels
  );

  const { isFetchingNomenclatures, assetTypeOptionsState: assetTypeOptions } =
    useAssetTypes(ASSET_FILTERS_TYPES);

  const isLoading = isFetchingNomenclatures > 0;

  const { tags: tagsDropdownOptions } = useGetAssetsTags({
    limit: 100,
    skip: 0,
  });
  const { assetManufacturers } = useGetAssetsManufacturers({
    limit: 100,
    skip: 0,
  });

  const { regions: regionsData, isLoading: isRegionsLoading } =
    useRegionsList();
  const regionOptions = useDropdownOptions(regionsData, "value", "name");

  /** 
   fetches only the first value from the artificial list
  * Current implementation works with artificial array 
  * Will be adjusted after new filter logic supports single formFieldOptions with label & value
  */
  const selectedRegion = useMemo(
    () => regions?.[0] ?? { label: "", value: [] },
    [regions]
  );

  const { zones: zonesData, isLoading: zonesAreLoading } = useGetZonesData();

  const zonesOptions = useMemo(() => {
    const { canada, unitedStates, mexico } = zonesData;

    const allZones = [
      /* 
        UI for canada zones doesn't allow for spaces but
        assets in OS have a space at index 3 
        (remove this processing if changed)
      */
      ...canada.data.map((zone) => ({
        ...zone,
        matches: zone.matches.map(
          (match) => `${match.slice(0, 3)} ${match.slice(3)}`
        ),
      })),
      ...unitedStates.data,
      ...mexico.data,
    ];

    return allZones.map((zone) => ({
      id: zone.id,
      label: zone.name,
      country: startCase(zone.country),
      ranges: zone.ranges,
      matches: zone.matches,
    }));
  }, [zonesData]);

  const selectedZones = useMemo(
    () =>
      zonesOptions.filter((option) =>
        (zonesFilter ?? []).map(property("id")).includes(option.id)
      ),
    [zonesFilter, zonesOptions]
  );

  const assetTagsSorted = uniqBy(assetTags, (tag) =>
    tag.trim().toLowerCase()
  ).sort((a, b) => a.localeCompare(b));
  const { products } = useProductsList();
  const productsDropdownOptions = useDropdownOptions(
    products,
    "_id",
    "product_name"
  ).sort((a, b) => a.label.localeCompare(b.label));

  const onChangeType = useCallback(
    (value: SelectedValueType) =>
      onChangeFilters({ assetTypes: (value ?? []) as string[] }),
    [onChangeFilters]
  );

  const onChangeHealth = (value: SelectedValueType) => {
    onChangeFilters({
      assetHealth: (isArray(value) ? value : [value]) as SensorStatus[],
    });
  };

  const isDarkMode = muiTheme.palette.mode === "dark";
  const color = isDarkMode ? "white" : "black";

  return (
    <Box className="w-full">
      <Box className="h-12 mb-3 flex items-center justify-between font-semibold text-[18px] leading-[26px] tracking-[-0.01em]">
        <Box>Assets</Box>
      </Box>

      <Box className="h-20 my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Types</Box>
        {isLoading ? (
          <Box className="flex justify-center">
            <CircularProgress />
          </Box>
        ) : (
          <ToggleButtons
            id="assets-filter__asset-types"
            options={assetTypeOptions}
            value={assetTypes}
            onChange={onChangeType}
            outlined
            parentStyle="grid grid-cols-4 gap-2"
            buttonStyle="!h-[40px] !rounded-[20px] !text-[12px] !font-medium !capitalize !border-2"
            customColor={color}
          />
        )}
      </Box>

      <Divider />

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Tags</Box>
        <Autocomplete
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="assets-filter__asset-tags"
          limitTags={3}
          multiple
          options={tagsDropdownOptions}
          size="small"
          value={assetTagsSorted}
          onChange={(_, value) => onChangeFilters({ assetTags: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Tags"
              data-testid="asset-tags-input"
            />
          )}
        />
      </Box>

      <Divider />

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Product Name</Box>
        <Autocomplete
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="assets-filter__product-name"
          limitTags={3}
          multiple
          options={productsDropdownOptions}
          size="small"
          value={productNames}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          onChange={(_, value) => {
            onChangeFilters({
              productNames: value.map(({ label, value }) => ({
                value,
                label: label.replace(PRODUCT_CODE_REGEX, ""),
              })),
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Product Name"
              data-testid="products-input"
            />
          )}
        />
      </Box>

      <Divider />

      <Box className="h-20 my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Asset Health</Box>

        <ToggleButtons
          id="assets-filter__asset-health"
          options={ASSET_HEALTH_OPTIONS}
          value={assetHealth}
          onChange={onChangeHealth}
          outlined
          parentStyle="grid grid-cols-4 gap-2"
          buttonStyle="!h-[40px] !rounded-[20px] !text-[12px] !font-medium !capitalize !border-2"
          customColor={color}
        />
      </Box>

      <Divider />

      <Box className="h-20 my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Sub-Organizations</Box>

        <SwitchButtons
          id="assets-navigation-switch"
          data-testid="assets-navigation-switch"
          value={subOrganizations}
          onChange={(_, value) =>
            value !== null && onChangeFilters({ subOrganizations: value })
          }
          options={SUB_ORGS_OPTIONS}
          size="small"
          groupclass={`flex justify-between h-[40px] overflow-hidden !rounded-[30px] p-0.5 !border-2 !border-${color}`}
          className={`!text-${color}`}
          fullWidth
          exclusive
        />
      </Box>

      <Divider />

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Number of Axles</Box>
        <Autocomplete
          data-testid="assets-filter__number-of-axles"
          limitTags={3}
          multiple
          options={assetNumberOfAxlesOptions}
          size="small"
          value={numberOfAxles}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          onChange={(_, value) => onChangeFilters({ numberOfAxles: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              data-testid="number-of-axles-input"
            />
          )}
        />
      </Box>

      <Divider />

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Number of Tires</Box>
        <Autocomplete
          data-testid="assets-filter__number-of-tires"
          multiple
          options={assetNumberOfTiresOptions}
          size="small"
          value={numberOfTires}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          onChange={(_, value) => onChangeFilters({ numberOfTires: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              data-testid="number-of-tires-input"
            />
          )}
        />
      </Box>

      <Divider />

      <Box className="h-[100px] my-4 flex flex-col justify-between items-center">
        <Box className="self-start font-bold text-[10px] leading-4">
          Weight Status
        </Box>
        <Box className="w-[90%]">
          <WeightStatusSlider />
        </Box>
      </Box>

      <Divider />

      <Box className="h-[100px] my-4 flex flex-col justify-between items-center">
        <Box className="self-start font-bold text-[10px] leading-4">
          Total Mileage
        </Box>
        <Box className="w-[90%]">
          <TotalMileageSlider />
        </Box>
      </Box>

      <Divider />

      <Box className="h-[100px] my-4 flex flex-col justify-between items-center">
        <Box className="self-start font-bold text-[10px] leading-4">
          Asset Year
        </Box>
        <Box className="w-[90%]">
          <AssetYearSlider />
        </Box>
      </Box>

      <Divider />

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Manufacturer</Box>
        <Autocomplete
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="assets-filter__manufacturer"
          multiple
          options={assetManufacturers}
          size="small"
          value={manufacturers}
          onChange={(_, value) => onChangeFilters({ manufacturers: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              data-testid="manufacturer-input"
            />
          )}
        />
      </Box>
      <Divider />

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Regions</Box>
        <Autocomplete
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="assets-filter__regions"
          options={regionOptions}
          loading={isRegionsLoading}
          size="small"
          value={selectedRegion}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          onChange={(_, value) => {
            // sent as list due to current filters logic
            onChangeFilters({
              regions: (value ? [value] : []) as FormFieldDropdownOption[],
            });
          }}
          renderInput={(params) => (
            <TextField {...params} label="Search" data-testid="regions-input" />
          )}
        />
      </Box>

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Zones</Box>
        <Autocomplete
          multiple
          openOnFocus
          size="small"
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="assets-filter__zones"
          groupBy={property("country")}
          loading={zonesAreLoading}
          disabled={zonesAreLoading}
          options={zonesOptions}
          value={selectedZones}
          isOptionEqualToValue={(option, value) =>
            option.id.toString() === value.id.toString()
          }
          onChange={(_, value) =>
            onChangeFilters({
              zones: value.map((zone) =>
                pick(zone, ["id", "label", "ranges", "matches", "country"])
              ),
            })
          }
          renderTags={(value, getTagProps) =>
            value.map((zone, index) => (
              <Chip
                {...getTagProps({ index })}
                // If there are mulitple zones with the same label append
                // the country name as well for clarity
                label={
                  value.filter(({ label }) => label === zone.label).length > 1
                    ? `${zone.label} (${zone.country})`
                    : zone.label
                }
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Zones"
              data-testid="zones-input"
            />
          )}
        />
      </Box>

      <Divider />

      <Box className="h-[60px] my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Last Reported</Box>
        <Autocomplete
          data-testid="assets-filter__last-reported-date"
          options={Object.values(LastReportedDateOptions)}
          size="small"
          value={lastReportedDateRange}
          onChange={(_, value) =>
            onChangeFilters({ lastReportedDateRange: value })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              data-testid="last-reported-date-input"
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default memo(AssetFilters);
