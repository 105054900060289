import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import slug from "slug";

export type NameWithBackButtonProps = {
  name: string;
  moveBackRoute: string;
  setIsDialogOpen?: (isOpen: boolean) => void;
};

export const NameWithBackButton: FC<NameWithBackButtonProps> = ({
  name,
  moveBackRoute,
  setIsDialogOpen,
}: NameWithBackButtonProps) => {
  const navigate = useNavigate();
  const onBackBtnClick = useCallback(
    () => navigate(moveBackRoute),
    [navigate, moveBackRoute]
  );

  return (
    <Box className="px-4 pt-4 md:px-8 md:pt-4 lg:px-16 lg:pt-4">
      <Box className="mb-1">
        <Button
          className="!p-0 !font-bold !capitalize !text-brand"
          onClick={onBackBtnClick}
        >
          <ArrowBackIcon className="mr-3" />
          Back
        </Button>
      </Box>
      <h2
        className="group mb-4 min-h-[3rem] text-2xl font-semibold leading-10 text-header-text"
        data-testid={`alert-${slug(name)}`}
      >
        {name}
        {setIsDialogOpen ? (
          <IconButton
            color="inherit"
            className="!hidden group-hover:!inline-flex"
            data-testid="editNameBtn"
            aria-label="editName"
            onClick={() => setIsDialogOpen(true)}
          >
            <ModeEditIcon />
          </IconButton>
        ) : (
          ""
        )}
      </h2>
    </Box>
  );
};
