import { useState } from "react";
import QRCode from "react-qr-code";
import { Box } from "@mui/system";
import { useAuthContext } from "../../context/AuthContext";
import { verifySecretCodeForAuthApp } from "../../services/aws/auth";
import { Logo } from "../../shared/components/Svg";
import { navigateToRoute } from "../../utils";
import { NavigationRoutes } from "../../utils/routes/routesUtils";

const MfaAuthAppSetup = () => {
  const { login } = useAuthContext();

  const username = sessionStorage.getItem("username") ?? "";
  const appName = "Phillips-Connect";
  const [secretCodeFromAuthApp, setSecretCodeFromAuthApp] = useState(""); // 6-digits code

  // TOTP App link generation
  const TOTP_APP_LINK = `otpauth://totp/${appName}:${username}?secret=${
    sessionStorage.getItem("SecretCodeForAuthApp") ?? ""
  }&algorithm=SHA1&digits=6&period=60`;

  const setupAppAuthSecretCode = async (secretCodeFromAuthApp: string) => {
    console.log("secretCodeFromAuthApp----", secretCodeFromAuthApp);
    const response = await verifySecretCodeForAuthApp(secretCodeFromAuthApp);
    console.log(
      "verifySecretCodeForAuthApp -> response",
      JSON.stringify(response)
    );
    if (!response) {
      console.error("Sign-in failed.");
      return;
    }

    if (
      response.type === "Challenge" &&
      response.challengeName === "SOFTWARE_TOKEN_MFA"
    ) {
      console.log(
        "verifySecretCodeForAuthApp -> response",
        JSON.stringify(response)
      );
      // TODO: https://phillips-connect.atlassian.net/browse/PRJIND-9365
      // Check why this step is needed, should be possible to be skipped
      // when we already entered the auth app code once ...
      return navigateToRoute(NavigationRoutes.MfaVerifyAuthAppCode, false);
    }
    if (response.type === "AuthenticationResult") {
      const { result } = response;

      if (result.AccessToken) {
        sessionStorage.setItem("accessToken", result.AccessToken ?? "");
        sessionStorage.setItem("idToken", result.IdToken ?? "");
        sessionStorage.setItem("refreshToken", result.RefreshToken ?? "");
        sessionStorage.setItem(
          "expiresIn",
          (result.ExpiresIn ?? "").toString()
        );

        const tokens = {
          accessToken: result.AccessToken,
          idToken: result.IdToken ?? "",
          refreshToken: result.RefreshToken ?? "",
          expiresIn: result.ExpiresIn ?? 0,
        };
        login(tokens);
        return navigateToRoute(NavigationRoutes.Home);
      } else {
        console.error("AccessToken is undefined.");
      }
    }
  };

  return (
    <Box className="v-full h-full flex flex-col justify-center items-center bg-gray">
      <Logo />
      <Box className="my-4 text-sm font-thin">
        Set up application authentication
      </Box>
      <Box className="mt-2 mb-3 text-sm font-thin">
        Scan this QR code with your authenticator app
      </Box>
      <Box className="mt-1 text-sm font-thin">
        <Box>
          <Box>
            {TOTP_APP_LINK && (
              <QRCode data-testid="qr-code" value={TOTP_APP_LINK} size={256} />
            )}
          </Box>
        </Box>
      </Box>
      <Box className="mt-4 text-sm font-thin">
        Please enter your 6-digits code from the auth app :
      </Box>
      <Box className="mt-4 text-sm font-thin">
        <form>
          <input
            className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded"
            onChange={(event) => {
              setSecretCodeFromAuthApp(event.target.value ?? "");
            }}
            value={secretCodeFromAuthApp}
          ></input>
          <Box className="mt-4 text-sm font-thin">
            <button
              className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setupAppAuthSecretCode(secretCodeFromAuthApp);
                return false;
              }}
            >
              Send
            </button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default MfaAuthAppSetup;
