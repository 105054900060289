import { FC, useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Paper, Typography, Button } from "@mui/material";
import { CustomTooltip } from "../../../../shared/components/CustomTooltip/CustomTooltip";
import { assetQueryBuilderFields } from "../../../../shared/components/QueryBuilder/config";
import {
  helperText,
  QueryBuilderElement,
} from "../../../../shared/components/react-hook-form-mui/QueryBuilderElement";
import { AutomationInitialValues } from "./hooks/useAutomationForm";

interface AutomationQueryBuilderProps {
  form: UseFormReturn<Partial<AutomationInitialValues>>;
}

export const AutomationQueryBuilder: FC<AutomationQueryBuilderProps> = ({
  form,
}) => {
  const [shouldReset, setShouldReset] = useState(false);

  const resetQueryBuilderHandler = useCallback(() => {
    setShouldReset(true);
  }, []);

  const onReset = useCallback(() => {
    setShouldReset(false);
  }, []);

  return (
    <Paper
      elevation={0}
      className={`mb-6`}
      data-testid="automation-query-builder"
    >
      <Box className="p-12">
        {/*query builder has padding right, remove pr-2 if style change*/}
        <Box className="flex justify-between pr-2">
          <Typography className="!pb-8 !text-lg" variant="h6" component="div">
            Add Filters
            <CustomTooltip title={helperText} arrow>
              <InfoOutlinedIcon className="ml-2 cursor-pointer" />
            </CustomTooltip>
          </Typography>
          <Button
            data-testid="reset-query-view-btn"
            className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand"
            onClick={resetQueryBuilderHandler}
          >
            Reset query view
          </Button>
        </Box>
        <QueryBuilderElement
          label=""
          control={form.control}
          name="trigger"
          configFields={assetQueryBuilderFields}
          shouldReset={shouldReset}
          onReset={onReset}
        />
      </Box>
    </Paper>
  );
};
