import { FC, memo, MutableRefObject, useState } from "react";
import Box from "@mui/material/Box";
import {
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridRowGroupingModel,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../context/AppContext";
import {
  AccountsReportData,
  AssetInstallEvent,
  AssetReportDataRow,
  AssetTransferEvent,
  DwellHierarchyReportDataRow,
  FiltersInput,
  MediaActivitiesReportDataRow,
  MileageReportDataRow,
  ReportAlertHistory,
  SensorsReportDataRow,
  TableFilterInput,
  TableFilterLinkOperator,
  TableViewType,
} from "../../../graphql/operations";
import { TableViewMenuRef } from "../../../views/ReportView/AssetReport";
import { ReportGridColDef } from "../../../views/ReportView/helpers/helpers";
import { useExportedFileName } from "../../hooks/useExportedFileName";
import { Table, TableDataModes } from "../Table";

export type ReportTableProps = {
  data:
    | AccountsReportData[]
    | AssetTransferEvent[]
    | AssetInstallEvent[]
    | ReportAlertHistory[]
    | DwellHierarchyReportDataRow[]
    | AssetReportDataRow[]
    | MileageReportDataRow[]
    | MediaActivitiesReportDataRow[]
    | SensorsReportDataRow[];
  isLoading?: boolean;
  isSuccess?: boolean;
  apiRef: MutableRefObject<GridApiPremium>;
  columnsVisibilityModel?: GridColumnVisibilityModel;
  changeColumnsVisibility: (model: GridColumnVisibilityModel) => void;
  onColumnOrderChange: (colData: GridColumnOrderChangeParams) => void;
  columns: ReportGridColDef[];
  sortModel?: GridSortModel;
  onSortModelChange?: (model: GridSortModel) => void;
  searchKeys: string[];
  reportName: string;
  onPageChange: (page: number) => void;
  currentPage: number;
  pageSize: number;
  rowCount: number;
  onSearch: (searchQuery: string) => void;
  onFilterChange: (value: {
    items: FiltersInput[] & TableFilterInput[];
    linkOperator: string & TableFilterLinkOperator;
  }) => void;
  tableViewsMenuRef?: MutableRefObject<TableViewMenuRef | null>;
};

export const ReportTable: FC<ReportTableProps> = memo(
  (props: ReportTableProps) => {
    const {
      reportName,
      isSuccess,
      isLoading,
      data,
      columnsVisibilityModel,
      changeColumnsVisibility,
      onColumnOrderChange,
      columns,
      searchKeys,
      onSearch,
      onPageChange,
      currentPage,
      pageSize,
      rowCount,
      sortModel,
      onSortModelChange,
      onFilterChange,
      tableViewsMenuRef,
      ...rest
    } = props;

    const {
      state: { appConfig },
    } = useAppContext();
    const fileName = useExportedFileName(reportName);
    const [rowGroupingModel, setRowGroupingModel] =
      useState<GridRowGroupingModel>();

    return (
      <Box
        className="h-full w-full p-4 pt-2 md:px-6 md:pb-10 lg:px-16 lg:pb-20"
        data-testid="asset-report-table-container"
      >
        <Table
          tableType={TableViewType.Report}
          tableHeight="570px"
          tableName="report_table"
          columns={columns}
          rows={data}
          loading={isLoading}
          error={!isLoading && !isSuccess ? true : null}
          columnVisibilityModel={columnsVisibilityModel}
          changeColumnsVisibility={changeColumnsVisibility}
          onColumnOrderChange={onColumnOrderChange}
          showToolbar
          disableRowGrouping // TEMPORARY DISABLED UNTIL WE HAVE A BE GROUPING SOLUTION
          getRowId={(row) => row._id}
          allowExport={false} // EXPORT IS DISABLED FOR REPORTS THAT DON'T HAVE BE TABLE PROCESSING
          {...rest}
          exportProps={{
            csvOptions: { fileName },
            excelOptions: { fileName },
            printOptions: { fileName },
          }}
          enableSearch={true}
          searchKeys={searchKeys}
          searchMinLength={appConfig.searchMinLength}
          searchExactMatch={true}
          onSearch={onSearch}
          sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
          rowGroupingModel={rowGroupingModel}
          onRowGroupingModelChange={(model: GridRowGroupingModel) => {
            setRowGroupingModel(model);
            props.apiRef.current.setSortModel([
              {
                field: "__row_group_by_columns_group__",
                sort: "asc",
              },
            ]);
          }}
          pagination
          handleDataMode={TableDataModes.Client}
          onPageChange={onPageChange}
          currentPage={currentPage}
          pageSize={pageSize}
          rowCount={rowCount}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          onPaginationModelChange={onPageChange}
          onFilterModelChange={onFilterChange}
          tableViewsMenuRef={tableViewsMenuRef}
        />
      </Box>
    );
  }
);
