import React, { useState } from "react";
import { Box, FormControl, Typography } from "@mui/material";
import { useAuthContext } from "../../context/AuthContext";
import { verifySMSCode, resendSMSCode } from "../../services/aws/auth";
import { TextButton } from "../../shared/components/Button";
import { Logo } from "../../shared/components/Svg";
import { navigateToRoute } from "../../utils/generic";
import { NavigationRoutes } from "../../utils/routes/routesUtils";

const MFASmsValidation: React.FC = () => {
  const { login } = useAuthContext();
  const [smsCode, setSmsCode] = useState<string>("");
  const [smsCodeErrorState, setSmsCodeErrorState] = useState<boolean>(false);
  const username = sessionStorage.getItem("username") ?? "";
  const password = sessionStorage.getItem("password") ?? "";
  const codeDeliveryDestination =
    sessionStorage.getItem("codeDeliveryDestination") ?? "";

  const verifyCode = async () => {
    try {
      setSmsCodeErrorState(false);
      await verifySMSCode(smsCode);
      const AccessToken = sessionStorage.getItem("accessToken") ?? "";
      const IdToken = sessionStorage.getItem("idToken") ?? "";
      const RefreshToken = sessionStorage.getItem("refreshToken") ?? "";
      const ExpiresIn = parseInt(sessionStorage.getItem("expiresIn") ?? "0");

      if (AccessToken) {
        const tokens = {
          accessToken: AccessToken,
          idToken: IdToken,
          refreshToken: RefreshToken,
          expiresIn: ExpiresIn,
        };

        login(tokens);
        return navigateToRoute(NavigationRoutes.Home, false);
      } else {
        console.error("Sign in failed.");
      }
    } catch (err) {
      console.error("Validation core error:", err);
      setSmsCodeErrorState(true);
    }
  };

  const handleResendCode = async () => {
    await resendSMSCode(username, password);
  };

  return (
    <Box className="v-full h-full flex flex-col justify-center items-center bg-gray">
      <Logo />
      <Box className="mt-4 text-sm font-thin">
        Please enter the 6-digit code sent to your phone number:{" "}
        <b>{codeDeliveryDestination}</b>
      </Box>
      {smsCodeErrorState && (
        <Typography
          className="text-error !mt-1 !text-sm"
          data-testid="error-message"
        >
          The code you provided seems to be invalid.
        </Typography>
      )}

      <FormControl className="!mt-4">
        <input
          className={`${
            smsCodeErrorState ? "border-error" : ""
          } mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded`}
          id="smsCode"
          type="text"
          value={smsCode}
          onChange={(e) => setSmsCode(e.target.value)}
          placeholder="SMS Code"
          required
        />
      </FormControl>

      <button
        className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded"
        type="button"
        onClick={verifyCode}
      >
        Sign In
      </button>

      <Box
        className="mb-6 text-[10px] font-medium"
        data-testid="forgot-password-link"
      >
        Did not get code?
        <TextButton
          text="Request again"
          className="!text-brand"
          onClick={handleResendCode}
        />
      </Box>
    </Box>
  );
};

export default MFASmsValidation;
