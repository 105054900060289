import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import {
  SearchAggregatedEventDoorOsQuery,
  SearchAggregatedEventDoorQuery,
  SearchAggregatedEventHistoryQueryVariables,
  useSearchAggregatedEventDoorOsQuery,
  useSearchAggregatedEventDoorQuery,
} from "../../../graphql/operations";
import { useFeatureFlag } from "../../../utils";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";
import { DoorData } from "../../../views/AssetsView/MapView/Assets/CargoTabPanel/DoorStatus/utils";

type CombinedQueriesType =
  | SearchAggregatedEventDoorQuery
  | SearchAggregatedEventDoorOsQuery;

type UseSearchAggregatedEventDoorDataHookResult = {
  data: DoorData[];
};

type SearchAggregatedEventDoorQueryOptions = UseQueryOptions<
  CombinedQueriesType,
  unknown,
  CombinedQueriesType,
  QueryKey
>;

export const useSearchAggregatedEventDoorData = (
  input: SearchAggregatedEventHistoryQueryVariables,
  options: SearchAggregatedEventDoorQueryOptions
): UseSearchAggregatedEventDoorDataHookResult => {
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const queryFn = fetchAssetsFromOpenSearchFeatureFlag
    ? useSearchAggregatedEventDoorOsQuery
    : useSearchAggregatedEventDoorQuery;

  const { data } = queryFn(input, options);

  return {
    data:
      (fetchAssetsFromOpenSearchFeatureFlag
        ? (data as SearchAggregatedEventDoorOsQuery)
            ?.searchAggregatedEventHistoryOS?.data
        : (data as SearchAggregatedEventDoorQuery)?.searchAggregatedEventHistory
            ?.data) ?? [],
  };
};
