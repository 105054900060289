import React, { FC, useMemo, useState } from "react";
import { Box, Grid, MenuItem, TextField, ThemeProvider } from "@mui/material";
import {
  AssetSettingsTitle,
  AssetSettingsType,
  assetSettingsTitles,
} from "../../../../../constants/assets";
import { GeofenceSettingsType } from "../../../../../constants/geofences";
import {
  GeofenceData,
  useGetMergedProfileForAssetQuery,
} from "../../../../../graphql/operations";
import { useFindAssetById } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { SectionHeader } from "../../../TableView/components/AssetForm/sections/SectionHeader";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import { GeofenceSettingsTab } from "../../Geofences/Settings/GeofenceSettingsTab";
import { useSettingsTheme } from "../../hooks/useSettingsTheme";
import { AssetDetailsSettings } from "../AssetDetailsSettings";
import { BatterySettingsComponent } from "../BatterySettingsComponent";
import { BrakesComponent } from "../BrakesComponent";
import { CargoComponent } from "../CargoComponent/CargoComponent";
import { LiftgateSettings } from "../LiftgateSettings";
import { LightsSettings } from "../LightsSettings/LightsSettings";
import { OtherSensorsComponent } from "../OtherSensorsComponent";
import { TemperatureSettings } from "../TemperatureSettings/TemperatureSettings";
import { TiresComponent } from "../TiresComponent";

interface SettingsProps {
  onBack: () => void;
  geofence?: GeofenceData;
  settingsTabTypes: { label: string; value: string }[];
  isAssetRefetching: boolean;
}

export const Settings: FC<SettingsProps> = ({
  onBack,
  geofence,
  settingsTabTypes,
  isAssetRefetching,
}: SettingsProps) => {
  const theme = useSettingsTheme();
  const { selectedAssetId } = useAssetsDataContext();

  const isMobile = useBreakpoint("down", "sm");

  const { data: selectedAsset } = useFindAssetById(
    {
      assetId: selectedAssetId ?? "",
    },
    { enabled: Boolean(selectedAssetId) }
  );

  if (!geofence) {
    settingsTabTypes = selectedAsset?.imei
      ? settingsTabTypes
      : settingsTabTypes.filter(
          (item) => item.label === AssetSettingsTitle.Information
        );
  }

  const [selectedType, setSelectedType] = useState(
    settingsTabTypes?.[0]?.value
  );

  const profileGQL = useGetMergedProfileForAssetQuery(
    {
      input: { assetId: selectedAssetId ?? "" },
    },
    { enabled: Boolean(selectedAssetId) }
  )?.data;

  const mergedSensorProfile = useMemo(
    () => profileGQL?.getMergedProfileForAsset.sensorProfile ?? null,
    [profileGQL]
  );

  const renderSettingsContent = () => {
    if (selectedAsset) {
      switch (selectedType) {
        case AssetSettingsType.Information:
          return (
            <AssetDetailsSettings
              onBack={onBack}
              asset={selectedAsset}
              isAssetRefetching={isAssetRefetching}
            />
          );
        case AssetSettingsType.Battery:
          return mergedSensorProfile ? (
            <BatterySettingsComponent
              asset={selectedAsset}
              assetMergedSensorProfile={mergedSensorProfile}
              isAssetRefetching={isAssetRefetching}
            />
          ) : null;
        case AssetSettingsType.Cargo:
          return mergedSensorProfile ? (
            <CargoComponent
              asset={selectedAsset}
              assetMergedSensorProfile={mergedSensorProfile}
              isAssetRefetching={isAssetRefetching}
            />
          ) : null;
        case AssetSettingsType.Tires:
          return mergedSensorProfile ? (
            <TiresComponent
              asset={selectedAsset}
              assetMergedSensorProfile={mergedSensorProfile}
              isAssetRefetching={isAssetRefetching}
            />
          ) : null;
        case AssetSettingsType.Temperature:
          return mergedSensorProfile ? (
            <TemperatureSettings
              asset={selectedAsset}
              assetMergedSensorProfile={mergedSensorProfile}
              isAssetRefetching={isAssetRefetching}
            />
          ) : null;
        case AssetSettingsType.Brakes:
          return mergedSensorProfile ? (
            <BrakesComponent
              asset={selectedAsset}
              assetMergedSensorProfile={mergedSensorProfile}
              isAssetRefetching={isAssetRefetching}
            />
          ) : null;
        case AssetSettingsType.LightCircuit:
          return mergedSensorProfile ? (
            <LightsSettings
              asset={selectedAsset}
              assetMergedSensorProfile={mergedSensorProfile}
              isAssetRefetching={isAssetRefetching}
            />
          ) : null;
        case AssetSettingsType.Liftgate:
          return mergedSensorProfile ? (
            <LiftgateSettings
              asset={selectedAsset}
              assetMergedSensorProfile={mergedSensorProfile}
              isAssetRefetching={isAssetRefetching}
            />
          ) : null;
        case AssetSettingsType.OtherSensors:
          return mergedSensorProfile ? (
            <OtherSensorsComponent
              asset={selectedAsset}
              assetMergedSensorProfile={mergedSensorProfile}
              isAssetRefetching={isAssetRefetching}
            />
          ) : null;
        default:
          return null;
      }
    }
    if (geofence) {
      switch (selectedType) {
        case GeofenceSettingsType.Details:
          return (
            <GeofenceSettingsTab
              geofence={geofence}
              tab={GeofenceSettingsType.Details}
              onBack={onBack}
            />
          );
        case GeofenceSettingsType.Operations:
          return (
            <GeofenceSettingsTab
              geofence={geofence}
              tab={GeofenceSettingsType.Operations}
              onBack={onBack}
            />
          );
        case GeofenceSettingsType.Capacity:
          return (
            <GeofenceSettingsTab
              geofence={geofence}
              tab={GeofenceSettingsType.Capacity}
              onBack={onBack}
            />
          );
        case GeofenceSettingsType.Restrictions:
          return (
            <GeofenceSettingsTab
              geofence={geofence}
              tab={GeofenceSettingsType.Restrictions}
              onBack={onBack}
            />
          );
        default:
          return null;
      }
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Box className="bg-background h-fit sm:h-full">
        <Box
          className="py-4 px-8 md:p-8 bg-background"
          data-testid="settings-tab-panel"
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems={isMobile ? "stretch" : "center"}
            direction={isMobile ? "column-reverse" : "row"}
            rowSpacing={isMobile ? 2 : 0}
            sx={{ padding: "8px 0" }}
          >
            <Grid item xs={12} md={6} data-testid="settings-title">
              <SectionHeader
                header
                title={assetSettingsTitles[selectedType as AssetSettingsType]}
                showDetailsHeader={true}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              sx={{ padding: "8px 16px" }}
            >
              <TextField
                data-testid="settings-tab-type"
                select
                fullWidth
                value={selectedType}
                variant={"outlined"}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedType(event.target.value);
                }}
              >
                {settingsTabTypes.map((option) => (
                  <MenuItem
                    data-testid={`settings-tab-type-option-${option.value}`}
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container className="mb-12 rounded-lg">
            {renderSettingsContent()}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
