import { useEffect, useRef, useState } from "react";
import { DistanceUnit } from "../../../../../../../../graphql/operations";
import RangeSlider from "../../../../../../../../shared/components/RangeSlider/RangeSlider";
import { useCurrentOrg } from "../../../../../../../../shared/hooks/useCurrentOrg";
import {
  convertKmToMiles,
  convertMilesToKm,
} from "../../../../../../../../utils/convertUnits";
import { formatNumber } from "../../../../../../../../utils/formatters";
import { useAssetsDataContext } from "../../../../../AssetsDataContext";

export const minTotalMileageValues = 0;
export const maxTotalMileageValues = 1_000_000;

const TotalMileageSlider: React.FC = () => {
  const {
    currentFilter: { totalMileage },
    onChangeFilters,
  } = useAssetsDataContext();
  const currentOrg = useCurrentOrg();
  const distanceUnit =
    currentOrg?.distance_unit_preference ?? DistanceUnit.Kilometers;
  const isDistanceUnitMiles = distanceUnit === DistanceUnit.Miles;

  // convert values if distance unit is miles, because we store distance values in kilometers
  const convertedStartValue =
    totalMileage?.startValue && isDistanceUnitMiles
      ? convertKmToMiles(totalMileage.startValue)
      : totalMileage?.startValue;

  const convertedEndValue =
    totalMileage?.endValue && isDistanceUnitMiles
      ? convertKmToMiles(totalMileage.endValue)
      : totalMileage?.endValue;

  const defaultValues = useRef<number[]>([
    convertedStartValue ?? minTotalMileageValues,
    convertedEndValue ?? maxTotalMileageValues,
  ]);

  const [values, setValues] = useState<number[]>(defaultValues.current);

  useEffect(() => {
    const incomingValue = [
      convertedStartValue ?? minTotalMileageValues,
      convertedEndValue ?? maxTotalMileageValues,
    ];
    if (JSON.stringify(incomingValue) !== JSON.stringify(values)) {
      setValues(incomingValue);
    }
    // We only want to trigger this effect when the value in store is different than our local
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [convertedStartValue, convertedEndValue]);

  const onValueChanged = (values: number | number[]) => {
    if (Array.isArray(values)) {
      // call onChange event to update filter values in asset context
      let [startValue, endValue] = values;

      // convert values to kilometers if distance unit is miles, because we store distance values in kilometers
      if (isDistanceUnitMiles) {
        startValue = convertMilesToKm(startValue);
        endValue = convertMilesToKm(endValue);
      }

      onChangeFilters({ totalMileage: { startValue, endValue } });
    }
  };

  const marks = [
    {
      value: minTotalMileageValues,
      label: `${formatNumber(minTotalMileageValues)} ${distanceUnit}`,
    },
    {
      value: maxTotalMileageValues,
      label: `${formatNumber(maxTotalMileageValues)} ${distanceUnit}`,
    },
  ];

  return (
    <RangeSlider
      min={minTotalMileageValues}
      max={maxTotalMileageValues}
      values={values}
      setValues={setValues}
      defaultValues={defaultValues.current}
      marks={marks}
      marksMargin={{
        left: "2.2rem",
        right: isDistanceUnitMiles ? "-2.5rem" : "-3.5rem",
      }}
      onChange={onValueChanged}
    />
  );
};

export default TotalMileageSlider;
