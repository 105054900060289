import { AlertTypeV2 } from "../../../../graphql/operations";
import { BINARY_COLORS, COLORS, DEFAULT_LEGEND_TEXT_COLOR } from "./constants";

export const mapChartColors = (name: string, index: number): string => {
  if (name === "Other") {
    return "var(--concrete)";
  }

  return `var(--${COLORS[index]})`;
};

export const mapLegendTextColor = (name: string): string => {
  if (name === "Other") {
    return "var(--dark-charcoal)";
  }

  return DEFAULT_LEGEND_TEXT_COLOR;
};

export const mapBinaryChartColors = (index: number): string => {
  return `var(--${BINARY_COLORS[index]})`;
};

export const mapAlertType = (type: AlertTypeV2): string => {
  // Replace underscores with spaces in the string value of the enum
  return type.replace(/_/g, " ");
};
