import {
  FieldOrGroup,
  NumberFieldSettings,
  Config,
  Fields,
} from "@react-awesome-query-builder/core";
import { MuiConfig } from "@react-awesome-query-builder/mui";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { QUERYBUILDER_DATE_FORMAT } from "../../../utils";

type ConfigNumberFiledProps = {
  min?: number;
  max?: number;
  step?: number;
};

export const prepareConfigDoorStatus = (label: string) => ({
  label,
  type: "select",
  operators: ["select_equals"],
  fieldSettings: {
    listValues: [
      { title: "Open", value: "open" },
      { title: "Closed", value: "closed" },
    ],
  },
});

export const prepareConfigTripState = (label: string) => ({
  label,
  type: "select",
  operators: ["select_equals"],
  fieldSettings: {
    listValues: [
      { title: "Parked", value: "parked" },
      { title: "Moving", value: "moving" },
    ],
  },
});

export const prepareConfigOutsideOfGeofenceState = (label: string) => ({
  label,
  type: "select",
  operators: ["select_equals"],
  fieldSettings: {
    listValues: [
      { title: "Yes", value: "yes" },
      { title: "No", value: "no" },
    ],
  },
});

export const prepareConfigTextField = (label: string) => ({
  label,
  type: "text",
  operators: [
    "equal",
    "not_equal",
    "like",
    "not_like",
    "is_empty",
    "is_not_empty",
    "is_null",
    "is_not_null",
  ],
});

export const prepareConfigBooleanField = (label: string) => ({
  label,
  type: "boolean",
  operators: ["equal", "not_equal"],
});

export const prepareConfigNumberField = (
  label: string,
  props?: ConfigNumberFiledProps
) => {
  const { min, max, step } = props || {};

  const fieldConfig: FieldOrGroup = {
    label,
    type: "number",
    preferWidgets: ["number"],
    fieldSettings: {} as NumberFieldSettings,
  };

  const fieldSettings = {} as NumberFieldSettings;

  if (!isNil(min)) {
    fieldSettings.min = min;
  }

  if (!isNil(max)) {
    fieldSettings.max = max;
  }

  if (!isEmpty(fieldSettings)) {
    fieldConfig.fieldSettings = fieldSettings;
  }

  if (step) {
    fieldConfig.widgets = {
      number: {
        widgetProps: {
          step,
        },
      },
    };
  }

  return fieldConfig;
};

export const prepareConfigDateField = (label: string) => ({
  label,
  type: "date",
  fieldSettings: {
    dateFormat: QUERYBUILDER_DATE_FORMAT,
  },
});

export const prepareConfigTimeField = (label: string) => ({
  label,
  type: "select",
  fieldSettings: {
    listValues: [
      { title: "00:00", value: "00:00:00.000000000" },
      { title: "00:30", value: "00:30:00.000000000" },
      { title: "01:00", value: "01:00:00.000000000" },
      { title: "01:30", value: "01:30:00.000000000" },
      { title: "02:00", value: "02:00:00.000000000" },
      { title: "02:30", value: "02:30:00.000000000" },
      { title: "03:00", value: "03:00:00.000000000" },
      { title: "03:30", value: "03:30:00.000000000" },
      { title: "04:00", value: "04:00:00.000000000" },
      { title: "04:30", value: "04:30:00.000000000" },
      { title: "05:00", value: "05:00:00.000000000" },
      { title: "05:30", value: "05:30:00.000000000" },
      { title: "06:00", value: "06:00:00.000000000" },
      { title: "06:30", value: "06:30:00.000000000" },
      { title: "07:00", value: "07:00:00.000000000" },
      { title: "07:30", value: "07:30:00.000000000" },
      { title: "08:00", value: "08:00:00.000000000" },
      { title: "08:30", value: "08:30:00.000000000" },
      { title: "09:00", value: "09:00:00.000000000" },
      { title: "09:30", value: "09:30:00.000000000" },
      { title: "10:00", value: "10:00:00.000000000" },
      { title: "10:30", value: "10:30:00.000000000" },
      { title: "11:00", value: "11:00:00.000000000" },
      { title: "11:30", value: "11:30:00.000000000" },
      { title: "12:00", value: "12:00:00.000000000" },
      { title: "12:30", value: "12:30:00.000000000" },
      { title: "13:00", value: "13:00:00.000000000" },
      { title: "13:30", value: "13:30:00.000000000" },
      { title: "14:00", value: "14:00:00.000000000" },
      { title: "14:30", value: "14:30:00.000000000" },
      { title: "15:00", value: "15:00:00.000000000" },
      { title: "15:30", value: "15:30:00.000000000" },
      { title: "16:00", value: "16:00:00.000000000" },
      { title: "16:30", value: "16:30:00.000000000" },
      { title: "17:00", value: "17:00:00.000000000" },
      { title: "17:30", value: "17:30:00.000000000" },
      { title: "18:00", value: "18:00:00.000000000" },
      { title: "18:30", value: "18:30:00.000000000" },
      { title: "19:00", value: "19:00:00.000000000" },
      { title: "19:30", value: "19:30:00.000000000" },
      { title: "20:00", value: "20:00:00.000000000" },
      { title: "20:30", value: "20:30:00.000000000" },
      { title: "21:00", value: "21:00:00.000000000" },
      { title: "21:30", value: "21:30:00.000000000" },
      { title: "22:00", value: "22:00:00.000000000" },
      { title: "22:30", value: "22:30:00.000000000" },
      { title: "23:00", value: "23:00:00.000000000" },
      { title: "23:30", value: "23:30:00.000000000" },
    ],
  },
});

export const prepareQueryBuilderConfig = (fields: Fields): Config => {
  const initialConfig: typeof MuiConfig = MuiConfig;

  return {
    ...initialConfig,
    fields,
  };
};
