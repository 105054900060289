import { first } from "lodash";
import {
  DWELL_STATUS_FILTER_VALUE_MAPPING,
  DWELL_STATUS_MAPPING,
  LAST_REPORTED_DATE_OPTIONS_MAP,
  TRIP_STATUS,
} from "../../../../../constants/map";
import { SelectedOrganization } from "../../../../../context/reducers/selectedOrganization";
import {
  MeUserData,
  SensorStatus,
  TableFilterInput,
  TableFilterLinkOperator,
  TableFilterOperator,
  TableFiltersInput,
  TableValueDataType,
} from "../../../../../graphql/operations";
import { prepareRegionTerritoriesFilter } from "../../../../../utils";
import { AssetFilters } from "../../../shared/AssetsDataContext";
import { isNilOrEmpty } from "../../../utils";

const getFilterEntry = (
  field: string,
  value: unknown,
  operator: TableFilterOperator,
  dataType: TableValueDataType = TableValueDataType.String
): TableFilterInput => ({
  dataType,
  field,
  operator,
  value:
    value || dataType === TableValueDataType.Boolean
      ? JSON.stringify({ value })
      : undefined,
});

export const mapAssetFilters = (
  rawFilters: Partial<AssetFilters>,
  userData: MeUserData | undefined,
  orgs: string[],
  selectedOrganization: SelectedOrganization,
  osFeatureFlag = false
): TableFiltersInput | undefined => {
  const filters: TableFilterInput[] = [];

  if (!rawFilters) {
    return undefined;
  }

  const capitalizeSensorValue = (values: SensorStatus[]) => {
    return values.map((value) =>
      osFeatureFlag ? value.charAt(0).toUpperCase() + value.slice(1) : value
    );
  };

  const {
    assetTypes,
    assetTags,
    productNames,
    subOrganizations,
    assetHealth,
    numberOfAxles,
    numberOfTires,
    weightStatus,
    totalMileage,
    assetYear,
    manufacturers,
    lastReportedDateRange,
    status,
    cargoUltrasonicState,
    doorState,
    internalCameraStatus,
    internalCameraFloorUsagePercentage,
    batteryPrimaryVoltage,
    batterySecondaryVoltage,
    backupBatteryVoltage,
    solarAmperage,
    atisAlpha,
    liteSentryGamma,
    temperatureInternal,
    wheelEndTemperature,
    airbag,
    battery,
    liftgate,
    dualImbalance,
    tpmsBeta,
    supplyPressure,
    tankPressure,
    installStatus,
    installer,
    installedDate,
    regions,
    signals,
    geofenceNames,
    geofenceCategories,
    zones,
  } = rawFilters;

  if (assetTypes?.length) {
    filters.push(
      getFilterEntry("category", assetTypes, TableFilterOperator.IsAnyOf)
    );
  }

  if (assetTags?.length) {
    filters.push(
      getFilterEntry("tags", assetTags, TableFilterOperator.IsAnyOf)
    );
  }

  if (productNames?.length) {
    filters.push(
      getFilterEntry(
        "productName",
        productNames.map((productName) => productName.value),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (orgs) {
    const orgName = osFeatureFlag ? "customer_orgs_id" : "orgName";

    const isExcludeSubOrgsFilterActive = !subOrganizations;

    const filteredOrgs = isExcludeSubOrgsFilterActive
      ? [selectedOrganization.value]
      : orgs;

    filters.push(
      getFilterEntry(orgName, filteredOrgs, TableFilterOperator.IsAnyOf)
    );
  }

  if (assetHealth?.length) {
    filters.push(
      getFilterEntry(
        "healthCondition",
        assetHealth,
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (numberOfAxles?.length) {
    filters.push(
      getFilterEntry(
        "numOfAxles",
        numberOfAxles.map((item) => item.value),
        TableFilterOperator.IsAnyOf,
        TableValueDataType.Number
      )
    );
  }

  if (numberOfTires?.length) {
    filters.push(
      getFilterEntry(
        "numOfTires",
        numberOfTires.map((item) => item.value),
        TableFilterOperator.IsAnyOf,
        TableValueDataType.Number
      )
    );
  }

  if (weightStatus?.startValue !== null && weightStatus?.endValue !== null) {
    filters.push(
      getFilterEntry(
        "emptyWeight",
        [weightStatus?.startValue, weightStatus?.endValue],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (totalMileage?.startValue !== null && totalMileage?.endValue !== null) {
    filters.push(
      getFilterEntry(
        "odo",
        [totalMileage?.startValue, totalMileage?.endValue],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (assetYear?.startValue !== null && assetYear?.endValue !== null) {
    filters.push(
      getFilterEntry(
        "year",
        [assetYear?.startValue, assetYear?.endValue],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (manufacturers?.length) {
    filters.push(
      getFilterEntry("manufacturer", manufacturers, TableFilterOperator.IsAnyOf)
    );
  }

  if (regions?.length) {
    const territoriesFilter = prepareRegionTerritoriesFilter({ regions });
    filters.push(
      getFilterEntry("state", territoriesFilter, TableFilterOperator.IsAnyOf)
    );
  }

  if (lastReportedDateRange) {
    const dateRange = LAST_REPORTED_DATE_OPTIONS_MAP.get(lastReportedDateRange);

    filters.push(
      getFilterEntry(
        "lastEventTime",
        [dateRange?.startDate, dateRange?.endDate],
        TableFilterOperator.InValueRange,
        TableValueDataType.Date
      )
    );
  }

  if (Array.isArray(status) && status.length > 0) {
    const tripStatusValues = status.filter((value) =>
      Object.values(TRIP_STATUS).includes(value as TRIP_STATUS)
    );

    /*
      filter dwelling statuses and map FE values to BE values
      needed because BE uses value 'large' for High Dwell
    */
    const dwellingStatusValues = status
      .filter((value) =>
        Object.keys(DWELL_STATUS_FILTER_VALUE_MAPPING).includes(value)
      )
      .map(
        (value) =>
          DWELL_STATUS_FILTER_VALUE_MAPPING[value as DWELL_STATUS_MAPPING]
      );

    if (tripStatusValues.length) {
      filters.push(
        getFilterEntry(
          "tripStatus",
          tripStatusValues,
          TableFilterOperator.IsAnyOf
        )
      );
    }

    if (dwellingStatusValues.length) {
      filters.push(
        getFilterEntry(
          "dwellStatus",
          dwellingStatusValues,
          TableFilterOperator.IsAnyOf
        )
      );
    }
  }

  if (!Array.isArray(status)) {
    filters.push(
      getFilterEntry(
        "dwellingDays",
        [status?.minDays, status?.maxDays],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (cargoUltrasonicState) {
    filters.push(
      getFilterEntry(
        "cargoUltrasonicState",
        cargoUltrasonicState,
        TableFilterOperator.Equals
      )
    );
  }

  if (doorState) {
    filters.push(
      getFilterEntry("doorState", doorState, TableFilterOperator.Equals)
    );
  }

  if (internalCameraStatus) {
    filters.push(
      getFilterEntry(
        "internalCameraStatus",
        internalCameraStatus,
        TableFilterOperator.Contains
      )
    );
  }

  if (internalCameraFloorUsagePercentage) {
    filters.push(
      getFilterEntry(
        "internalCameraFloorUsagePercentage",
        internalCameraFloorUsagePercentage,
        TableFilterOperator.Gte,
        TableValueDataType.Number
      )
    );
  }

  if (batteryPrimaryVoltage) {
    filters.push(
      getFilterEntry(
        "batteryPrimaryVoltage",
        [batteryPrimaryVoltage?.minVoltage, batteryPrimaryVoltage?.maxVoltage],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (batterySecondaryVoltage) {
    filters.push(
      getFilterEntry(
        "batterySecondaryVoltage",
        [
          batterySecondaryVoltage?.minVoltage,
          batterySecondaryVoltage?.maxVoltage,
        ],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (backupBatteryVoltage) {
    filters.push(
      getFilterEntry(
        "backupBatteryVoltage",
        [backupBatteryVoltage?.minVoltage, backupBatteryVoltage?.maxVoltage],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (solarAmperage) {
    filters.push(
      getFilterEntry(
        "solarAmperage",
        [solarAmperage?.minAmperage, solarAmperage?.maxAmperage],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (atisAlpha?.length) {
    filters.push(
      getFilterEntry(
        "atisAlphaHealthCondition",
        capitalizeSensorValue(atisAlpha),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (liteSentryGamma?.length) {
    filters.push(
      getFilterEntry(
        "liteSentryGammaHealthCondition",
        capitalizeSensorValue(liteSentryGamma),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (temperatureInternal?.length) {
    filters.push(
      getFilterEntry(
        "temperatureHealthCondition",
        capitalizeSensorValue(temperatureInternal),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (wheelEndTemperature?.length) {
    filters.push(
      getFilterEntry(
        "psiWheelEndHealthCondition",
        capitalizeSensorValue(wheelEndTemperature),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (airbag?.length) {
    filters.push(
      getFilterEntry(
        "airbagHealthCondition",
        capitalizeSensorValue(airbag),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (battery?.length) {
    filters.push(
      getFilterEntry(
        "batteryHealthCondition",
        capitalizeSensorValue(battery),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (dualImbalance?.length) {
    filters.push(
      getFilterEntry(
        "dualImbalanceHealthCondition",
        capitalizeSensorValue(dualImbalance),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (liftgate?.length) {
    filters.push(
      getFilterEntry(
        "liftgateHealthCondition",
        capitalizeSensorValue(liftgate),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (tpmsBeta?.length) {
    filters.push(
      getFilterEntry(
        "tpmsBetaHealthCondition",
        capitalizeSensorValue(tpmsBeta),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (supplyPressure?.length) {
    filters.push(
      getFilterEntry(
        "psiAirSupplyHealthCondition",
        capitalizeSensorValue(supplyPressure),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (tankPressure?.length) {
    filters.push(
      getFilterEntry(
        "psiAirSupplyHealthCondition",
        capitalizeSensorValue(tankPressure),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (!installStatus) {
    filters.push(
      getFilterEntry("imei", undefined, TableFilterOperator.IsEmpty)
    );
  }

  if (installer) {
    filters.push(
      getFilterEntry("installer", installer, TableFilterOperator.Contains)
    );
  }

  if (installedDate?.startDate && installedDate?.endDate) {
    filters.push(
      getFilterEntry(
        "installedDate",
        [installedDate.startDate, installedDate.endDate],
        TableFilterOperator.InValueRange,
        TableValueDataType.Date
      )
    );
  }

  if ((signals ?? "all") !== "all") {
    filters.push(
      getFilterEntry(
        "signal",
        signals,
        TableFilterOperator.Equals,
        TableValueDataType.Boolean
      )
    );
  }

  if (geofenceNames?.length) {
    filters.push(
      getFilterEntry(
        "geofenceNames",
        geofenceNames,
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (geofenceCategories?.length) {
    filters.push(
      getFilterEntry(
        "geofenceCategories",
        geofenceCategories.map((category) => category.label),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (zones?.length) {
    let allExactMatches: string[][] = [];
    // Each zone could have ranges and matches
    for (const zone of zones) {
      const { ranges, matches, country } = zone;

      // If ranges are set
      if (!isNilOrEmpty(ranges)) {
        // Generate a filter clause for each range (the back-end combines them into a "should" clause)
        for (const range of ranges as string[][]) {
          /*
            We pass in a tuple where the first element is the country
            and the 2nd and 3rd are the "startValue" and "endValue"
            of the range.
          */
          filters.push(
            getFilterEntry(
              "zones",
              [country, parseInt(range[0]), parseInt(range[1])],
              TableFilterOperator.InValueRange,
              TableValueDataType.Number
            )
          );
        }
      }

      // If the zone has exact matches
      if (!isNilOrEmpty(matches)) {
        const newMatches = matches as string[];
        /*
          We combine matches into an array of arrays where each nested array
          is a tuple with the first element being the country, followed by
          all of the matches.

          The back-end will create separate clauses for each country and
          its matches and include them into the "should clause".
        */
        const existingCountryArray = allExactMatches.find(
          (array) => first(array) === country
        );
        if (existingCountryArray) existingCountryArray.push(...newMatches);
        else allExactMatches = [...allExactMatches, [country, ...newMatches]];
      }
    }

    if (!isNilOrEmpty(allExactMatches)) {
      filters.push(
        getFilterEntry("zones", allExactMatches, TableFilterOperator.IsAnyOf)
      );
    }
  }

  return {
    filters,
    linkOperator: TableFilterLinkOperator.And,
  };
};
