import { Box } from "@mui/material";
import { ReactComponent as PsiAirSupplySensorDark } from "../../../../../../assets/svgs/airTankSensorDark.svg";
import { ReactComponent as AirBagSensorDark } from "../../../../../../assets/svgs/airbagSensorDark.svg";
import { ReactComponent as AirBagSensorLight } from "../../../../../../assets/svgs/airbagSensorLight.svg";
import { ReactComponent as PsiAirSupplySensorLight } from "../../../../../../assets/svgs/airtankSensorLight.svg";
import { ReactComponent as ATISSensorLight } from "../../../../../../assets/svgs/aitsSensorLight.svg";
import { ReactComponent as ATISSensorDark } from "../../../../../../assets/svgs/atisSensorDark.svg";
import { ReactComponent as BatterySensorDark } from "../../../../../../assets/svgs/batterySensorDark.svg";
import { ReactComponent as BatterySensorLight } from "../../../../../../assets/svgs/batterySensorLight.svg";
import { ReactComponent as CargoSensorDark } from "../../../../../../assets/svgs/cargoSensorDark.svg";
import { ReactComponent as CargoSensorLight } from "../../../../../../assets/svgs/cargoSensorLight.svg";
import { ReactComponent as CargoVisionSensorDark } from "../../../../../../assets/svgs/cargoVisionSensorDark.svg";
import { ReactComponent as CargoVisionSensorLight } from "../../../../../../assets/svgs/cargoVisionSensorLight.svg";
import { ReactComponent as DoorSensorDark } from "../../../../../../assets/svgs/doorSensorDark.svg";
import { ReactComponent as DoorSensorLight } from "../../../../../../assets/svgs/doorSensorLight.svg";
import { ReactComponent as DwellSensorDark } from "../../../../../../assets/svgs/dwellSensorDark.svg";
import { ReactComponent as DwellSensorLight } from "../../../../../../assets/svgs/dwellSensorLight.svg";
import { ReactComponent as LiteSentrySensorDark } from "../../../../../../assets/svgs/lightOutSensorDark.svg";
import { ReactComponent as LiteSentrySensorLight } from "../../../../../../assets/svgs/lightOutSensorLight.svg";
import { ReactComponent as TemperatureSensorDark } from "../../../../../../assets/svgs/temperatureSensorDark.svg";
import { ReactComponent as TemperatureSensorLight } from "../../../../../../assets/svgs/temperatureSensorLight.svg";
import { ReactComponent as TPMSSensorDark } from "../../../../../../assets/svgs/tpmsSensorDark.svg";
import { ReactComponent as TPMSSensorLight } from "../../../../../../assets/svgs/tpmsSensorLight.svg";
import { ReactComponent as PsiWheelEndSensorDark } from "../../../../../../assets/svgs/wheelEndTemperatureSensorDark.svg";
import { ReactComponent as PsiWheelEndSensorLight } from "../../../../../../assets/svgs/wheelEndTemperatureSensorLight.svg";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  Asset,
  SensorStatus,
  SensorStatusUppercase,
} from "../../../../../../graphql/operations";
import {
  AssetSensorType,
  AssetShortTooltipSensor,
  CargoState,
  DoorSensorState,
  DwellSensorState,
} from "../components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import { formatDwellSensorLabel } from "../helpers";

export const useGetEnabledSensors = (selectedAsset: Asset | null) => {
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const boxStyleSettings = {
    height: "24px",
    width: "24px",
  };
  const svgIconSettings = {
    width: "100%",
    height: "100%",
    display: "block",
  };

  let cargoVisionState: CargoState | SensorStatus = SensorStatus.Unknown;
  if (selectedAsset?.sensors?.cargoCamera?.data?.floorUsageStatus) {
    // Cargo Vision status can be `Mostly LOADED`, `Fully LOADED`, `Partially LOADED` or `EMPTY`, but we only care about if it's loaded or empty
    cargoVisionState = selectedAsset.sensors.cargoCamera.data.floorUsageStatus
      .toLowerCase()
      .includes(CargoState.Loaded)
      ? CargoState.Loaded
      : CargoState.Empty;
  }

  const enabledSensors: AssetShortTooltipSensor[] = [
    // Battery
    {
      type: AssetSensorType.Battery,
      state:
        selectedAsset?.sensors?.voltage?.statusVoltage ??
        SensorStatusUppercase.Unknown,
      sensorLabel: "Battery",
      icon: (
        <Box sx={boxStyleSettings} data-testid="battery-sensor-icon">
          {isLightTheme ? (
            <BatterySensorDark style={svgIconSettings} />
          ) : (
            <BatterySensorLight style={svgIconSettings} />
          )}
        </Box>
      ),
    },
    // Dwell
    {
      type: AssetSensorType.Dwell,
      state:
        (selectedAsset?.dwelling?.dwellingStatus as DwellSensorState) ??
        SensorStatus.Unknown,
      sensorLabel: "Dwell",
      chipLabel: formatDwellSensorLabel(
        selectedAsset?.dwelling?.dwellingDays ?? undefined
      ),
      icon: (
        <Box sx={boxStyleSettings} data-testid="dwell-widget-status">
          {isLightTheme ? (
            <DwellSensorDark
              style={svgIconSettings}
              data-testid="dwell-sensor-icon"
            />
          ) : (
            <DwellSensorLight
              style={svgIconSettings}
              data-testid="dwell-sensor-icon"
            />
          )}
        </Box>
      ),
    },
    // Cargo Ultrasonic
    {
      type: AssetSensorType.CargoUltrasonic,
      state: selectedAsset?.sensors?.chassis?.data?.cargoState as CargoState,
      sensorLabel: "Cargo",
      icon: (
        <Box sx={boxStyleSettings} data-testid="cargo-ultrasonic-status">
          {isLightTheme ? (
            <CargoSensorDark style={svgIconSettings} />
          ) : (
            <CargoSensorLight style={svgIconSettings} />
          )}
        </Box>
      ),
    },
    // Cargo Vision
    {
      type: AssetSensorType.CargoVision,
      state: cargoVisionState,
      sensorLabel: "Cargo Vision",
      icon: (
        <Box sx={boxStyleSettings} data-testid="battery-widget-status">
          {isLightTheme ? (
            <CargoVisionSensorDark style={svgIconSettings} />
          ) : (
            <CargoVisionSensorLight style={svgIconSettings} />
          )}
        </Box>
      ),
    },
    // Air Bag
    {
      type: AssetSensorType.AirBag,
      state:
        selectedAsset?.sensors?.airbag?.statusAirbag ??
        SensorStatusUppercase.Unknown,
      sensorLabel: "Air Bag",
      icon: (
        <Box sx={boxStyleSettings} data-testid="battery-widget-status">
          {isLightTheme ? (
            <AirBagSensorDark style={svgIconSettings} />
          ) : (
            <AirBagSensorLight style={svgIconSettings} />
          )}
        </Box>
      ),
    },
    // Door
    {
      type: AssetSensorType.Door,
      state: selectedAsset?.sensors?.bleDoorSensor?.data
        ?.doorState as DoorSensorState,
      sensorLabel: "Door",
      icon: (
        <Box sx={boxStyleSettings} data-testid="battery-widget-status">
          {isLightTheme ? (
            <DoorSensorDark style={svgIconSettings} />
          ) : (
            <DoorSensorLight style={svgIconSettings} />
          )}
        </Box>
      ),
    },
    // TPMS
    {
      type: AssetSensorType.TPMS,
      state:
        selectedAsset?.sensors?.tpmsBeta?.statusTpmsBeta ??
        SensorStatusUppercase.Unknown,
      sensorLabel: "TPMS",
      icon: (
        <Box sx={boxStyleSettings} data-testid="battery-widget-status">
          {isLightTheme ? (
            <TPMSSensorDark style={svgIconSettings} />
          ) : (
            <TPMSSensorLight style={svgIconSettings} />
          )}
        </Box>
      ),
    },

    // psiAirSupply
    {
      type: AssetSensorType.AirSupply,
      state:
        selectedAsset?.sensors?.psiAirSupply?.statusPsiAirSupply ??
        SensorStatusUppercase.Unknown,
      sensorLabel: "Air Supply",
      icon: (
        <Box sx={boxStyleSettings} data-testid="battery-widget-status">
          {isLightTheme ? (
            <PsiAirSupplySensorDark style={svgIconSettings} />
          ) : (
            <PsiAirSupplySensorLight style={svgIconSettings} />
          )}
        </Box>
      ),
    },
    // temperature > internal
    {
      type: AssetSensorType.TemperatureInternal,
      state:
        selectedAsset?.sensors?.temperature?.data?.statusInternal ??
        SensorStatusUppercase.Unknown,
      sensorLabel: "Temperature (int)",
      icon: (
        <Box sx={boxStyleSettings} data-testid="temperature-widget-status">
          {isLightTheme ? (
            <TemperatureSensorDark style={svgIconSettings} />
          ) : (
            <TemperatureSensorLight style={svgIconSettings} />
          )}
        </Box>
      ),
    },
    // atisAlpha
    {
      type: AssetSensorType.AtisAlpha,
      state:
        selectedAsset?.sensors?.atisAlpha?.statusAtisAlpha ??
        SensorStatusUppercase.Unknown,
      sensorLabel: "ATIS",
      icon: (
        <Box sx={boxStyleSettings} data-testid="temperature-widget-status">
          {isLightTheme ? (
            <ATISSensorDark style={svgIconSettings} />
          ) : (
            <ATISSensorLight style={svgIconSettings} />
          )}
        </Box>
      ),
    },
    // psiWheelEnd > temperature
    {
      type: AssetSensorType.WheelEndTemperature,
      state:
        selectedAsset?.sensors?.psiWheelEnd?.statusPsiWheelEnd ??
        SensorStatusUppercase.Unknown,
      sensorLabel: "Wheel End Temperature",
      icon: (
        <Box sx={boxStyleSettings} data-testid="temperature-widget-status">
          {isLightTheme ? (
            <PsiWheelEndSensorDark style={svgIconSettings} />
          ) : (
            <PsiWheelEndSensorLight style={svgIconSettings} />
          )}
        </Box>
      ),
    },
    // liteSentryGamma
    {
      type: AssetSensorType.LiteSentryGamma,
      state:
        selectedAsset?.sensors?.liteSentryGamma?.statusLiteSentryGamma ??
        SensorStatusUppercase.Unknown,
      sensorLabel: "Light Circuit",
      icon: (
        <Box sx={boxStyleSettings} data-testid="temperature-widget-status">
          {isLightTheme ? (
            <LiteSentrySensorDark style={svgIconSettings} />
          ) : (
            <LiteSentrySensorLight style={svgIconSettings} />
          )}
        </Box>
      ),
    },
  ];
  return { enabledSensors };
};
