import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { forgotPassword } from "../../services/aws/auth";
import Logo from "../../shared/components/Svg/Logo";
import { NavigationRoutes } from "../../utils/routes/routesUtils";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [error, setError] = useState("");

  const handlePasswordReset = async () => {
    setError("");

    if (username.length < 2)
      return setError("Username must be at least 2 characters");

    try {
      await forgotPassword(username);
      const usernameParam = `?username=${username}`;
      navigate(`${NavigationRoutes.ConfirmForgotPassword}${usernameParam}`);
    } catch (error) {
      setError("Forgot password failed");
    }
  };

  return (
    <Box className="w-full h-full flex flex-col justify-center items-center bg-gray">
      <Box className="w-64 h-full flex flex-col justify-center items-center bg-gray">
        <Logo />
        <Box className="mt-16 text-lg font-medium">Forgot your password?</Box>
        <Box className="my-4 mb-2 text-sm font-thin">
          Enter your E-mail or Username and we will send a message to reset your
          password
        </Box>
        {error && (
          <Box className="mt-2 text-sm font-medium text-error">{error}</Box>
        )}
        <Box className="mt-4 w-[200px] flex flex-col justify-center">
          <input
            className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="E-mail or Username"
            required
          />
          <button
            className="mb-2 h-[40px] rounded bg-primary-blue text-white hover:bg-brand-light-blue"
            onClick={handlePasswordReset}
          >
            Reset my password
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ForgotPassword);
