import { memo, FC, useState, useEffect, useMemo, useCallback } from "react";
import { Box, ThemeProvider } from "@mui/material";
import { GridSortModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { GridColDef } from "@mui/x-data-grid/models";
import { isEmpty, isUndefined } from "lodash";
import { useAppContext } from "../../../../../context/AppContext";
import {
  Asset,
  AssetFilterListInput,
  AssetSortOrder,
  FiltersInput,
  useFindAssetsByGeofenceQuery,
  TableViewType,
  useFindAssetsByGeofenceOsQuery,
} from "../../../../../graphql/operations";
import { Table, TableDataModes } from "../../../../../shared/components/Table";
import { useExportedFileName } from "../../../../../shared/hooks/useExportedFileName";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { useProductsList } from "../../../../../shared/hooks/useProductsList";
import { resolvePromiseSafely, useFeatureFlag } from "../../../../../utils";
import { FeatureFlags } from "../../../../../utils/featureFlagsConstants";
import { validateTableFilters } from "../../../../../utils/validateTableFilters";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../../AdminPanel/tabs/constants";
import { serializeDwellTime } from "../../../TableView/assetUtils";
import {
  getColumns as getAlertColumns,
  SEARCH_KEYS,
} from "../../../TableView/components/AssetManagementTable/columns";
import { useSettingsTheme } from "../../hooks/useSettingsTheme";
import { columnVisibilityModel } from "./columns";

export type GeofenceAssetListProps = {
  geofenceId: string;
};

export const GeofenceAssetsList: FC<GeofenceAssetListProps> = memo(
  ({ geofenceId }: GeofenceAssetListProps) => {
    const {
      state: { appConfig },
    } = useAppContext();
    const theme = useSettingsTheme();
    const gridApiRef = useGridApiRef();
    const { productsMap } = useProductsList();
    const fileName = useExportedFileName("Geofence Assets");

    const [assetsPagination, setAssetPagination] = useState(0);
    const [currentPageNo, setCurrentPageNo] = useState<number>(1);
    const [searchValue, setSearchValue] = useState("");
    const initialSortModel: GridSortModel = [
      { field: "lst_evnt_t", sort: "desc" },
    ];
    const [sortModel, setSortModel] = useState<GridSortModel>(initialSortModel);
    const [filters, setFilters] = useState<AssetFilterListInput | null>(null);
    const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
      FeatureFlags.Connect1FetchAssetsFromOpenSearch
    );
    const pageSize = appConfig.table.geofenceAssetsPerPage;

    const assetsByGeofenceQuery = useFindAssetsByGeofenceQuery(
      {
        input: {
          geofenceId,
          skip: assetsPagination,
          limit: pageSize,
          search: searchValue,
          sort: {
            field: sortModel[0]?.field ?? initialSortModel[0].field,
            direction:
              (sortModel[0]?.sort as AssetSortOrder) ?? AssetSortOrder.Desc,
          },
          filterList: filters?.filters
            ? ([filters] as AssetFilterListInput[])
            : null,
        },
      },
      {
        enabled: !fetchAssetsFromOpenSearchFeatureFlag,
      }
    );

    const assetsByGeofenceQueryOS = useFindAssetsByGeofenceOsQuery(
      {
        input: {
          geofenceId,
          skip: assetsPagination,
          limit: pageSize,
          search: searchValue,
          sort: {
            field: sortModel[0]?.field,
            direction: sortModel[0]?.sort as AssetSortOrder,
          },
          filterList: filters?.filters
            ? ([filters] as AssetFilterListInput[])
            : null,
        },
      },
      {
        enabled: fetchAssetsFromOpenSearchFeatureFlag,
      }
    );

    const {
      isSuccess: isAssetDataSuccess,
      isLoading: isAssetDataLoading,
      refetch,
    } = fetchAssetsFromOpenSearchFeatureFlag
      ? assetsByGeofenceQueryOS
      : assetsByGeofenceQuery;

    const assetsByGeofence = useMemo(() => {
      const assets = fetchAssetsFromOpenSearchFeatureFlag
        ? assetsByGeofenceQueryOS?.data?.findAssetsByGeofenceOS.assets
        : assetsByGeofenceQuery?.data?.findAssetsByGeofence.assets;
      return (assets as Asset[]) || [];
    }, [
      assetsByGeofenceQuery,
      assetsByGeofenceQueryOS,
      fetchAssetsFromOpenSearchFeatureFlag,
    ]);

    const alertColumns = getAlertColumns(usePreferredTimezone());

    const updatedColumns = alertColumns.map((column) => {
      if (column.field === "org_name") {
        return { ...column, filterable: false };
      }
      return column;
    });

    const columns: GridColDef<Asset>[] = [
      ...updatedColumns,
      {
        field: "dwelling.dwellingDays",
        headerName: "Dwell",
        flex: 1,
        minWidth: 120,
        valueGetter: (params) =>
          serializeDwellTime(params.row.dwelling?.dwellingDays ?? undefined),
      },
    ];

    const tableRows = useMemo(
      () =>
        assetsByGeofence.map((asset) => ({
          ...asset,
          prd_name:
            productsMap.get(asset.prd_cde as string)?.product_name ??
            asset?.prd_cde,
          device_associated: isEmpty(asset.imei) ? "No" : "Yes",
        })) ?? [],
      [assetsByGeofence, productsMap]
    );
    const rowCount = assetsByGeofence.length;
    const [rowCountState, setRowCountState] = useState<number>(rowCount);

    useEffect(() => {
      setRowCountState((prevRowCountState: number) =>
        !isAssetDataLoading ? rowCount : prevRowCountState
      );
    }, [rowCount, setRowCountState, isAssetDataLoading]);

    const onPageChange = useCallback(
      (page: number) => {
        setCurrentPageNo(page);
        setAssetPagination(pageSize * (page - 1));
      },
      [pageSize]
    );

    const onSearch = useCallback((value: string) => {
      setSearchValue(value);
    }, []);

    const onSortModelChange = useCallback((sortModel: GridSortModel) => {
      setSortModel(sortModel);
    }, []);

    const onDownloadHandler = () => {
      gridApiRef.current.setSelectionModel([]);
      resolvePromiseSafely(gridApiRef.current.exportDataAsExcel({ fileName }));
    };

    const onFilterChange = (value: {
      items: FiltersInput[];
      linkOperator: string;
    }) => {
      setCurrentPageNo(1);
      setAssetPagination(0);
      const newFilters = validateTableFilters(value);
      if (!isUndefined(newFilters)) {
        setFilters(newFilters);
      }
    };

    useEffect(() => {
      refetch();
    }, [searchValue, sortModel, filters, refetch]);

    return (
      <ThemeProvider theme={theme}>
        <Box className="bg-background h-full overflow-auto">
          <Box className="flex h-full w-full pt-2 px-4 pb-4 md:px-6 lg:px-16 lg:pb-40">
            <Table
              tableType={TableViewType.GeofenceAssets}
              onPageChange={onPageChange}
              currentPage={currentPageNo}
              onFilterModelChange={onFilterChange}
              onPaginationModelChange={onPageChange}
              rowCount={rowCountState}
              handleDataMode={TableDataModes.SemiServer}
              columns={columns}
              rows={tableRows}
              error={!isAssetDataLoading && !isAssetDataSuccess ? true : null}
              showToolbar
              apiRef={gridApiRef}
              columnVisibilityModel={columnVisibilityModel}
              enableSearch={true}
              searchExactMatch
              searchKeys={SEARCH_KEYS}
              searchMinLength={appConfig.searchMinLength}
              getRowId={(row) => row._id}
              sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
              exportProps={{
                csvOptions: { fileName },
                excelOptions: { fileName },
                printOptions: { fileName },
              }}
              initialState={{ sorting: { sortModel: initialSortModel } }}
              tableName="geofence_assets_table"
              pageSize={pageSize}
              loading={isAssetDataLoading}
              allowExport
              disableRowGrouping
              onSearch={onSearch}
              onSortModelChange={onSortModelChange}
              mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
            />
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
);
