import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { NavigationRoutes } from "../../utils/routes/routesUtils";

/*
 *  An enum of URL parts related to different Asset Views.
 */
export enum AssetPagesUrlTypes {
  Table = "table",
  Gallery = "gallery",
  Geofences = "geofences",
  Invitations = "invitations",
  Map = "map",
}

// Common classes used for the arrow buttons that hide/show the drawers
export const ARROW_COMMON_CLASSES =
  "absolute top-[46%] h-[53px] flex flex-col justify-center text-black bg-background hover:cursor-pointer";

export const getViewFromUrl = (pathname: string) => {
  if (pathname.includes(AssetPagesUrlTypes.Table))
    return AssetPagesUrlTypes.Table;
  if (pathname.includes(AssetPagesUrlTypes.Gallery))
    return AssetPagesUrlTypes.Gallery;
  if (pathname.includes(AssetPagesUrlTypes.Geofences))
    return AssetPagesUrlTypes.Geofences;
  if (pathname.includes(AssetPagesUrlTypes.Invitations))
    return AssetPagesUrlTypes.Invitations;
  return AssetPagesUrlTypes.Map;
};

export const getNavigationRouteBasedOnSelectedView = (view?: string | null) => {
  switch (view) {
    case AssetPagesUrlTypes.Table:
      return NavigationRoutes.AssetTable;
    case AssetPagesUrlTypes.Gallery:
      return NavigationRoutes.AssetGallery;
    case AssetPagesUrlTypes.Map:
      return NavigationRoutes.AssetMap;
    default:
      return NavigationRoutes.AssetTable;
  }
};

export const isNilOrEmpty = (value: unknown) => isNil(value) || isEmpty(value);
