import { NavigationRoutes } from "./routes/routesUtils";

interface DataCollection {
  [key: string]: any;
}

interface OccurenceCount {
  [key: string]: number;
}
interface OccurenceCountWithLimitedData {
  counts: OccurenceCount;
  data: DataCollection[];
}

/*******************************************
 * Ideal for Group by Autocomplete options
 * Params
 * @Collection = [
 * {name: 'abc', id:1, title:'Developer'},
 * {name: 'abc', id:1, title:'Developer'},
 * {name: 'abc', id:1, title:'Tester'},
 * {name: 'abc', id:1, title:'Developer'},
 * {name: 'abc', id:1, title:'Tester'},
 * {name: 'abc', id:1, title:'Manager'},
 * {name: 'abc', id:1, title:'Developer'},
 * {name: 'abc', id:1, title:'Manager'},
 * {name: 'abc', id:1, title:'Tester'},
 * {name: 'abc', id:1, title:'Developer'},
 * {name: 'abc', id:1, title:'Tester'},
 * {name: 'abc', id:1, title:'Developer'},
 * ]
 * @Key = "title"
 * @Limit = 2
 * Return total occurence count with options as per limit
 * Since Limit is 2 it will return two options for each occurence type
 *
 * returns {
 * counts: {Developer: 6, Tester: 4, Manager: 2}, data:
 * [
 *  {name: 'abc', id:1, title:'Developer'},
 *  {name: 'abc', id:1, title:'Developer'},
 *  {name: 'abc', id:1, title:'Tester'},
 *  {name: 'abc', id:1, title:'Tester'},
 *  {name: 'abc', id:1, title:'Manager'},
 *  {name: 'abc', id:1, title:'Manager'},
 * ]
 * }
 *
 * @SkipKey = 'Tester' will return all tester but
 * 2 Developer and Manager each
 *
 */

export const getLimitedResultWithTotalCount = (
  collection: DataCollection[],
  key: string,
  limit: number,
  skipKeys?: string[]
): OccurenceCountWithLimitedData =>
  collection.reduce(
    (
      result: OccurenceCountWithLimitedData,
      item: DataCollection
    ): OccurenceCountWithLimitedData => {
      if (result.counts[item[key]]) {
        result.counts[item[key]]++;
        if (
          result.counts[item[key]] <= limit ||
          (skipKeys && skipKeys.includes(item[key]))
        ) {
          result.data.push(item);
        }
      } else {
        result.counts[item[key]] = 1;
        result.data.push(item);
      }
      return result;
    },
    { counts: {}, data: [] }
  );
export const navigateToRoute = (
  route: NavigationRoutes | string,
  addToHistory: boolean = true
): void => {
  if (addToHistory) {
    window.location.href = route;
  } else {
    window.location.replace(route);
  }

  return;
};
