import { useState, useEffect, useMemo } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import { SMALLEST_SCREEN_WIDTH } from "../../constants/map";
import { useAppContext } from "../../context/AppContext";
import { useAuthContext } from "../../context/AuthContext";
import RequireAuth from "../../permissions/RequireAuth";
import useBreakpoint from "../../shared/hooks/useBreakpoint";
import { useFeatureFlag } from "../../utils";
import { FeatureFlags } from "../../utils/featureFlagsConstants";
import { NavigationRoutes } from "../../utils/routes/routesUtils";
import { withDisplayName } from "../../utils/withDisplayName";
import { GalleryView } from "./GalleryView/GalleryView";
import MapView from "./MapView";
import AssetsDrawer from "./MapView/Shared/AssetsDrawer/AssetsDrawer";
import FiltersDrawer from "./MapView/Shared/FiltersDrawer/FiltersDrawer";
import { ShareAssetsView } from "./ShareAssetsView/ShareAssetsView";
import TableView from "./TableView";
import { useAssetsDataContext } from "./shared/AssetsDataContext/AssetsDataContext";
import { AssetPagesUrlTypes, getViewFromUrl } from "./utils";

export type TFieldValues = {
  minDays: number;
  maxDays: number;
};

const Geofences = withDisplayName(Outlet, "Geofences");

const AssetsViewContainer = () => {
  const {
    isFiltersDrawerOpen,
    setIsFiltersDrawerOpen,
    isAssetsDrawerOpen,
    setIsAssetsDrawerOpen,
    isAssetsDrawerVisible,
  } = useAssetsDataContext();
  const isSmallerThanFilters = useBreakpoint("down", SMALLEST_SCREEN_WIDTH);
  const isMobile = useBreakpoint("down", "sm");
  const { userRolePermissions } = useAuthContext();

  const {
    state: { appConfig },
  } = useAppContext();
  const { selectedAssetsView } = appConfig;
  const urlView = getViewFromUrl(window.location.pathname);

  const geoFencingFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1RolloutGeofencing
  );

  // State
  const [wasMobile, setWasMobile] = useState(isMobile);

  // Effects
  useEffect(() => {
    // Handle drawers auto closing on mobile
    if (isMobile && !wasMobile) {
      if (isFiltersDrawerOpen || isAssetsDrawerOpen) {
        setIsFiltersDrawerOpen(false);
        setIsAssetsDrawerOpen(false);
      }
    }

    // Handle drawers auto opening on desktop
    if (!isMobile && wasMobile) {
      if (!isFiltersDrawerOpen || !isAssetsDrawerOpen) {
        setIsFiltersDrawerOpen(true);
        setIsAssetsDrawerOpen(true);
      }
    }

    setWasMobile(isMobile);
  }, [
    isMobile,
    wasMobile,
    isFiltersDrawerOpen,
    isAssetsDrawerOpen,
    setIsAssetsDrawerOpen,
    setIsFiltersDrawerOpen,
  ]);

  const view = useMemo(
    () => selectedAssetsView ?? urlView,
    [selectedAssetsView, urlView]
  );

  const AssetsViewRouter = useMemo(
    () => (
      <Routes>
        <Route path={NavigationRoutes.Map} element={<MapView />} />
        <Route path={NavigationRoutes.Table} element={<TableView />} />
        <Route path={NavigationRoutes.Gallery} element={<GalleryView />} />
        <Route
          path={NavigationRoutes.SharedAssetInvitation}
          element={<ShareAssetsView />}
        />

        {geoFencingFeatureFlag && userRolePermissions.geofences.view && (
          <Route
            path={NavigationRoutes.Geo}
            element={
              <RequireAuth>
                <Geofences />
              </RequireAuth>
            }
          >
            <Route path="" element={<MapView />} />
            <Route path=":id" element={<MapView />} />
          </Route>
        )}

        <Route path="*" element={<Navigate to={view} relative="route" />} />
      </Routes>
    ),
    [geoFencingFeatureFlag, userRolePermissions, view]
  );

  // Hide all kind of drawers on invitations view
  if (urlView === "invitations") return AssetsViewRouter;

  return (
    <Box className="relative w-full h-full flex flex-1 overflow-hidden">
      <FiltersDrawer />

      {/* Hide children on smallest screen and open drawer */}
      {isSmallerThanFilters &&
      isFiltersDrawerOpen &&
      view !== AssetPagesUrlTypes.Map
        ? null
        : AssetsViewRouter}

      {isAssetsDrawerVisible && <AssetsDrawer />}
    </Box>
  );
};

export default AssetsViewContainer;
