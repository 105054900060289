import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { AwsRum, AwsRumConfig } from "aws-rum-web";
import AWS from "aws-sdk";
import { interval } from "rxjs";
import packageJson from "../../package.json";
import config from "../config";
import { env } from "../env";
import { UserRole } from "../graphql/operations";
import { useRefreshCognitoAuthTokens } from "../services/cognito";
import {
  defaultUserRolePermissions,
  UserRolePermissionsByScope,
  getPermissionsMap,
} from "../shared/components/WithPermissions/userRolePermissions";
import { themes } from "../shared/hooks/theme/utils";
import useIsMounted from "../shared/hooks/useIsMounted";

const { region, cognito } = config;

AWS.config.region = region;

export type CognitoToken = {
  expiresIn: number;
  idToken: string;
  accessToken?: string;
  refreshToken: string;
};

export type UserInfo = {
  atHash: string;
  sub: string;
  groups: UserRole[];
  emailVerified: string;
  username: string;
  givenName: string;
  familyName: string;
  email: string;
};

export type ImpersonationSession = {
  active: boolean;
  impersonate_by: string;
  impersonated_user_id: string;
  added_date: string | Date | number;
  updated_date: string | Date | number;
} | null;

export type AuthContextState = {
  tokens: CognitoToken | null;
  accessToken: any;
  isAuthorized: boolean;
  userInfo: UserInfo | null;
  userRolePermissions: UserRolePermissionsByScope;
  login: (tokens: CognitoToken) => void;
  logout: () => void;
  refreshAuthTokens$: (callbackFunction?: any) => any;
  impersonationSession: ImpersonationSession;
  setImpersonationSession: (
    impersonationSessionData: ImpersonationSession
  ) => void;
  decodedToken: any;
};

const AuthContext = createContext<AuthContextState>({
  tokens: null,
  accessToken: null,
  isAuthorized: false,
  userInfo: null,
  userRolePermissions: defaultUserRolePermissions,
  login: () => {},
  logout: () => {},
  refreshAuthTokens$: (callbackFunction?: any): any => {},
  impersonationSession: null,
  setImpersonationSession: () => {},
  decodedToken: null,
});

const AUTH_COOKIE_NAME = "PI-auth-tokens";
const REFRESH_COOKIE_NAME = "PI-refresh-token";
const ACCESS_COOKIE_NAME = "PI-access-token";

const getRolePermissions = (decodedToken: any): UserRolePermissionsByScope => {
  const userRoles: string[] = decodedToken
    ? decodedToken["cognito:groups"]
    : [];
  return getPermissionsMap(userRoles);
};

const AuthContextProvider = ({ children }: { children: ReactElement }) => {
  const isMounted = useIsMounted();
  const [cookies, setCookie, removeCookie] = useCookies([
    AUTH_COOKIE_NAME,
    ACCESS_COOKIE_NAME,
    REFRESH_COOKIE_NAME,
  ]);
  const [accessCookies, setAccessCookies] = useCookies([ACCESS_COOKIE_NAME]);
  const [refreshCookie, setRefreshCookie] = useCookies([REFRESH_COOKIE_NAME]);
  const [accessToken, setAccessToken] = useState<string | undefined>(
    accessCookies[ACCESS_COOKIE_NAME] ?? null
  );

  const initialTokens =
    cookies[AUTH_COOKIE_NAME] && refreshCookie[REFRESH_COOKIE_NAME]
      ? {
          idToken: cookies[AUTH_COOKIE_NAME].idToken,
          refreshToken: refreshCookie[REFRESH_COOKIE_NAME],
          expiresIn: cookies[AUTH_COOKIE_NAME].expiresIn,
        }
      : null;

  const [tokens, setTokens] = useState<CognitoToken | null>(
    initialTokens ?? null
  );

  const [impersonationSession, setImpersonationSessionData] =
    useState<ImpersonationSession>(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { mutate: refreshAuthTokens } = useRefreshCognitoAuthTokens();
  const { decodedToken } = useJwt<any>(tokens?.idToken ?? "");

  const handleLogin = useCallback(
    ({ expiresIn, accessToken, idToken, refreshToken }: CognitoToken) => {
      if (cookies[AUTH_COOKIE_NAME]) {
        removeCookie(AUTH_COOKIE_NAME, { path: "/" });
      }
      const expirationDate = new Date(Date.now() + expiresIn * 1000);
      const tokenData = {
        idToken,
        refreshToken,
        expiresIn,
      };
      setCookie(
        AUTH_COOKIE_NAME,
        { idToken, expiresIn },
        {
          path: "/",
          expires: expirationDate,
        }
      );
      setRefreshCookie(REFRESH_COOKIE_NAME, refreshToken, {
        path: "/",
        expires: expirationDate,
      });
      setTokens(tokenData);
      setAccessCookies(ACCESS_COOKIE_NAME, accessToken, {
        path: "/",
        expires: expirationDate,
      });
      setAccessToken(accessToken);
    },
    [setCookie, cookies, removeCookie, setAccessCookies, setRefreshCookie]
  );

  const handleLogout = useCallback(() => {
    if (tokens?.idToken) {
      const credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId,
        Logins: {
          [`cognito-idp.${region}.amazonaws.com/${cognito.userPoolId}`]:
            tokens.idToken,
        },
      });
      credentials.clearCachedId();
    }
    removeCookie(AUTH_COOKIE_NAME);
    removeCookie(ACCESS_COOKIE_NAME);
    removeCookie(REFRESH_COOKIE_NAME);
    setTokens(null);
    // Ref: https://phillips-connect.atlassian.net/browse/PRJIND-6604
    // storing theme even after logout
    const storedTheme = localStorage.getItem("theme") ?? themes.light;
    localStorage.clear();
    localStorage.setItem("theme", storedTheme);
  }, [removeCookie, tokens?.idToken]);

  useEffect(() => {
    if (tokens?.idToken) {
      const newCredentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId,
        Logins: {
          [`cognito-idp.${region}.amazonaws.com/${cognito.userPoolId}`]:
            tokens.idToken,
        },
      });
      (newCredentials as AWS.CognitoIdentityCredentials)
        .refreshPromise()
        .then(() => {
          AWS.config.credentials = newCredentials;
          if (isMounted.current) {
            setIsAuthorized(true);
          }
          if (env.REACT_APP_AWS_RUM_APPLICATION_ID) {
            try {
              const authenticatedConfig: AwsRumConfig = {
                sessionSampleRate: 1,
                endpoint: env.REACT_APP_AWS_RUM_ENDPOINT,
                telemetries: ["errors", "performance", "http"],
                allowCookies: true,
                enableXRay: true,
                cookieAttributes: { unique: true },
                enableRumClient: false,
              };

              const APPLICATION_ID: string =
                env.REACT_APP_AWS_RUM_APPLICATION_ID;
              const APPLICATION_VERSION: string = packageJson.version;
              const APPLICATION_REGION: string = env.REACT_APP_REGION;

              const rum = new AwsRum(
                APPLICATION_ID,
                APPLICATION_VERSION,
                APPLICATION_REGION,
                authenticatedConfig
              );
              rum.setAwsCredentials(AWS.config.credentials as AWS.Credentials);
              rum.enable();
            } catch (error) {
              console.log(error);
            }
          }
        });
    }
  }, [isMounted, tokens?.idToken]);

  // This functionality is to refresh auth tockens on-demand
  // Especially when the scenarios like, Whenevr a new organization is created,
  // User auth tokens will get refetched to get the latest tokens,So that,usercan accessthe latest organizations list
  const refreshAuthTokens$ = useCallback(
    (callbackFunction?: any): any => {
      if (!!tokens?.refreshToken && !!tokens?.expiresIn) {
        refreshAuthTokens(tokens?.refreshToken, {
          onSuccess: ({ data }) => {
            if (data && isMounted.current) {
              handleLogin({
                expiresIn: data.expires_in,
                accessToken: data.acess_token,
                idToken: data.id_token,
                refreshToken: tokens?.refreshToken,
              });
            }
            if (callbackFunction) {
              callbackFunction(null);
            }
          },
          onError: (error) => {
            if (callbackFunction) {
              callbackFunction(error);
            }
          },
        });
      }
    },
    [refreshAuthTokens, handleLogin, tokens, isMounted]
  );

  const setImpersonationSession = (
    impersonationSessionData: ImpersonationSession
  ) => {
    setImpersonationSessionData(impersonationSessionData);
  };

  useEffect(() => {
    if (tokens?.refreshToken && tokens?.expiresIn) {
      // refresh 120 secs before expire
      const subscription = interval((tokens.expiresIn - 120) * 1000).subscribe(
        () => refreshAuthTokens$()
      );

      return () => subscription.unsubscribe();
    }
  }, [
    handleLogin,
    isMounted,
    refreshAuthTokens,
    tokens,
    tokens?.expiresIn,
    tokens?.refreshToken,
    refreshAuthTokens$,
  ]);

  const userInfo = useMemo<UserInfo | null>(
    () =>
      decodedToken
        ? {
            atHash: decodedToken.at_hash,
            sub: decodedToken.sub,
            groups: decodedToken["cognito:groups"],
            emailVerified: decodedToken.email_verified,
            username: decodedToken["cognito:username"],
            givenName: decodedToken.given_name,
            familyName: decodedToken.family_name,
            email: decodedToken.email,
          }
        : null,
    [decodedToken]
  );

  const userRolePermissions = useMemo<any | null>(
    () => getRolePermissions(decodedToken),
    [decodedToken]
  );

  return (
    <AuthContext.Provider
      value={{
        tokens,
        accessToken,
        isAuthorized,
        userInfo,
        userRolePermissions,
        impersonationSession,
        setImpersonationSession,
        login: handleLogin,
        logout: handleLogout,
        refreshAuthTokens$: refreshAuthTokens$,
        decodedToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthContextProvider;
