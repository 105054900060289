import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PasswordResetRequiredException } from "@aws-sdk/client-cognito-identity-provider";
import { Box } from "@mui/material";
import { useAuthContext } from "../../context/AuthContext";
import { MfaOption } from "../../graphql/operations";
import { setUserMFAPreference, signIn } from "../../services/aws/auth";
import Logo from "../../shared/components/Svg/Logo";
import { navigateToRoute } from "../../utils/generic";
import { NavigationRoutes } from "../../utils/routes/routesUtils";
import { useCheckRouteSecurity } from "../../utils/routes/useCheckRouteSecurity";
import { ConfirmPasswordReason } from "./ConfirmForgotPassword";

const CustomLogin = () => {
  const { login } = useAuthContext();
  const navigate = useNavigate();
  const { defaultPath, isLoadingDefaultPath } = useCheckRouteSecurity();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const handleSignIn = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      const response = await signIn(username, password);
      sessionStorage.setItem("password", password);
      if (!response) {
        console.error("Sign-in failed.");
        return;
      }

      if (response.type === "AuthenticationResult") {
        const { result } = response;

        if (result.AccessToken) {
          sessionStorage.setItem("accessToken", result.AccessToken ?? "");
          sessionStorage.setItem("idToken", result.IdToken ?? "");
          sessionStorage.setItem("refreshToken", result.RefreshToken ?? "");
          sessionStorage.setItem(
            "expiresIn",
            (result.ExpiresIn ?? "").toString()
          );

          const tokens = {
            accessToken: result.AccessToken,
            idToken: result.IdToken ?? "",
            refreshToken: result.RefreshToken ?? "",
            expiresIn: result.ExpiresIn ?? 0,
          };

          login(tokens);
          setShouldRedirect(true);
        } else {
          console.error("AccessToken is undefined.");
        }
      } else if (
        response.type === "Challenge" &&
        response.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        return navigateToRoute(NavigationRoutes.MfaVerifyAuthAppCode);
      } else if (
        response.type === "Challenge" &&
        response.challengeName === "SMS_MFA"
      ) {
        sessionStorage.setItem(
          "codeDeliveryDestination",
          response.challengeParameters.CODE_DELIVERY_DESTINATION
        );
        return navigateToRoute(NavigationRoutes.MfaSmsVerification);
      } else if (
        response.type === "Challenge" &&
        response.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        return navigateToRoute(NavigationRoutes.NewUserPassword);
      } else if (
        response.type === "Challenge" &&
        response.challengeName === "MFA_SETUP"
      ) {
        await setUserMFAPreference(MfaOption.SoftwareTokenMfa, username);
        return navigateToRoute(NavigationRoutes.MfaAuthAppSetup);
      }
    } catch (error) {
      if (error instanceof PasswordResetRequiredException) {
        const usernameParam = `?username=${username}`;
        const reasonParam = `&reason=${ConfirmPasswordReason.Reset}`;
        return navigate(
          `${NavigationRoutes.ConfirmForgotPassword}${usernameParam}${reasonParam}`
        );
      } else {
        console.log(`Sign in failed: ${error}`);
      }
    }
  };

  const handleForgotPasswordClick = () => {
    navigate(NavigationRoutes.ForgotPassword);
  };

  useEffect(() => {
    if (shouldRedirect && !isLoadingDefaultPath) {
      navigate(defaultPath, { replace: true });
    }
  }, [defaultPath, isLoadingDefaultPath, shouldRedirect, navigate]);

  useEffect(() => {
    // Function to handle popstate events, which are triggered when the active history entry changes
    const handlePopState = (event: PopStateEvent) => {
      // Check if the state has a property that prevents forward navigation
      if (event.state && event.state.preventForward) {
        // Navigate to the default path and replace the current entry in the history stack
        navigate(defaultPath, { replace: true });
      }
    };

    // Push a new state into the history stack with a flag to prevent forward navigation
    window.history.pushState({ preventForward: true }, "");

    // Add an event listener for popstate events to call handlePopState
    window.addEventListener("popstate", handlePopState);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate, defaultPath]);

  return (
    <Box className="v-full h-full flex flex-col justify-center items-center bg-gray">
      <Logo />
      <Box className="my-4 text-base font-thin">
        Sign in with your credentials
      </Box>
      <Box
        className="mb-6 text-[10px] text-error font-medium underline hover:cursor-pointer"
        data-testid="forgot-password-link"
        onClick={handleForgotPasswordClick}
      >
        Forgot your password?
      </Box>
      <Box className="flex flex-col justify-center w-[200px] h-[150px]">
        <input
          className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
        />

        <input
          className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button
          className="mb-2 h-[40px] rounded bg-primary-blue text-white hover:bg-brand-light-blue"
          onClick={handleSignIn}
        >
          Sign In
        </button>
      </Box>
    </Box>
  );
};

export default memo(CustomLogin);
