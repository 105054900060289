import { useState } from "react";
import { Button } from "@mui/base";
import { Box } from "@mui/system";
import { useAuthContext } from "../../context/AuthContext";
import { MfaOption } from "../../graphql/operations";
import {
  respondWithNewPassword,
  setUserMFAPreference,
  signIn,
} from "../../services/aws/auth";
import { Logo } from "../../shared/components/Svg";
import { navigateToRoute } from "../../utils";
import { NavigationRoutes } from "../../utils/routes/routesUtils";

const NewUserPassword = () => {
  const { login } = useAuthContext();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [match, setMatch] = useState(true);
  const handleChangePassword = (password: string) => {
    setNewPassword(password);
  };
  const handleChangeConfirmPassword = (password: string) => {
    setConfirmPassword(password);
  };

  const handleConfirmChangePassword = async (password: string) => {
    if (confirmPassword !== password) {
      setMatch(false);
    } else {
      const username = sessionStorage.getItem("username") ?? "";
      const responseAfterSettingNewPassword = await respondWithNewPassword(
        password
      );
      console.log(
        "responseAfterSettingNewPassword----",
        JSON.stringify(responseAfterSettingNewPassword)
      );
      if (responseAfterSettingNewPassword?.ChallengeName === "MFA_SETUP") {
        if (responseAfterSettingNewPassword.Session) {
          sessionStorage.setItem(
            "session",
            responseAfterSettingNewPassword.Session
          );

          await setUserMFAPreference(MfaOption.SoftwareTokenMfa, username);
          return navigateToRoute(NavigationRoutes.MfaAuthAppSetup, false);
        }
      }

      if (responseAfterSettingNewPassword?.ChallengeName === "SMS_MFA") {
        if (responseAfterSettingNewPassword.Session) {
          sessionStorage.setItem(
            "codeDeliveryDestination",
            responseAfterSettingNewPassword?.ChallengeParameters
              ?.CODE_DELIVERY_DESTINATION ?? ""
          );
          return navigateToRoute(NavigationRoutes.MfaSmsVerification, false);
        }
      }

      const response = await signIn(username, password);
      if (!response) {
        console.error("Sign-in failed.");
        alert("Change password failed.");
        return;
      }

      // eslint-disable-next-line no-prototype-builtins
      if (response.type === "AuthenticationResult") {
        const { result } = response;

        if (result.AccessToken) {
          sessionStorage.setItem("accessToken", result.AccessToken ?? "");

          const tokens = {
            accessToken: result.AccessToken,
            idToken: result.IdToken ?? "",
            refreshToken: result.RefreshToken ?? "",
            expiresIn: result.ExpiresIn ?? 0,
          };

          login(tokens);
          return navigateToRoute(NavigationRoutes.Home);
        } else {
          console.error("AccessToken is undefined.");
        }
      }
    }
  };
  return (
    <Box className="v-full h-full flex flex-col justify-center items-center bg-gray">
      <Logo />
      <Box className="my-4 text-sm font-thin">Enter new password</Box>
      <Box>New Password:</Box>
      <input
        data-testid="new-password"
        className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded"
        id="password"
        type="password"
        value={newPassword}
        onChange={(e) => handleChangePassword(e.target.value)}
      ></input>
      <Box>Confirm Password:</Box>
      <input
        data-testid="confirm-password"
        className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded"
        id="password"
        type="password"
        value={confirmPassword}
        onChange={(e) => handleChangeConfirmPassword(e.target.value)}
      ></input>
      {!match && <Box className="text-red-700">Passwords should match !</Box>}
      <Button
        className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded hover:bg-primary-blue"
        onClick={(e) => {
          e.preventDefault();
          handleConfirmChangePassword(newPassword);
        }}
      >
        Change password
      </Button>
    </Box>
  );
};
export default NewUserPassword;
