import { FC } from "react";
import { useLocation } from "react-router-dom";
import slug from "slug";
import { useAppContext } from "../../../../context/AppContext";
import { ReportType } from "../../../../graphql/operations";
import { usePreferredTimezone } from "../../../../shared/hooks/usePreferredTimezone";
import { useSpinner } from "../../../../shared/hooks/useSpinner";
import { useUserData } from "../../../../shared/hooks/useUserData";
import { AssetReport } from "../../AssetReport";
import {
  defaultColumnVisibilityModel as defaultAccountColumnVisibilityModel,
  getColumns as getAccountsColumns,
  SEARCH_KEYS as ACCOUNTS_SEARCH_KEYS,
} from "../../AssetReport/components/AccountsTable/columns";
import {
  defaultColumnVisibilityModel as defaultAlertHistoryColumnVisibilityModel,
  getColumns as getAlertHistoryColumns,
} from "../../AssetReport/components/AlertHistoryTable/columns";
import {
  defaultColumnVisibilityModel as defaultInstallColumnVisibilityModel,
  getColumns as getInstallColumns,
  SEARCH_KEYS as INSTALL_SEARCH_KEYS,
} from "../../AssetReport/components/AssetInstallTable/columns";
import {
  defaultColumnVisibilityModel as defaultAssetColumnVisibilityModel,
  getColumns as getCommonAssetColumns,
  getInventoryColumns,
  SEARCH_KEYS as COMMON_ASSET_SEARCH_KEYS,
} from "../../AssetReport/components/AssetInventoryTable/columns";
import {
  defaultColumnVisibilityModel as defaultTransferColumnVisibilityModel,
  getColumns as getTransferColumns,
  SEARCH_KEYS as TRANSFER_SEARCH_KEYS,
} from "../../AssetReport/components/AssetTransferTable/columns";
import {
  defaultColumnVisibilityModel as yardColumnVisibilityModel,
  getColumns as yardColumns,
  SEARCH_KEYS as YARD_SEARCH_KEYS,
} from "../../AssetReport/components/AssetYardTable/columns";
import {
  defaultColumnVisibilityModel as defaultDwellHierarchyColumnVisibilityModel,
  getColumns as getDwellHierarchyColumns,
} from "../../AssetReport/components/DwellHierarchyTable/columns";
import {
  defaultColumnVisibilityModel as defaultMediaActivitiesColumnVisibilityModel,
  getColumns as getMediaActivitiesColumns,
  SEARCH_KEYS as MEDIA_ACTIVITIES_SEARCH_KEYS,
} from "../../AssetReport/components/MediaActivitiesTable/columns";
import {
  defaultColumnVisibilityModel as defaultMileageColumnVisibilityModel,
  getColumns as getMileageColumns,
  SEARCH_KEYS as MILEAGE_SEARCH_KEYS,
} from "../../AssetReport/components/MileageTable/columns";
import {
  defaultColumnVisibilityModel as defaultSensorsColumnVisibilityModel,
  getColumns as getSensorsColumns,
  SEARCH_KEYS as SENSORS_SEARCH_KEYS,
} from "../../AssetReport/components/SensorsTable/columns";
import { ReportTypeTitle } from "../../constants";
import { ReportParameters } from "../../interfaces";
import {
  buildAssetInstallReportTemplate,
  buildAssetInventoryReportTemplate,
  buildAssetTransferReportTemplate,
  buildAlertHistoryReportTemplate,
  buildMileageReportTemplate,
  buildYardReportTemplate,
  buildOutsideGeofenceReportTemplate,
  buildAccountsReportTemplate,
  buildDwellHierarchyReportTemplate,
  buildMediaActivitiesReportTemplate,
  buildSensorsReportTemplate,
} from "./utils";

export type ReportTemplateProps = {
  templateName: string;
};

const AssetTransferReportSlug = slug(ReportTypeTitle[ReportType.AssetTransfer]);
const AssetInstallReportSlug = slug(ReportTypeTitle[ReportType.AssetInstall]);
const AssetInventoryReportSlug = slug(
  ReportTypeTitle[ReportType.AssetInventory]
);
const YardReportSlug = slug(ReportTypeTitle[ReportType.AssetYard]);
const OutsideGeofenceReportSlug = slug(
  ReportTypeTitle[ReportType.OutsideGeofence]
);
const AlertHistoryReportSlug = slug(ReportTypeTitle[ReportType.AlertHistory]);
const MileageReportSlug = slug(ReportTypeTitle[ReportType.Mileage]);
const AccountsReportSlug = slug(ReportTypeTitle[ReportType.Accounts]);
const DwellHierarchySlug = slug(ReportTypeTitle[ReportType.DwellHierarchy]);
const MediaActivitiesSlug = slug(ReportTypeTitle[ReportType.MediaActivities]);
const SensorsReportSlug = slug(ReportTypeTitle[ReportType.Sensors]);

export const ReportTemplate: FC<ReportTemplateProps> = ({
  templateName,
}: ReportTemplateProps) => {
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();
  const userData = useUserData();
  const userTimezone = usePreferredTimezone();
  const isLoading = !selectedOrganization.value;
  const { reportParams } = useLocation().state as {
    reportParams: ReportParameters;
  };

  useSpinner(isLoading);

  return (
    <>
      {!isLoading && (
        <>
          {templateName === AccountsReportSlug && (
            <AssetReport
              report={buildAccountsReportTemplate(
                selectedOrganization.value,
                selectedOrganization.label,
                userData?._id!,
                reportParams
              )}
              reportType={ReportType.Accounts}
              getColumns={getAccountsColumns}
              defaultColumnVisibilityModel={defaultAccountColumnVisibilityModel}
              searchKeys={ACCOUNTS_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "updatedAt",
                  sort: "desc",
                },
              ]}
            />
          )}
          {templateName === AssetTransferReportSlug && (
            <AssetReport
              report={buildAssetTransferReportTemplate(
                selectedOrganization.value,
                selectedOrganization.label,
                userData?._id!,
                reportParams
              )}
              reportType={ReportType.AssetTransfer}
              getColumns={getTransferColumns}
              defaultColumnVisibilityModel={
                defaultTransferColumnVisibilityModel
              }
              searchKeys={TRANSFER_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "date",
                  sort: "desc",
                },
              ]}
            />
          )}
          {templateName === AssetInstallReportSlug && (
            <AssetReport
              report={buildAssetInstallReportTemplate(
                selectedOrganization.value,
                selectedOrganization.label,
                userData?._id!,
                reportParams
              )}
              reportType={ReportType.AssetInstall}
              getColumns={getInstallColumns}
              defaultColumnVisibilityModel={defaultInstallColumnVisibilityModel}
              searchKeys={INSTALL_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "installDate",
                  sort: "desc",
                },
              ]}
            />
          )}
          {templateName === AssetInventoryReportSlug && (
            <AssetReport
              report={buildAssetInventoryReportTemplate(
                selectedOrganization.value,
                selectedOrganization.label,
                userData?._id!,
                reportParams
              )}
              reportType={ReportType.AssetInventory}
              getColumns={getInventoryColumns}
              defaultColumnVisibilityModel={defaultAssetColumnVisibilityModel}
              searchKeys={COMMON_ASSET_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "device.lst_evnt_t",
                  sort: "desc",
                },
              ]}
            />
          )}
          {templateName === AlertHistoryReportSlug && (
            <AssetReport
              report={buildAlertHistoryReportTemplate(
                selectedOrganization.value,
                selectedOrganization.label,
                userData?._id!,
                reportParams
              )}
              reportType={ReportType.AlertHistory}
              getColumns={getAlertHistoryColumns}
              defaultColumnVisibilityModel={
                defaultAlertHistoryColumnVisibilityModel
              }
              searchKeys={[]}
              initialSortModel={[
                {
                  field: "startedAt",
                  sort: "desc",
                },
              ]}
            />
          )}
          {templateName === MileageReportSlug && (
            <AssetReport
              report={buildMileageReportTemplate(
                userTimezone,
                selectedOrganization.value,
                selectedOrganization.label,
                userData?._id!,
                reportParams
              )}
              reportType={ReportType.Mileage}
              getColumns={getMileageColumns}
              defaultColumnVisibilityModel={defaultMileageColumnVisibilityModel}
              searchKeys={MILEAGE_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "dateAndTime",
                  sort: "desc",
                },
              ]}
            />
          )}
          {templateName === YardReportSlug && (
            <AssetReport
              report={buildYardReportTemplate(
                selectedOrganization.value,
                selectedOrganization.label,
                userData?._id!,
                reportParams
              )}
              reportType={ReportType.AssetYard}
              getColumns={yardColumns}
              defaultColumnVisibilityModel={yardColumnVisibilityModel}
              searchKeys={YARD_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "device.lst_evnt_t",
                  sort: "desc",
                },
              ]}
            />
          )}
          {templateName === OutsideGeofenceReportSlug && (
            <AssetReport
              report={buildOutsideGeofenceReportTemplate(
                selectedOrganization.value,
                selectedOrganization.label,
                userData?._id!,
                reportParams
              )}
              reportType={ReportType.OutsideGeofence}
              getColumns={getCommonAssetColumns}
              defaultColumnVisibilityModel={defaultAssetColumnVisibilityModel}
              searchKeys={COMMON_ASSET_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "device.lst_evnt_t",
                  sort: "desc",
                },
              ]}
            />
          )}
          {templateName === DwellHierarchySlug && (
            <AssetReport
              report={buildDwellHierarchyReportTemplate(
                selectedOrganization.value,
                selectedOrganization.label,
                userData?._id!,
                reportParams
              )}
              reportType={ReportType.DwellHierarchy}
              getColumns={getDwellHierarchyColumns}
              defaultColumnVisibilityModel={
                defaultDwellHierarchyColumnVisibilityModel
              }
              searchKeys={[]} // remove when all reports are moved to table data
              initialSortModel={[
                {
                  field: "geofenceName",
                  sort: "asc",
                },
              ]}
            />
          )}
          {templateName === MediaActivitiesSlug && (
            <AssetReport
              report={buildMediaActivitiesReportTemplate(
                userTimezone,
                selectedOrganization.value,
                selectedOrganization.label,
                userData?._id!,
                reportParams
              )}
              reportType={ReportType.MediaActivities}
              getColumns={getMediaActivitiesColumns}
              defaultColumnVisibilityModel={
                defaultMediaActivitiesColumnVisibilityModel
              }
              searchKeys={MEDIA_ACTIVITIES_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "dateAndTime",
                  sort: "desc",
                },
              ]}
            />
          )}
          {templateName === SensorsReportSlug && (
            <AssetReport
              report={buildSensorsReportTemplate(
                userTimezone,
                selectedOrganization.value,
                selectedOrganization.label,
                userData?._id!,
                reportParams
              )}
              reportType={ReportType.Sensors}
              getColumns={getSensorsColumns}
              defaultColumnVisibilityModel={defaultSensorsColumnVisibilityModel}
              searchKeys={SENSORS_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "assetId",
                  sort: "asc",
                },
              ]}
            />
          )}
        </>
      )}
    </>
  );
};
