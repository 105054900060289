import { memo, useCallback, useEffect, useState } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { useAppContext } from "../../../context/AppContext";
import { AuthorizedComponent } from "../../../shared/components/AuthorizedComponent/AuthorizedComponent";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { useAssetsDataContext } from "../shared/AssetsDataContext";
import {
  AssetPagesUrlTypes,
  getNavigationRouteBasedOnSelectedView,
  isNilOrEmpty,
} from "../utils";
import { AddAssetDrawer } from "./components";
import { AssetDashboard } from "./components/AssetDashboard";
import { AssetsTable } from "./components/AssetsTable";
import { useAssetManagementTheme } from "./hooks/useAssetManagementTheme";

const AssetsTableView = memo(() => {
  const assetManagementTheme = useAssetManagementTheme();
  const {
    gridApiRef,
    setSelectedAssetId,
    setShouldShowFiltersStripAndViewToggle,
  } = useAssetsDataContext();
  const assetId = Object.values(useParams())[0];
  const [shouldShowTable, setShouldShowTable] = useState(!assetId);
  const {
    state: { appConfig },
  } = useAppContext();
  const { selectedAssetsView } = appConfig;
  const navigate = useNavigate();
  const location = useLocation();

  /* 
    TODO: PRJIND-9343 Ensure the Filters Strip is hidden if an asset is selected and we're showing the Asset Dashboard
    (should not be an issue once the new Asset Dashboard page is in place)
  */
  useEffect(() => {
    setShouldShowFiltersStripAndViewToggle(isNilOrEmpty(assetId));
  }, [assetId, setShouldShowFiltersStripAndViewToggle]);

  const handleNavigateBack = useCallback(() => {
    // TODO: PRJIND-9343 Deselect asset and navigate to previously selected view (should be moved into the new Asset Dashboard page)
    setSelectedAssetId(null);
    setShouldShowTable(selectedAssetsView === AssetPagesUrlTypes.Table);
    const queryParams = new URLSearchParams(location.search);
    const navigationRoute =
      getNavigationRouteBasedOnSelectedView(selectedAssetsView);
    navigate(`${navigationRoute}?${queryParams}`);
  }, [location.search, selectedAssetsView, navigate, setSelectedAssetId]);

  useEffect(() => {
    // Check if the current path is assets/table/:id and set the selected asset id
    if (location.pathname.includes("/assets/table/")) {
      const assetId = location.pathname.split("/").at(-1);
      if (assetId) {
        setSelectedAssetId(assetId);
      }
    }
    setShouldShowTable(location.pathname === NavigationRoutes.AssetTable);
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return gridApiRef ? (
    <ThemeProvider theme={assetManagementTheme}>
      {shouldShowTable ? (
        <AssetsTable />
      ) : (
        <Routes>
          <Route
            path="create"
            element={
              <AuthorizedComponent actionPath="assetManagement.addAsset">
                <AddAssetDrawer open={true} setOpen={handleNavigateBack} />
              </AuthorizedComponent>
            }
          />
          <Route
            path=":id"
            element={
              <AssetDashboard
                onClose={handleNavigateBack}
                isAssetRefetching={false}
                backButtonText="Back"
              />
            }
          />
        </Routes>
      )}
    </ThemeProvider>
  ) : null;
});

export default AssetsTableView;
