import React from "react";
import { QueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { Action } from "../../../../../context/commonTypes";
import { Automation } from "../../../../../graphql/operations";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../utils";
import { NavigationRoutes } from "../../../../../utils/routes/routesUtils";

type onSuccessCreateCallbackParams = {
  dispatch: React.Dispatch<Action>;
  created: Automation;
  queryClient: QueryClient;
  navigate: (path: string) => void;
  setAutomation: (automation: Automation) => void;
  setLoading: (loading: boolean) => void;
  setShouldCreateAutomation: (shouldCreateAutomation: boolean) => void;
};

export const onSuccessCreateCallback = async ({
  dispatch,
  created,
  navigate,
  queryClient,
  setAutomation,
  setLoading,
  setShouldCreateAutomation,
}: onSuccessCreateCallbackParams) => {
  await queryClient.invalidateQueries(["getAutomations"], {
    refetchType: "all",
  });

  setShouldCreateAutomation(false);
  navigate(`${NavigationRoutes.AdminPanelAutomations}/${created._id}`);
  setAutomation(created);
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Success!",
      text: "Automation was created successfully!",
      severity: "success",
    },
  });
  setLoading(false);
};

type onErrorCallbackParams = {
  error: unknown;
  dispatch: any;
  setLoading: (loading: boolean) => void;
};

export const createAutomationOnErrorCallback = ({
  error,
  dispatch,
  setLoading,
}: onErrorCallbackParams) => {
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Error creating alert",
      text: mapServerErrorCodeToHumanReadableMessage(
        error instanceof Error ? error.message : "Something Went Wrong."
      ),
      severity: "error",
    },
  });
  setLoading(false);
};
