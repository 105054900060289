import {
  Automation,
  CreateAutomationInput,
  CreateAutomationMutation,
  Exact,
  useCreateAutomationMutation,
} from "../../../../../graphql/operations";

type AutomationsApi = {
  createAutomationOnSuccess: (
    data: CreateAutomationMutation,
    variables: Exact<{ input: CreateAutomationInput }>,
    context: unknown
  ) => unknown;
  createAutomationOnError: (error: unknown) => unknown;
};

export const useAutomationsApi = ({
  createAutomationOnSuccess,
  createAutomationOnError,
}: AutomationsApi) => {
  const { mutate: createAutomation } = useCreateAutomationMutation({
    onSuccess: createAutomationOnSuccess,
    onError: createAutomationOnError,
  });

  return {
    createAutomation: (automation: Partial<Automation>) => {
      return createAutomation({
        input: {
          actions: automation?.actions ?? [],
          mode: automation.mode!,
          name: automation.name!,
          orgId: automation.orgId!,
          parameters: automation.parameters,
          trigger: automation.trigger,
        },
      });
    },
  };
};
