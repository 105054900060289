import { useMemo } from "react";
import { UseQueryOptions } from "@tanstack/react-query";
import {
  ConfigurationSetType,
  GetConfigurationSetsQuery,
  RegionGroupTypeValue,
  useGetConfigurationSetsQuery,
} from "../../graphql/operations";
import { useCurrentOrg } from "./useCurrentOrg";

export type RegionListElement = {
  _id: string;
  name: string;
  value: string[];
};

export type RegionsListHookResult = {
  regions: RegionListElement[];
  isLoading: boolean;
};

export const useRegionsList = (
  orgId: string | undefined = undefined,
  options: UseQueryOptions<
    GetConfigurationSetsQuery,
    unknown,
    GetConfigurationSetsQuery
  > = {}
): RegionsListHookResult => {
  const currentOrganization = useCurrentOrg();
  const { isLoading, data } = useGetConfigurationSetsQuery(
    {
      input: {
        orgId: orgId ?? currentOrganization?._id ?? "",
        type: ConfigurationSetType.RegionGroup,
      },
    },
    {
      enabled: Boolean(currentOrganization),
      refetchOnWindowFocus: false,
      ...options,
    }
  );
  const regionsList = useMemo(() => {
    const regions = data?.getConfigurationSets ?? [];

    return (
      regions?.map((region) => {
        const parsedValue = JSON.parse(region.value) as RegionGroupTypeValue;

        return {
          _id: region?._id,
          name: region?.name,
          value: parsedValue?.territories as string[],
        };
      }) ?? []
    );
  }, [data?.getConfigurationSets]);

  return { regions: regionsList, isLoading };
};
