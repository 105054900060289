import { FC, memo, MouseEvent, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Divider, Menu, MenuItem } from "@mui/material";
import {
  ASSETS_LIST_HEADER_HEIGHT,
  SORT_OPTIONS,
  SORT_OPTIONS_GEOFENCES,
} from "../../../../../../constants/map";
import {
  AssetSort,
  AssetSortOrder,
} from "../../../../../../graphql/operations";
import { formatNumber } from "../../../../../../utils/formatters";
import {
  PageTypes,
  useAssetsDataContext,
} from "../../../../shared/AssetsDataContext";
import { AssetPagesUrlTypes } from "../../../../utils";

export type AssetsListHeaderProps = {
  sortChange: (params: any) => void;
  totalAssets: number;
  currentSort: AssetSort | null;
};

const AssetsListHeader: FC<AssetsListHeaderProps> = memo(
  ({ sortChange, totalAssets, currentSort }) => {
    const location = useLocation();
    const pageType: PageTypes = location.pathname.includes(
      AssetPagesUrlTypes.Map
    )
      ? PageTypes.AssetMap
      : PageTypes.Geofences;
    const { geofences } = useAssetsDataContext();
    const [sortMenu, setSortMenu] = useState<Element | null>(null);
    const [openSortMenu, setOpenSortMenu] = useState(false);

    const onOpenSortMenu = useCallback((event: MouseEvent) => {
      setOpenSortMenu(true);
      setSortMenu(event.currentTarget);
    }, []);

    const onCloseSortMenu = useCallback(() => {
      setOpenSortMenu(false);
      setSortMenu(null);
    }, []);

    const sortOptions = {
      [PageTypes.Geofences]: SORT_OPTIONS_GEOFENCES,
      [PageTypes.AssetMap]: SORT_OPTIONS,
    };

    const currentSortOptions = sortOptions[pageType];

    return (
      <Box
        className="w-full bg-background"
        height={ASSETS_LIST_HEADER_HEIGHT}
        data-testid="asset-list-header"
      >
        <Box className="flex justify-between px-4 py-2">
          <Box className="w-full flex-col">
            <div className="text-base font-bold text-assets-list-header">
              {pageType === PageTypes.AssetMap && (
                <>
                  <span data-testid="assets-count" id="assets-count">
                    {formatNumber(totalAssets)}
                  </span>{" "}
                  Assets
                </>
              )}
              {pageType === PageTypes.Geofences && (
                <>
                  <span data-testid="geofences-count" id="geofences-count">
                    {geofences?.length > 0 && (
                      <>{formatNumber(geofences.length)}</>
                    )}
                  </span>{" "}
                  Geofences
                </>
              )}
            </div>
            <div
              className="text-xs font-normal text-typography"
              data-testid="menu-sort"
            >
              <span
                className="flex justify-between cursor-pointer underline underline-offset-1 text-assets-list-header-sort-menu"
                onClick={onOpenSortMenu}
                data-testid="menu-sort-option"
              >
                {currentSortOptions.find(
                  (a) =>
                    a.key === currentSort?.field &&
                    a.order === currentSort?.direction
                )?.label ?? SORT_OPTIONS[0].label}
                <KeyboardArrowDownIcon
                  data-testid="menu-sort-icon"
                  sx={{
                    fill: "var(--assets-list-header)",
                    height: "0.9em",
                    width: "0.9em",
                  }}
                />
              </span>
              <Menu
                data-testid="menu-sort"
                id="menu-sort"
                anchorEl={sortMenu}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                keepMounted
                open={openSortMenu}
                onClose={onCloseSortMenu}
              >
                {currentSortOptions.map(({ label, order, key, id }) => (
                  <MenuItem
                    key={label}
                    onClick={() => {
                      sortChange({
                        direction: order as AssetSortOrder,
                        field: key,
                      });
                      onCloseSortMenu();
                    }}
                    data-testid={id}
                  >
                    {label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Box>
        </Box>
        <Divider />
      </Box>
    );
  }
);

export default AssetsListHeader;
