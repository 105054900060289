import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  InitiateAuthCommandInput,
  AuthenticationResultType,
  ChallengeNameType,
  RespondToAuthChallengeCommand,
  RespondToAuthChallengeCommandInput,
  VerifySoftwareTokenCommand,
  VerifySoftwareTokenCommandInput,
  AssociateSoftwareTokenCommand,
  AssociateSoftwareTokenCommandInput,
  RespondToAuthChallengeCommandOutput,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { env } from "../../env";
import { MfaOption } from "../../graphql/operations";

export const cognitoClient = new CognitoIdentityProviderClient({
  region: env.REACT_APP_REGION,
});

export const setUserMFAPreference = async (
  mfaType: MfaOption,
  username: string
) => {
  const session = sessionStorage.getItem("session") ?? "";
  if (mfaType === MfaOption.SmsMfa) {
    const command = new RespondToAuthChallengeCommand({
      ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
      Session: session,
      ChallengeName: "MFA_SETUP",
      ChallengeResponses: {
        USERNAME: username,
        SMS_MFA: "true",
      },
    });

    const response = await cognitoClient.send(command);
  }

  if (mfaType === MfaOption.SoftwareTokenMfa) {
    const associateSoftwareTokenInput: AssociateSoftwareTokenCommandInput = {
      Session: session, // Use the session returned from the initial Auth response
    };

    const mfaCommand = new AssociateSoftwareTokenCommand(
      associateSoftwareTokenInput
    );
    const mfaResponse = await cognitoClient.send(mfaCommand);

    sessionStorage.setItem("session", mfaResponse.Session ?? "");
    sessionStorage.setItem(
      "SecretCodeForAuthApp",
      mfaResponse.SecretCode ?? ""
    );
  }
};

export const forgotPassword = async (username: string) => {
  const params = {
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    Username: username,
  };

  try {
    const command = new ForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error("Error initiating forgot password: ", error);
    throw error;
  }
};

export const confirmForgotPassword = async (
  username: string,
  code: string,
  newPassword: string
) => {
  const params = {
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    Username: username,
    ConfirmationCode: code,
    Password: newPassword,
  };

  try {
    const command = new ConfirmForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error("Error confirming forgot password: ", error);
    throw error;
  }
};

// TODO: refactor the response of this method to be InitiateAuthCommandOutput
export const signIn = async (
  username: string,
  password: string
): Promise<
  | { type: "AuthenticationResult"; result: AuthenticationResultType }
  | {
      type: "Challenge";
      challengeName: ChallengeNameType;
      challengeParameters?: any;
    }
  | void
> => {
  const params: InitiateAuthCommandInput = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command);

    const {
      AuthenticationResult,
      ChallengeName,
      Session,
      ChallengeParameters,
    } = response;

    if (!ChallengeName) {
      if (AuthenticationResult) {
        sessionStorage.setItem("idToken", AuthenticationResult.IdToken ?? "");
        sessionStorage.setItem(
          "accessToken",
          AuthenticationResult.AccessToken ?? ""
        );
        sessionStorage.setItem(
          "refreshToken",
          AuthenticationResult.RefreshToken ?? ""
        );
        return {
          type: "AuthenticationResult",
          result: AuthenticationResult,
        };
      }
    } else {
      sessionStorage.setItem("session", Session ?? "");
      sessionStorage.setItem("username", username ?? "");
      return {
        type: "Challenge",
        challengeName: ChallengeName,
        challengeParameters: ChallengeParameters,
      };
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const mfaSignIn = async (
  Session: any,
  username: string,
  code: string
): Promise<AuthenticationResultType | void> => {
  const challengeResponse = {
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    ChallengeName: MfaOption.SoftwareTokenMfa,
    Session: Session,
    ChallengeResponses: {
      USERNAME: username,
      SOFTWARE_TOKEN_MFA_CODE: code,
    },
  } as RespondToAuthChallengeCommandInput;
  try {
    const mfaCommand = new RespondToAuthChallengeCommand(challengeResponse);
    const mfaResponse = await cognitoClient.send(mfaCommand);

    if (mfaResponse.AuthenticationResult) {
      sessionStorage.setItem(
        "idToken",
        mfaResponse.AuthenticationResult.IdToken ?? ""
      );
      sessionStorage.setItem(
        "accessToken",
        mfaResponse.AuthenticationResult.AccessToken ?? ""
      );
      sessionStorage.setItem(
        "refreshToken",
        mfaResponse.AuthenticationResult.RefreshToken ?? ""
      );
      return mfaResponse.AuthenticationResult;
    } else {
      throw new Error("MFA failed");
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};
export const verifySMSCode = async (smsCode: string): Promise<any> => {
  const session = sessionStorage.getItem("session") ?? "";
  const username = sessionStorage.getItem("username") ?? "";

  const input: RespondToAuthChallengeCommandInput = {
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    Session: session,
    ChallengeName: "SMS_MFA",
    ChallengeResponses: {
      USERNAME: username,
      SMS_MFA_CODE: smsCode,
    },
  };

  const command = new RespondToAuthChallengeCommand(input);
  const response = await cognitoClient.send(command);

  if (response.AuthenticationResult) {
    sessionStorage.setItem(
      "idToken",
      response.AuthenticationResult.IdToken ?? ""
    );
    sessionStorage.setItem(
      "accessToken",
      response.AuthenticationResult.AccessToken ?? ""
    );
    sessionStorage.setItem(
      "refreshToken",
      response.AuthenticationResult.RefreshToken ?? ""
    );
    sessionStorage.setItem(
      "expiresIn",
      (response.AuthenticationResult.ExpiresIn ?? 0).toString()
    );
    return response.AuthenticationResult;
  } else {
    throw new Error("SMS MFA failed");
  }
};
export const verifySecretCodeForAuthApp = async (
  secretCodeForAuthApp: string
): Promise<any> => {
  const session = sessionStorage.getItem("session") ?? "";
  const username = sessionStorage.getItem("username") ?? "";
  const password = sessionStorage.getItem("password") ?? "";
  const inputSecretCodeVerify: VerifySoftwareTokenCommandInput = {
    Session: session, // Use the session returned from the initial Auth response
    UserCode: secretCodeForAuthApp, // Replace with the actual code
    FriendlyDeviceName: "My Device",
  };

  const secretCodeVerifyCommand = new VerifySoftwareTokenCommand(
    inputSecretCodeVerify
  );

  const secretCodeVerifyCommandResponse = await cognitoClient.send(
    secretCodeVerifyCommand
  );

  if (secretCodeVerifyCommandResponse.Status === "SUCCESS") {
    return await signIn(username, password);
  }
};

export const respondWithNewPassword = async (
  password: string
): Promise<RespondToAuthChallengeCommandOutput> => {
  const session = sessionStorage.getItem("session") ?? "";

  const username = sessionStorage.getItem("username") ?? "";
  const inputSendNewPassword: RespondToAuthChallengeCommandInput = {
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    Session: session, // Use the session returned from the initial Auth response
    ChallengeName: "NEW_PASSWORD_REQUIRED",
    ChallengeResponses: {
      USERNAME: username,
      NEW_PASSWORD: password,
    },
  };

  const sendNewPasswordCommand = new RespondToAuthChallengeCommand(
    inputSendNewPassword
  );
  const newPasswordResponse = await cognitoClient.send(sendNewPasswordCommand);

  sessionStorage.setItem("password", password);
  sessionStorage.setItem("session", newPasswordResponse.Session ?? "");

  return newPasswordResponse;
};

export const resendSMSCode = async (
  username: string,
  password: string
): Promise<any> => {
  try {
    return await signIn(username, password);
  } catch (error) {
    console.error("Error reseting sms code: ", error);
    throw error;
  }
};
