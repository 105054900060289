import { FC, MutableRefObject } from "react";
import { Circle } from "@mui/icons-material";
import { Box, Typography, Chip } from "@mui/material";
import { ReactComponent as CargoSensorDark } from "../../../../../../assets/svgs/cargoSensorDark.svg";
import { ReactComponent as CargoSensorLight } from "../../../../../../assets/svgs/cargoSensorLight.svg";
import { useAppContext } from "../../../../../../context/AppContext";
import SensorHeader from "../../../../../../shared/components/SensorHeader/SensorHeader";
import { useContainerDimensions } from "../../../../../../shared/hooks/useContainerDimensions";
import {
  BAR_HEIGHT,
  CargoData,
  calculateCargoStatusInLocationsLast30Days,
} from "./utils";

export interface CargoStatusInLocationsProps {
  data: Array<CargoData>;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
}

const CargoStatusInLocations: FC<CargoStatusInLocationsProps> = ({
  data,
  parentRef,
}) => {
  const {
    emptyCargoDaysInsideGeofence,
    emptyCargoDaysOutsideGeofence,
    loadedCargoDaysInsideGeofence,
    loadedCargoDaysOutsideGeofence,
  } = calculateCargoStatusInLocationsLast30Days(data);

  const legendKeys: string[] = ["Inside Geofence", "Outside Geofence"];
  const legendLabels = ["Inside Geofence", "Outside Geofence"];
  const legendColors = ["var(--primary-blue)", "var(--indigo)"];

  const { width } = useContainerDimensions(parentRef);
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "5rem",
    height: "5rem",
    display: "block",
  };
  // layout grid parent dimensions
  const gridItemPaddings = 32;
  const gridItemGapDistance = 16;

  // card layout dimensions
  const labelWidth = 55;
  const margins = 16;
  const paddings = 16 + gridItemPaddings + gridItemGapDistance;
  const barMaxWidth = width - (labelWidth + paddings + margins);

  // calculate percentage based on 30 days
  const loadedDaysOutsideGeo = (loadedCargoDaysOutsideGeofence / 30) * 100;
  const loadedDaysInsideGeo = (loadedCargoDaysInsideGeofence / 30) * 100;
  const emptyDaysOutsideGeo = (emptyCargoDaysOutsideGeofence / 30) * 100;
  const emptyDaysInsideGeo = (emptyCargoDaysInsideGeofence / 30) * 100;

  // calculate bar width based on the percentage
  const loadedOutsideGeofence = (loadedDaysOutsideGeo / 100) * barMaxWidth;
  const emptyOutsideGeofence = (emptyDaysOutsideGeo / 100) * barMaxWidth;
  const loadedInsideGeofence = (loadedDaysInsideGeo / 100) * barMaxWidth;
  const emptyInsideGeofence = (emptyDaysInsideGeo / 100) * barMaxWidth;

  return (
    <Box
      className="text-sm w-full p-4"
      data-testid="last-30-days-loaded-unloaded-in-locations"
    >
      <SensorHeader title="Cargo Sensor">
        {isLightTheme ? (
          <CargoSensorDark style={svgIconSettings} />
        ) : (
          <CargoSensorLight style={svgIconSettings} />
        )}
      </SensorHeader>

      <Typography
        variant="caption"
        className="!text-lg !font-semibold inline-block pb-4"
        data-testid="battery-widget-label"
      >
        Cargo Status in Locations Last 30 Days
      </Typography>

      <Box className="!mb-2 justify w-full" justifyContent="flex-end">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text"
        >
          Empty
        </Typography>
        {emptyCargoDaysOutsideGeofence > 0 && (
          <Box
            data-testid="empty-days-outside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--indigo)",
              color: "var(--white)",
              width: `${emptyOutsideGeofence}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {emptyCargoDaysOutsideGeofence}
          </Box>
        )}
        {emptyCargoDaysInsideGeofence > 0 && (
          <Box
            data-testid="empty-days-inside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--primary-blue)",
              color: "var(--white)",
              width: `${emptyInsideGeofence}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {emptyCargoDaysInsideGeofence}
          </Box>
        )}
      </Box>
      <Box className="!mb-3 justify w-full" justifyContent="flex-end">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text"
        >
          Loaded
        </Typography>
        {loadedCargoDaysOutsideGeofence > 0 && (
          <Box
            data-testid="loaded-days-outside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--indigo)",
              color: "var(--white)",
              width: `${loadedOutsideGeofence}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {loadedCargoDaysOutsideGeofence}
          </Box>
        )}
        {loadedCargoDaysInsideGeofence > 0 && (
          <Box
            data-testid="loaded-days-inside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--primary-blue)",
              color: "var(--white)",
              width: `${loadedInsideGeofence}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {loadedCargoDaysInsideGeofence}
          </Box>
        )}
      </Box>

      <Box className="justify gap-2">
        {legendKeys.map((key, index) => (
          <Chip
            key={key}
            label={legendLabels[index]}
            icon={
              <Circle
                sx={{
                  width: "16px",
                  fill: legendColors[index],
                }}
              />
            }
            className="!h-6 !mb-2 !mr-2 !rounded-full !text-white"
            sx={{
              color: "var(--mid-charcoal)",
              backgroundColor: "var(--grayscale-feather-gray)",
              "& .MuiChip-label": {
                color: "var(--mid-charcoal)",
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CargoStatusInLocations;
