import { FC, useCallback, useState, useEffect } from "react";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import {
  Box,
  Button as CancelButton,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isNil, pick, isEmpty } from "lodash";
import {
  DISABLE_ASSET_TABLE_ON_CLICK,
  PAGE_SNACKBAR,
} from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  Asset,
  UpdateAssetInput,
  useGetUserDataQuery,
  UpdateAssetOsMutation,
  UpdateAssetMutation,
} from "../../../../../graphql/operations";
import { Button, TextButton } from "../../../../../shared/components/Button";
import { InactivateAssetDialog } from "../../../../../shared/components/InactivateAssetDialog";
import Spinner from "../../../../../shared/components/Spinner";
import { WithPermissions } from "../../../../../shared/components/WithPermissions";
import { useFindAssetById } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import { useRemoveAsset } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useRemoveAsset";
import { useUpdateAsset } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useUpdateAsset";
import { useUserPermission } from "../../../../../shared/hooks/useUserPermission";
import {
  mapServerErrorCodeToHumanReadableMessage,
  omitByDeep,
  useFeatureFlag,
} from "../../../../../utils";
import { FeatureFlags } from "../../../../../utils/featureFlagsConstants";
import {
  AssetForm,
  getAssetFields,
  useAssetForm,
} from "../../../TableView/components";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext/AssetsDataContext";
import {
  onAssetRemoveSuccess,
  onAssetUpdateSuccess,
  UpdateAssetInputKeys,
} from "./helpers";

interface AssetDetailsSettingsProps {
  asset: Asset;
  onBack: () => void;
  isAssetRefetching: boolean;
}
export const AssetDetailsSettings: FC<AssetDetailsSettingsProps> = ({
  asset,
  onBack,
  isAssetRefetching,
}: AssetDetailsSettingsProps) => {
  const [showInactivationLoader, setShowInactivationLoader] = useState(false);
  const [showUpdateLoader, setShowUpdateLoader] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const { form, schema } = useAssetForm(getAssetFields(asset));
  const { dispatch } = useAppContext();
  const { setSelectedAssetId, selectedAssetId } = useAssetsDataContext();
  const isUserAllowed = useUserPermission("assetManagement.editAsset");
  const queryClient = useQueryClient();
  const [isInactivateDialogOpen, setIsInactivateDialogOpen] = useState(false);

  useEffect(() => {
    if (!isEmpty(form.formState.dirtyFields)) {
      setButtonsDisabled(false);
    } else {
      setButtonsDisabled(true);
    }
  }, [form.formState]);

  const { data: selectedAsset } = useFindAssetById(
    {
      assetId: selectedAssetId ?? "",
    },
    { enabled: !!selectedAssetId }
  );

  const { data: dataUserData } = useGetUserDataQuery();

  const canEditSettings =
    selectedAsset?.shared?.organizationId !==
    dataUserData?.me?.customer_orgs_id[0];

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const { mutateAsync } = useUpdateAsset({
    onSuccess: async (updated) => {
      updated = {
        ...updated,
        updateAsset: fetchAssetsFromOpenSearchFeatureFlag
          ? (updated as UpdateAssetOsMutation)?.updateAssetOS
          : (updated as UpdateAssetMutation)?.updateAsset,
      };

      form.reset(getAssetFields(updated?.updateAsset as Partial<Asset>), {
        keepDirty: false,
        keepDirtyValues: false,
      });
      setShowUpdateLoader(false);
      await onAssetUpdateSuccess({
        updated,
        selectedAsset,
        setSelectedAssetId,
        queryClient,
        fetchAssetsFromOpenSearchFeatureFlag,
      });

      await queryClient.invalidateQueries({
        queryKey: ["FindAssetById"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["findAssetByIdOS"],
      });

      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Asset Update",
          text: "Changes applied successfully and will be visible soon.",
          severity: "success",
        },
      });
    },
    onError: (error) => {
      setShowUpdateLoader(false);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Asset Update Failed",
          text: mapServerErrorCodeToHumanReadableMessage(
            error instanceof Error ? error.message : "Something Went Wrong."
          ),
          severity: "error",
        },
      });
    },
  });

  const { mutateAsync: removeAsset } = useRemoveAsset({
    onSuccess: () => {
      onAssetRemoveSuccess({
        dispatch,
        selectedAsset: asset,
        queryClient,
      });
    },
    onError: (_error) => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Asset Inactivation Failed",
          text: "Something Went Wrong.",
          severity: "error",
          onClose: () => {},
        },
      });
    },
  });

  const getAssetPropCopyCasted = () => {
    // we need this to reset the form with new asset data if user selects another asset
    const assetPropCopy = { ...asset };
    if (!assetPropCopy.wheel_config) {
      assetPropCopy.wheel_config = undefined;
    }
    if (!assetPropCopy.num_of_axles) {
      assetPropCopy.num_of_axles = undefined;
    }
    if (!assetPropCopy.door_type) {
      assetPropCopy.door_type = undefined;
    }
    // cast form to apply transformers
    return schema.cast(omitByDeep(assetPropCopy, isNil));
  };

  const handleFormSubmit = async (data: Partial<Asset>) => {
    const valid = await form.trigger();
    if (!valid) {
      return;
    }
    setShowUpdateLoader(true);
    await mutateAsync({
      asset: pick(data, UpdateAssetInputKeys) as UpdateAssetInput,
    });
  };

  const handleInactivateActionClick = () => {
    setIsInactivateDialogOpen(true);
  };

  const handleRemoveClick = useCallback(async () => {
    dispatch({
      type: DISABLE_ASSET_TABLE_ON_CLICK,
      payload: {
        isOnClickDisabled: true,
      },
    });
    setShowInactivationLoader(true);
    await removeAsset({
      id: asset?._id,
    });
    setIsInactivateDialogOpen(false);

    onBack();
  }, [asset._id, onBack, removeAsset, dispatch]);

  const handleSubmitClick = () => {
    form.handleSubmit((d) => handleFormSubmit(d))();
  };

  const handleCancelClick = () => {
    const preparedData = getAssetPropCopyCasted();

    form.reset(preparedData, {
      keepDirty: false,
      keepValues: false,
      keepDirtyValues: false,
    });
  };

  return (
    <>
      <Grid container>
        <AssetForm
          form={form}
          asset={asset}
          onChange={() => {}}
          showDetailsHeader
          className="w-full"
          disableAllFields={!isUserAllowed || !canEditSettings}
        />
      </Grid>
      {isUserAllowed && canEditSettings ? (
        <Box className="mx-auto md:w-full md:flex items-baseline md:justify-between">
          <WithPermissions accessScope={"asset.remove"}>
            <Box className="flex justify-center">
              <TextButton
                text={"Inactivate Asset"}
                size="medium"
                className="!text-error"
                icon={<NotInterestedIcon />}
                iconPosition={"left"}
                onClick={handleInactivateActionClick}
                data-testid="inactivate-button"
              />
            </Box>
          </WithPermissions>
          <Box className="flex items-baseline">
            <CancelButton
              data-testid="settings-cancel"
              className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand"
              onClick={handleCancelClick}
              disabled={buttonsDisabled}
              sx={{
                "&.MuiButton-root": {
                  padding: "18px 11px !important",
                },
              }}
            >
              Reset
            </CancelButton>
            <Button
              dataTestid="settings-submit"
              className="w-full"
              text={showUpdateLoader ? "Saving" : "Save"}
              size="medium"
              theme="blue"
              variant="default"
              type="button"
              onClick={handleSubmitClick}
              iconPosition="right"
              icon={
                showUpdateLoader && (
                  <CircularProgress size={15} style={{ color: "white" }} />
                )
              }
              disabled={buttonsDisabled}
              sx={{
                "&.MuiButton-root": {
                  width: "120px", // width is out of design, as button includes loader
                  marginLeft: "16px",
                  marginTop: "0",

                  "@media (max-width: 767px)": {
                    marginTop: "34px",
                  },
                },
              }}
            />
          </Box>
        </Box>
      ) : (
        ""
      )}
      <Spinner counter={Number(isAssetRefetching)} />

      <InactivateAssetDialog
        isLoading={showInactivationLoader}
        open={isInactivateDialogOpen}
        handleClose={() => setIsInactivateDialogOpen(false)}
        handleInactivate={handleRemoveClick}
      />
    </>
  );
};
