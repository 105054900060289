import { FC, useMemo } from "react";
import { Circle } from "@mui/icons-material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Box,
  Chip,
  Stack,
  TextField,
  MenuItem,
  ThemeProvider,
  Grid,
  IconButton,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { last } from "lodash";
import { nanoid } from "nanoid";
import { DEFAULT_DATE_RANGE_OPTIONS } from "../../../../../constants/map";
import { useAppContext } from "../../../../../context/AppContext";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import useChartExpand from "../../../../../shared/hooks/useChartExpand";
import {
  checkIsAfter,
  checkIsBefore,
  formatDateWithoutTimeZone,
  getStartOfDay,
  getSubMonths,
  isEqualDates,
} from "../../../../../utils/date";
import {
  getBoxColor,
  getBoxBorderColor,
} from "../../Assets/CargoTabPanel/CargoStatus/utils";
import DoorHeader from "../../Assets/CargoTabPanel/DoorStatus/DoorHeader";
import { DoorData } from "../../Assets/CargoTabPanel/DoorStatus/utils";
import { HOURS_MAP } from "../../Assets/CargoTabPanel/InternalCameraFloorSpaceGraph/constants";
import { useBrakesTabTheme } from "../BrakesTab/useBrakesTabTheme";

export interface DoorSensorGraphProps {
  data: Array<DoorData>;
  canResize?: boolean;
  startDateDoor: Date | null;
  endDateDoor: Date | null;
  setStartDateDoor: (date: Date | null) => void;
  setEndDateDoor: (date: Date | null) => void;
  selectedDateRangeOptionDoor: number;
  setSelectedDateRangeOptionDoor: (value: number) => void;
}

const DOOR_LEGEND_LABELS = ["Open", "Closed", "Unknown"];

const DoorSensorGraph: FC<DoorSensorGraphProps> = ({
  data,
  canResize = true,
  startDateDoor,
  endDateDoor,
  setStartDateDoor,
  setEndDateDoor,
  selectedDateRangeOptionDoor,
  setSelectedDateRangeOptionDoor,
}) => {
  const { gridWidth, gridWidthIndicator, handleToggleGridWidth } =
    useChartExpand();
  const isDesktop = useBreakpoint("up", "lg");
  const {
    state: { theme },
  } = useAppContext();

  const isLightTheme = useMemo(() => theme.theme === "light", [theme]);

  const doorLegendColors = useMemo(
    () =>
      isLightTheme
        ? ["var(--brand-light-blue)", "var(--off-white)", "var(--border-color)"]
        : ["var(--brand-light-blue)", "var(--border)", "var(--dark-grey)"],
    [isLightTheme]
  );

  const brakesTabTheme = useBrakesTabTheme();

  const option = DEFAULT_DATE_RANGE_OPTIONS.find(
    (option) => option.value === selectedDateRangeOptionDoor
  );
  const [startDate, endDate] = option?.getRange
    ? option.getRange()
    : [new Date(), new Date()];

  const handleDateRangeChange = (value: number) => {
    const newOption = DEFAULT_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );
    const [start, end] = newOption?.getRange
      ? newOption.getRange()
      : [new Date(), new Date()];

    if (newOption && start && end) {
      setSelectedDateRangeOptionDoor(value);
      setStartDateDoor(start);
      setEndDateDoor(end);
    } else {
      setSelectedDateRangeOptionDoor(value);
    }
  };

  const setLastDateRangeOption = () => {
    setSelectedDateRangeOptionDoor(last(DEFAULT_DATE_RANGE_OPTIONS)?.value!);
  };

  const sortedEventHistoryData = [...data].sort((a: any, b: any) => {
    const aDate = new Date(a?.eventHistoryData?.date ?? 0);
    const bDate = new Date(b?.eventHistoryData?.date ?? 0);
    return aDate.getTime() - bDate.getTime();
  });

  const filteredDoorData = sortedEventHistoryData.filter((e: any) => {
    const eventDate = new Date(e.eventHistoryData.date);

    return (
      (checkIsAfter(eventDate, startDate) &&
        checkIsBefore(eventDate, endDate)) ||
      isEqualDates(eventDate, startDate) ||
      isEqualDates(eventDate, endDate)
    );
  });

  return (
    <Grid item lg={gridWidth} className={isDesktop ? "block" : "hidden"}>
      <Box className="h-full w-full flex flex-col p-5 bg-dashboard_subheader__bg">
        <Stack direction="row" justifyContent="end">
          <DoorHeader title="Door Trending Graph" noBorder />
          {canResize && (
            <IconButton
              sx={{
                width: "2rem",
                height: "1rem",
                backgroundColor: "transparent!important",
              }}
              aria-label="toggleWidth"
              data-testid="toggleWidth-door-chart"
              onClick={() => handleToggleGridWidth()}
            >
              <OpenInFullIcon
                fontSize="medium"
                style={{
                  fill: "var(--cargo-trend-expand-icon)",
                  marginRight: "0.313rem",
                }}
              />
              <span
                className="font-bold text-sm leading-4"
                style={{ marginTop: "-0.5rem", color: ColorsPalette.LightBlue }}
              >
                {gridWidthIndicator}
              </span>
            </IconButton>
          )}
        </Stack>
        <ThemeProvider theme={brakesTabTheme}>
          <Box className="flex items-center justify-end gap-4 py-4">
            <MobileDatePicker
              label="Start date"
              inputFormat="MM/dd/yyyy"
              closeOnSelect
              value={startDateDoor}
              shouldDisableDate={(day) =>
                day < getStartOfDay(getSubMonths(new Date(), 6))
              }
              disableFuture
              onChange={(date) => {
                setStartDateDoor(date);
                setLastDateRangeOption();
              }}
              DialogProps={{
                className: "door-trending-date-picker",
              }}
              renderInput={(params) => (
                <TextField
                  data-testid="door-chart-start-date"
                  variant="outlined"
                  {...params}
                />
              )}
            />
            <span className="text-base font-normal text-primary">to</span>
            <MobileDatePicker
              DialogProps={{
                className: "trending-voltage-date-picker",
              }}
              label="End date"
              closeOnSelect
              inputFormat="MM/dd/yyyy"
              value={endDateDoor}
              shouldDisableDate={(day) =>
                !!startDateDoor && day < startDateDoor
              }
              disableFuture
              onChange={(date) => {
                setEndDateDoor(date);
                setLastDateRangeOption();
              }}
              renderInput={(params) => (
                <TextField
                  data-testid="voltage-chart-end-date"
                  variant="outlined"
                  {...params}
                />
              )}
            />

            <TextField
              data-testid="door-chart-date-range-select"
              select
              value={selectedDateRangeOptionDoor}
              variant="outlined"
              className="w-40"
              onChange={(e) => handleDateRangeChange(+e.target.value)}
            >
              {DEFAULT_DATE_RANGE_OPTIONS.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  data-testid={`door-chart-date-range-select-option-${option.value}`}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </ThemeProvider>
        <Box
          className="flex font-medium text-[10px] overflow-auto"
          data-testid="door-trending-graph-wrapper"
        >
          <Box
            className="flex flex-col mr-2 pt-1"
            data-testid="door-trending-graph-hour-list"
          >
            {HOURS_MAP.map((hour) => (
              <Box
                key={nanoid()}
                className="h-[36px] mb-2 flex items-center justify-end text-card-sensors-text"
              >
                {hour}
              </Box>
            ))}
          </Box>

          <Box
            className="flex h-full w-full"
            data-testid="door-trending-graph-content"
          >
            {filteredDoorData.map((day) => (
              <Box className="w-full" key={nanoid()}>
                {!!day?.eventHistoryData?.door?.doorStatus &&
                  day.eventHistoryData.door.doorStatus.reverse().map((hour) => (
                    <Box
                      key={nanoid()}
                      className={`h-[36px] flex flex-1 items-center justify-center m-1 mb-2 rounded ${getBoxColor(
                        hour?.status as number
                      )}`}
                      sx={{
                        borderColor: getBoxBorderColor(hour?.status as number),
                      }}
                    />
                  ))}

                <Box
                  className="mt-4 text-center text-card-sensors-text"
                  data-testid="door-trending-graph-day"
                >
                  {day?.eventHistoryData?.date &&
                    formatDateWithoutTimeZone(day.eventHistoryData.date)}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          className="mt-8 flex justify-center items-center"
          data-testid="door-trending-graph-legend"
        >
          {DOOR_LEGEND_LABELS.map((key, index) => (
            <Chip
              key={key}
              label={DOOR_LEGEND_LABELS[index]}
              icon={
                <Circle
                  sx={{
                    width: "16px",
                    height: "16px",
                    fill: doorLegendColors[index].toLowerCase(),
                    borderRadius: "50%",
                  }}
                />
              }
              sx={{
                height: "1.5rem",
                color: ColorsPalette.BrightWhite,
                borderRadius: "9999px",
                marginBottom: "0.5rem",
                marginRight: "0.5rem",
                backgroundColor: "var(--grayscale-feather-gray)",
                "& .MuiChip-label": {
                  color: "var(--mid-charcoal)",
                },
              }}
            />
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default DoorSensorGraph;
