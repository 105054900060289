import { memo } from "react";
import Page from "../../shared/components/Page";
import AssetsViewContainer from "./AssetsViewContainer";
import { AssetsDataContextProvider } from "./shared/AssetsDataContext";
import AssetsFilterControls from "./shared/AssetsFilterControls";

const AssetView = memo(() => (
  <Page className="bg-background" contentClassNames="overflow-hidden">
    <AssetsDataContextProvider>
      <AssetsFilterControls />
      <AssetsViewContainer />
    </AssetsDataContextProvider>
  </Page>
));

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
AssetView.displayName = "AssetsView";
export default AssetView;
