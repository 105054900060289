import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import * as uuid from "uuid";
import * as yup from "yup";
import { AutomationMode } from "../../../../../graphql/operations";
import { AlertTypeTitle } from "../../../../AlertsView/constants";

export type AutomationAction = {
  command: string;
  name: string;
  parameters: string;
  _id?: string;
};

export type AutomationInitialValues = {
  mode: AutomationMode;
  parameters: {
    alertIds: string[] | undefined | null;
    alertType: typeof AlertTypeTitle | undefined | null;
  };
  trigger: string[] | undefined | null;
  actions: AutomationAction[];
};

export const useAutomationForm = (
  initialValues: AutomationInitialValues = {
    mode: AutomationMode.Pause,
    parameters: {
      alertIds: null,
      alertType: null,
    },
    trigger: null,
    actions: [],
  }
) => {
  const schema = yup.object().shape({
    mode: yup
      .string()
      .oneOf([AutomationMode.Pause, AutomationMode.Run])
      .required(),
    parameters: yup
      .object()
      .shape({
        alertIds: yup
          .array()
          .of(yup.string())
          .notRequired()
          .optional()
          .nullable(),
        alertType: yup
          .string()
          .oneOf([...Object.keys(AlertTypeTitle), null])
          .notRequired()
          .optional()
          .nullable(),
      })
      .notRequired()
      .optional(),
    trigger: yup.mixed().nullable().notRequired().optional(),
    actions: yup
      .array()
      .of(
        yup.object().shape({
          command: yup.string().optional(),
          name: yup.string().required("Action name is required"),
          parameters: yup.mixed().optional(),
        })
      )
      .notRequired()
      .optional(),
  });

  const preparedInitialValues = useMemo(() => {
    return {
      ...initialValues,
      actions: initialValues.actions.map((action) => ({
        ...action,
        parameters: action.parameters ? JSON.parse(action.parameters) : "",
        _id: uuid.v4(), // assign a random id
      })),
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const form = useForm({
    resolver: yupResolver(schema),
    values: omitBy(
      preparedInitialValues,
      isNil
    ) as Partial<AutomationInitialValues>,
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { schema, form, getValues };
};
