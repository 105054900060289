import { MutableRefObject } from "react";
import { PauseCircleOutline, TrendingUp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid-premium";
import { QueryClient } from "@tanstack/react-query";
import { Automation } from "../../../../graphql/operations";

export enum AutomationsDrawerState {
  edit = "edit",
  create = "create",
}

export const setSelectedAutomation = (
  data: GridRowParams,
  setAutomationToEdit: Function,
  setFormMode: Function,
  setDrawerOpened: Function
) => {
  if (data?.row) {
    setAutomationToEdit(data.row);
    setFormMode(AutomationsDrawerState.edit);
    setDrawerOpened(true);
  }
};

export const handleRefresh = (
  queryClient: QueryClient,
  refreshAutomations: MutableRefObject<Function>
) => {
  queryClient.invalidateQueries({ queryKey: ["getAutomations"] });
  refreshAutomations?.current();
};

export const automationColumns: GridColDef<Automation>[] = [
  {
    field: "name",
    headerName: "Automation name",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "trigger",
    headerName: "Trigger Type",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "lastRun",
    headerName: "Last run",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "actionButtons",
    headerName: "Action Buttons",
    minWidth: 120,
    groupable: false,
    renderCell: (params: GridRenderCellParams) => {
      const { colorReflectsAsset, outerRingReflectsDwell } = params.row;

      return (
        <>
          <IconButton aria-label="close" onClick={() => {}}>
            <PauseCircleOutline />
          </IconButton>
          <IconButton aria-label="close" onClick={() => {}}>
            <TrendingUp />
          </IconButton>
        </>
      );
    },
    disableExport: true,
    filterable: false,
  },
];

export const automationsVisibleColumns = {
  name: true,
  trigger: true,
  action: true,
  lastRun: true,
  status: true,
  actionButton: true,
};

export const searchKeys = [
  "name",
  "trigger",
  "action",
  "lastRun",
  "status",
  "actionButton",
];
