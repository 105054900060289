import { FC, useState, useEffect, useCallback } from "react";
import { FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PauseIcon from "@mui/icons-material/Pause";
import SaveIcon from "@mui/icons-material/Save";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box, ThemeProvider } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useAppContext } from "../../../../context/AppContext";
import {
  Automation as AutomationData,
  CreateAutomationMutation,
  AutomationMode,
} from "../../../../graphql/operations";
import { LeaveModal } from "../../../../shared/components/LeaveModal";
import {
  NameWithBackButton,
  ChangeNameDialog,
} from "../../../../shared/components/NameWithBackButton";
import { SubHeader } from "../../../../shared/components/SubHeader";
import {
  SubHeaderActionProps,
  SubHeaderActionType,
} from "../../../../shared/components/SubHeader/components/SubHeaderAction/SubHeaderAction";
import { SwitchButtons } from "../../../../shared/components/SwitchButtons";
import { ToggleButtonOption } from "../../../../shared/components/ToggleButtons";
import { UserAccessScope } from "../../../../shared/components/WithPermissions";
import { usePrompt } from "../../../../shared/hooks/usePrompt";
import { useSpinner } from "../../../../shared/hooks/useSpinner";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { useAlertTheme } from "../../../AlertView/hooks/useAlertTheme";
import { AutomationForm } from "./AutomationForm";
import { useAutomationForm } from "./hooks/useAutomationForm";
import { useAutomationsApi } from "./hooks/useAutomationsApi";
import {
  createAutomationOnErrorCallback,
  onSuccessCreateCallback,
} from "./utils/utils";

interface AutomationProps {
  isTemplate: boolean;
}

export const Automation: FC<AutomationProps> = ({ isTemplate }) => {
  const automationTheme = useAlertTheme();
  const {
    state: {
      theme: { theme },
      selectedOrganization: { selectedOrganization },
    },
    dispatch,
  } = useAppContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [shouldCreateAutomation, setShouldCreateAutomation] = useState(false);

  const isDarkMode = theme === "dark";
  const color = isDarkMode ? "white" : "black";

  const [automation, setAutomation] = useState<AutomationData | null>();
  const [automationMode, setAutomationMode] = useState<AutomationMode>(
    AutomationMode.Pause
  );
  const [automationName, setAutomationName] = useState("Automation");

  const { form } = useAutomationForm();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const createAutomationOnSuccess = async (data: CreateAutomationMutation) => {
    await onSuccessCreateCallback({
      dispatch,
      created: data.createAutomation,
      navigate,
      queryClient,
      setAutomation,
      setLoading,
      setShouldCreateAutomation,
    });

    form.reset();
    setShouldPrompt(false);
  };

  const createAutomationOnError = (error: unknown) => {
    createAutomationOnErrorCallback({ error, dispatch, setLoading });
  };

  const { createAutomation } = useAutomationsApi({
    createAutomationOnSuccess,
    createAutomationOnError,
  });

  const handleFormSubmit = async (data: FieldValues) => {
    const valid = await form.trigger();
    if (!valid) {
      return;
    }

    if (isTemplate) {
      setShouldCreateAutomation(true);
      const actions = data.actions.map((action: any) => {
        return {
          command: action.command,
          name: action.name,
          parameters: action.parameters
            ? JSON.stringify(action.parameters)
            : null,
        };
      });
      createAutomation({
        actions: actions,
        mode: automationMode,
        name: automationName,
        orgId: selectedOrganization.value,
        parameters: data.parameters,
        trigger: data.trigger,
      });
    } else {
      //should be removed after implementing of update logic
      return;
    }

    setLoading(true);
  };

  const handleSubmitClick = () => {
    form.handleSubmit((data) => handleFormSubmit(data))();
  };

  const handleAutomationStatusChange = (value: AutomationMode) => {
    setAutomationMode(value);
  };

  const subHeaderActions: SubHeaderActionProps[] = [
    {
      type: SubHeaderActionType.Button,
      icon: <SaveIcon />,
      title: "Save",
      primary: true,
      handler: handleSubmitClick,
      accessScope: "automations.create" as UserAccessScope,
    },
  ];

  const AUTOMATION_STATUS_SWITCH_OPTIONS: ToggleButtonOption[] = [
    {
      value: "pause",
      label: "Pause",
      icon: <PauseIcon />,
    },
    {
      value: "run",
      label: "Run",
      icon: <TrendingUpIcon />,
    },
  ];

  const handleCloseEditNameDialog = () => {
    setIsDialogOpen(false);
  };
  const handleSubmitEditNameDialog = (name: string) => {
    setAutomationName(name);
    setIsDialogOpen(false);
  };

  const [shouldPrompt, setShouldPrompt] = useState(false);
  const { isLeaveModalOpen, setIsLeaveModalOpen, setIsConfirmed } =
    usePrompt(shouldPrompt);
  const handleLeaveModalClose = () => {
    setIsLeaveModalOpen(false);
    setIsConfirmed(false);
  };
  const dirtyFields = Object.keys(form.formState.dirtyFields);
  const formValues = form.watch();
  const updatePromptState = useCallback(() => {
    const condition =
      (!!dirtyFields.length || automation?.name !== automationName) &&
      !shouldCreateAutomation;
    if (condition) {
      setShouldPrompt(true);
    } else {
      setShouldPrompt(false);
    }
    return condition;
  }, [dirtyFields, automation?.name, automationName, shouldCreateAutomation]);

  useEffect(() => {
    updatePromptState();
  }, [
    automationName,
    automation?.name,
    formValues,
    dirtyFields,
    shouldCreateAutomation,
    updatePromptState,
  ]);

  useSpinner(loading);

  return (
    <Box data-testid="automation" className="pb-8">
      <SubHeader
        title="Automation"
        actions={subHeaderActions}
        hideOrgName
        customElement={
          <SwitchButtons
            id="automation-status-switch"
            data-testid="automation-status-switch-mobile"
            value={automationMode}
            onChange={(_, value) => handleAutomationStatusChange(value)}
            options={AUTOMATION_STATUS_SWITCH_OPTIONS}
            size="small"
            groupclass={`!w-[300px] min-w-80 flex justify-between h-8 overflow-hidden !rounded-3xl p-0.5 !border ${
              isDarkMode ? "!border-white" : "!border-black"
            }`}
            className={`!text-${color} !font-medium`}
            fullWidth
            exclusive
          />
        }
      />
      <NameWithBackButton
        name={automationName}
        moveBackRoute={NavigationRoutes.AdminPanelAutomations}
        setIsDialogOpen={setIsDialogOpen}
      />
      <ThemeProvider theme={automationTheme}>
        <AutomationForm form={form} isTemplate={isTemplate} />
      </ThemeProvider>
      <ChangeNameDialog
        name={automationName}
        nameType={"automation"}
        open={isDialogOpen}
        onClose={handleCloseEditNameDialog}
        onSubmit={handleSubmitEditNameDialog}
      />
      <LeaveModal
        isOpen={isLeaveModalOpen}
        confirmNavigation={() => setIsConfirmed(true)}
        onClose={handleLeaveModalClose}
      />
    </Box>
  );
};
