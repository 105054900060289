import { useState } from "react";
import { AuthenticationResultType } from "@aws-sdk/client-cognito-identity-provider";
import { Box } from "@mui/material";
import { useAuthContext } from "../../context/AuthContext";
import { mfaSignIn } from "../../services/aws/auth";
import Logo from "../../shared/components/Svg/Logo";
import { navigateToRoute } from "../../utils";
import { NavigationRoutes } from "../../utils/routes/routesUtils";

const MfaVerifyAuthAppCode = () => {
  const { login } = useAuthContext();
  const [code, setCode] = useState("");
  const username = sessionStorage.getItem("username") ?? "";
  const session = sessionStorage.getItem("session") ?? "";

  const handleSignIn = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    try {
      const result = await mfaSignIn(session, username, code);
      const { AccessToken, ExpiresIn, IdToken, RefreshToken } =
        result as AuthenticationResultType;

      if (AccessToken && IdToken && RefreshToken && ExpiresIn && session) {
        sessionStorage.setItem("accessToken", AccessToken ?? "");

        const tokens = {
          accessToken: AccessToken,
          idToken: IdToken,
          refreshToken: RefreshToken,
          expiresIn: ExpiresIn,
        };

        login(tokens);

        return navigateToRoute(NavigationRoutes.Home, false);
      } else {
        console.error("SignIn session or AccessToken is undefined.");
      }
    } catch (error) {
      console.error(`Sign in failed: ${error}`);
    }
  };

  return (
    <Box className="v-full h-full flex flex-col justify-center items-center bg-gray">
      <Logo />
      <Box className="my-4 text-sm font-thin">
        Enter code from authentication app
      </Box>
      <Box className="flex flex-col justify-center w-[200px] h-[150px]">
        <input
          className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded"
          id="code"
          type="code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Code"
          required
        />

        <button
          className="mb-2 h-[40px] bg-gray hover:bg-primary-blue hover:text-white rounded"
          onClick={handleSignIn}
        >
          Sign In
        </button>
      </Box>
    </Box>
  );
};

export default MfaVerifyAuthAppCode;
