import { GridColumnOrderChangeParams } from "@mui/x-data-grid-premium";
import {
  ReportAlertHistoryTableData,
  DwellHierarchyTableData,
  FiltersInput,
  TableFilterLinkOperator,
  TableFiltersInput,
  TableFilterInput,
} from "../../../graphql/operations";
import {
  MUI_GRID_FILTER_OPERATOR_TO_TABLE_FILTER_OPERATOR_MAP,
  MUI_GRID_LINK_OPERATOR_TO_TABLE_FILTER_LINK_OPERATOR_MAP,
  TableGridColDef,
} from "../../../shared/components/Table";
import { checkIsAfter, checkIsBefore } from "../../../utils";
import {
  isTableFiltersEmpty,
  isTableFiltersValid,
} from "../../../utils/validateTableFilters";
import { ReportGridColDef, transformColumnField } from "../helpers/helpers";
import { ReportWithParameters } from "../interfaces";
import { SENSORS_COLUMN_TYPES_MAP } from "./components/SensorsTable/columns";

export const changeColumnsOrder = (
  colData: GridColumnOrderChangeParams,
  columns: (
    | ReportGridColDef
    | TableGridColDef<DwellHierarchyTableData | ReportAlertHistoryTableData>
  )[],
  report: ReportWithParameters
) => {
  const { targetIndex, field } = colData;
  const oldIndex = columns.findIndex((col) => col.field === field);
  const newColumns = [...columns];
  const newReportColumns = [...report.columns];
  const oldReportColumnIndex = newReportColumns.findIndex(
    (col) => col.field === field
  );
  newColumns.splice(targetIndex, 0, newColumns.splice(oldIndex, 1)[0]);
  newReportColumns.splice(
    targetIndex,
    0,
    newReportColumns.splice(oldReportColumnIndex, 1)[0]
  );
  return { newColumns, newReportColumns };
};

interface Parent {
  startDate: string | null | undefined;
  endDate: string | null | undefined;
}

export const isStartAfterEnd = (
  value: string | null | undefined,
  parent: Parent
) => {
  if (!value || !parent.endDate) return true;

  return !checkIsAfter(new Date(value), new Date(parent.endDate));
};

export const isEndBeforeStart = (
  value: string | null | undefined,
  parent: Parent
) => {
  if (!value || !parent.startDate) return true;

  return !checkIsBefore(new Date(value), new Date(parent.startDate));
};

export const validateAndMapSensorsFiltersOS = (value: {
  items: FiltersInput[];
  linkOperator: TableFilterLinkOperator;
}): TableFiltersInput | null | undefined => {
  if (!value?.items.length) {
    return null;
  }
  const filtersValid = isTableFiltersValid(value.items);

  if (filtersValid) {
    const filterItems: TableFilterInput[] = value.items.map((i) => ({
      dataType: SENSORS_COLUMN_TYPES_MAP[i.columnField],
      value: JSON.stringify({ value: i?.value ?? [] }),
      field: transformColumnField(i.columnField),
      operator:
        MUI_GRID_FILTER_OPERATOR_TO_TABLE_FILTER_OPERATOR_MAP[
          SENSORS_COLUMN_TYPES_MAP[i.columnField]
        ][i.operatorValue],
    }));
    return {
      filters: filterItems,
      linkOperator:
        MUI_GRID_LINK_OPERATOR_TO_TABLE_FILTER_LINK_OPERATOR_MAP[
          value.linkOperator
        ],
    };
  }

  const filtersEmpty = isTableFiltersEmpty(value.items);
  if (filtersEmpty) {
    return null;
  }
};
