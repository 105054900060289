import { Chip, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { SensorStatusUppercase } from "../../../../../../../graphql/operations";
import { useGetSensorStateWidget } from "../../../AssetShortTooltip/hooks/useGetSensorStateWidget";
import { getElapsedTimeSinceLabel } from "../../../TiresTabPanel/helpers";
import { LiftgateHeader } from "../header/LiftgateHeader";
import LiftgateBatteryIcon from "./LiftgateBatteryIcon";

type LiftgateBatteryProps = {
  status: string;
  date: string | undefined;
};

const LiftgateBatteryStatus = ({ status, date }: LiftgateBatteryProps) => {
  const { getColoredStatusIcon } = useGetSensorStateWidget();

  return (
    <Box
      sx={{
        width: "full",
        backgroundColor: "var(--card__bg)",
        borderRadius: "0.5rem",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="streched"
        className="p-4 rounded-lg"
        data-testId="liftgate-battery-status-wrapper"
      >
        <LiftgateHeader text="Liftgate Battery Status" />
        <Box
          className="flex justify-center w-full my-8"
          data-testId="liftgate-battery-status-icon"
        >
          <Grid container xs={10} md={8}>
            <LiftgateBatteryIcon
              width={250}
              height={255}
              statusIcon={getColoredStatusIcon(status as SensorStatusUppercase)}
            />
          </Grid>
        </Box>
        {date && (
          <Box className="flex justify-end w-full">
            <Chip
              component="span"
              label={getElapsedTimeSinceLabel(new Date(date))}
              data-testId="liftgate-battery-status-date-chip"
              sx={{
                backgroundColor: "var(--primary-chip-bg)",
                color: "var(--primary-chip-text)",
                fontWeight: 700,
                fontSize: "12px",
                lineHeight: "12px",
                borderRadius: "4px",
                height: "18px",
                "& .MuiChip-label ": {
                  padding: "0px 8px",
                },
              }}
            />
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default LiftgateBatteryStatus;
