import { useState, FC } from "react";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { last } from "lodash";
import { ReactComponent as LiftgateSensorDark } from "../../../../../../../assets/svgs/liftGateSensorDark.svg";
import { ReactComponent as LiftgateSensorLight } from "../../../../../../../assets/svgs/liftgateSensorLight.svg";
import { VOLTAGE_CHART_DATE_RANGE_OPTIONS } from "../../../../../../../constants/map";
import { useAppContext } from "../../../../../../../context/AppContext";
import {
  useGetLiftgateBatteryAggregatedDataOsQuery,
  ReportType,
} from "../../../../../../../graphql/operations";
import { VerticalBarChartOverlay } from "../../../../../../../shared/components/LoadingOverlaySkeletons/VerticalBarChartLoader/VerticalBarChartOverlay";
import { themes } from "../../../../../../../shared/hooks/theme/utils";
import {
  getEndOfToday,
  getStartOfDay,
  getSubDays,
  formatDate,
} from "../../../../../../../utils/date";
import BatteryChart from "../../../../Shared/BatteryChart";

const CustomBatteryChartTooltip = ({
  active,
  payload,
}: {
  active?: number;
  payload: any;
}) => {
  const item = payload?.[0];
  const min = item?.payload?.Minimum;
  const max = item?.payload?.Maximum;
  const unit = item?.payload?.units?.[item?.dataKey];

  return (
    <Box className="rounded-lg bg-custom-tooltip-background border border-sub-header-border p-3 flex flex-col gap-3">
      <Typography className="!text-md !text-sub-header-text !font-medium !leading-3 !mb-1">
        {item?.payload?.tooltipLabel ??
          item?.payload?.date?.replaceAll("-", "/")}
      </Typography>
      <Typography className="!text-md !text-sub-header-text !font-medium !leading-3 !mb-1">
        {`Battery Voltage: ${min?.toFixed(2)}${unit} - ${max?.toFixed(
          2
        )}${unit}`}
      </Typography>
    </Box>
  );
};

export type LiftgateBatteryChartProps = {
  assetDetails: { id?: string | null; imei?: string | null };
};

const LiftgateBatterChart: FC<LiftgateBatteryChartProps> = ({
  assetDetails,
}) => {
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === themes.light;
  const [batteryStartDate, setBatteryStartDate] = useState<Date | null>(
    getStartOfDay(getSubDays(new Date(), 30))
  );

  const [batteryEndDate, setBatteryEndDate] = useState<Date | null>(
    getEndOfToday()
  );
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(
    VOLTAGE_CHART_DATE_RANGE_OPTIONS[0].value
  );

  const setSelectedLastDateRangeOption = () => {
    setSelectedDateRangeOption(last(VOLTAGE_CHART_DATE_RANGE_OPTIONS)?.value!);
  };

  const handleBatteryDateRangeChange = (value: number) => {
    const option = VOLTAGE_CHART_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );
    setSelectedDateRangeOption(value);
    if (option?.getRange) {
      const range = option.getRange();
      setBatteryStartDate(range[0]);
      setBatteryEndDate(range[1]);
    }
  };

  const { data: dataMotorTime, isLoading } =
    useGetLiftgateBatteryAggregatedDataOsQuery(
      {
        input: {
          imei: assetDetails.imei,
          assetId: assetDetails.id ?? "",
          reportType: ReportType.Sensors,
          startDate: batteryStartDate,
          endDate: batteryEndDate,
        },
      },
      {
        enabled: Boolean(assetDetails.imei && assetDetails.id),
      }
    );

  const formattedBatteryData =
    dataMotorTime?.getLiftgateAggregatedDataOS?.data?.[0]?.eventHistoryData?.liftgate?.battery?.map(
      (item) => {
        const minimum = (item?.minimum_liftgate_voltage ?? 0) / 1000;
        const maximum = (item?.maximum_liftgate_voltage ?? 0) / 1000;
        const average = (item?.average_liftgate_voltage ?? 0) / 1000;

        return {
          date: item?.date,
          Minimum: minimum > 16 ? 16 : minimum,
          Maximum: maximum > 16 ? 16 : maximum,
          Average: average > 16 ? 16 : average,
          min: {
            Minimum: minimum > 16 ? 16 : minimum,
            Maximum: maximum > 16 ? 16 : maximum,
            Average: average > 16 ? 16 : average,
          },
          max: {
            Minimum: minimum > 16 ? 16 : minimum,
            Maximum: maximum > 16 ? 16 : maximum,
            Average: average > 16 ? 16 : average,
          },
          units: {
            Minimum: "v",
            Maximum: "v",
            Average: "v",
          },
        };
      }
    ) ?? [];

  const svgIconSettings = {
    width: "80px",
    height: "80px",
    display: "block",
  };

  return isLoading ? (
    <VerticalBarChartOverlay />
  ) : (
    <>
      <Box className="!mb-1 justify w-full flex items-center">
        {isLightTheme ? (
          <LiftgateSensorDark style={svgIconSettings} />
        ) : (
          <LiftgateSensorLight style={svgIconSettings} />
        )}

        <Typography
          variant="caption"
          className="!pl-2 !text-base inline-block !font-semibold !text-primary-blue"
        >
          Liftgate Battery Voltage Trending
        </Typography>
      </Box>

      <Box className="flex items-center justify-end gap-4 py-4">
        <MobileDatePicker
          label="Start date"
          inputFormat="MM/dd/yyyy"
          closeOnSelect
          value={batteryStartDate}
          shouldDisableDate={(day) =>
            day < getStartOfDay(getSubDays(new Date(), 90))
          }
          disableFuture
          onChange={(date) => {
            setBatteryStartDate(date);
            setSelectedLastDateRangeOption();
          }}
          DialogProps={{
            className: "trending-temperature-date-picker",
          }}
          renderInput={(params) => (
            <TextField
              data-testid="chart-start-date"
              variant="outlined"
              sx={{
                "& .MuiInputLabel-root": {
                  top: -6,
                  left: 15,
                  fontWeight: "normal",
                },
              }}
              {...params}
            />
          )}
        />
        <span className="text-base font-normal text-primary">to</span>
        <MobileDatePicker
          DialogProps={{
            className: "trending-temperature-date-picker",
          }}
          label="End date"
          closeOnSelect
          inputFormat="MM/dd/yyyy"
          value={batteryEndDate}
          shouldDisableDate={(day) =>
            Boolean(batteryStartDate && day < batteryStartDate)
          }
          disableFuture
          onChange={(date) => {
            setBatteryEndDate(date);
            setSelectedLastDateRangeOption();
          }}
          renderInput={(params) => (
            <TextField
              data-testid="chart-end-date"
              variant="outlined"
              sx={{
                "& .MuiInputLabel-root": {
                  top: -6,
                  left: 15,
                  fontWeight: "normal",
                },
              }}
              {...params}
            />
          )}
        />

        <TextField
          data-testid="chart-date-range-select"
          select
          value={selectedDateRangeOption}
          variant="outlined"
          className="w-40"
          onChange={(e) => handleBatteryDateRangeChange(Number(e.target.value))}
        >
          {VOLTAGE_CHART_DATE_RANGE_OPTIONS.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              data-testid={`chart-date-range-select-option-${option.value}`}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <BatteryChart
        data={formattedBatteryData}
        xAxisTickFormatter={(tickItem: string) => {
          return formatDate(new Date(tickItem), "MM/dd");
        }}
        lineKeys={["Average", "Minimum", "Maximum"]}
        xKey="date"
        showLegend={Boolean(formattedBatteryData.length && !isLoading)}
        leftYAxisTicks={Array.from({ length: 17 }, (_, i) => i)}
        customTooltip={(payload, hoveredLineIndex) => (
          <CustomBatteryChartTooltip
            active={hoveredLineIndex}
            payload={payload}
          />
        )}
      />
    </>
  );
};

export default LiftgateBatterChart;
