import { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { sub } from "date-fns";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ColorsPalette } from "../../../../design-system/colors-palette";
import {
  useGetAlertsHistogramDataQuery,
  GetAlertsHistogramData,
  GetAlertsHistogramDataInput,
} from "../../../../graphql/operations";
import useBreakpoint from "../../../../shared/hooks/useBreakpoint";
import { DAY_MONTH_FORMAT, formatDateToUTC } from "../../../../utils";
import {
  ChartTooltip,
  ChartType,
} from "../../../AssetsView/MapView/Shared/ChartTooltip/ChartTooltip";
import { AlertsHistogramSkeleton } from "./AlertsHistogramSkeleton";

const containerHeight = 330;

const chartTickStyle = {
  fontSize: 10,
  fill: "var(--chart-tick-style)",
  fontWeight: 500,
};

export const AlertsHistogram: React.FC = () => {
  const isMobile = useBreakpoint("down", "sm");

  const [chartData, setChartData] = useState<GetAlertsHistogramData[]>([]);
  const [showNoData, setShowNoData] = useState<boolean>(false);

  // Calculate the width of the yAxis based on the number of digits in the largest incident count
  const yAxisWidth = useMemo(() => {
    const biggestCountLength = chartData.reduce(
      (max, item) => Math.max(max, item.incidents.toString().length),
      0
    );
    return biggestCountLength > 1 ? biggestCountLength * 8 : 9;
  }, [chartData]);

  // Fetch data for the last 30 days. Will be updated when the date range picker is implemented
  const queryInput: GetAlertsHistogramDataInput = useMemo(() => {
    return {
      dateFrom: sub(new Date(), { days: 30 }),
      dateTo: new Date(),
    };
  }, []);

  const { data, isLoading } = useGetAlertsHistogramDataQuery({
    input: queryInput,
  });

  useEffect(() => {
    if (data?.getAlertsHistogramData) {
      const filteredData = data.getAlertsHistogramData.filter(
        (item): item is GetAlertsHistogramData => item !== null
      );

      setChartData(filteredData);
      setShowNoData(!isLoading && !filteredData.length);
    }
  }, [data?.getAlertsHistogramData, isLoading]);

  if (isMobile) {
    return null;
  }

  return (
    <Box data-testid="alerts-histogram-wrapper" className="py-5">
      <Box
        className="h-full w-full px-4 pt-8 pb-4 md:px-6 md:pt-0 md:pb-3 lg:px-16"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Box data-testid="alerts-histogram-header">
          <Typography sx={{ color: "var(--header-text)", fontWeight: 600 }}>
            Total Number of Alerts
          </Typography>
        </Box>
        <Box
          data-testid="alerts-histogram-chart"
          className="relative"
          style={{ height: containerHeight }}
        >
          {isLoading && (
            <Box className="absolute bottom-9 left-20">
              <AlertsHistogramSkeleton />
            </Box>
          )}
          {showNoData && (
            <Typography className="text-header-text !font-medium absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              No data available
            </Typography>
          )}

          <ResponsiveContainer>
            <ComposedChart
              data={chartData}
              stackOffset="none"
              barGap={0}
              barSize={24}
            >
              <Label
                value="No data available"
                position="center"
                style={{ transform: `translate(0px, -50%)` }}
              />
              <CartesianGrid stroke={ColorsPalette.Concrete} />
              <XAxis
                dataKey="date"
                tick={chartTickStyle}
                tickLine={false}
                tickFormatter={(tickItem: string) =>
                  formatDateToUTC(new Date(tickItem)).toFormat(DAY_MONTH_FORMAT)
                }
              />
              <YAxis
                yAxisId="alert-histogram"
                type="number"
                orientation="left"
                tick={chartTickStyle}
                width={yAxisWidth}
              />
              <Tooltip
                offset={-5}
                content={<ChartTooltip type={ChartType.AlertsHistogram} />}
              />
              <Bar
                yAxisId="alert-histogram"
                dataKey="incidents"
                fill={ColorsPalette.PrimaryBlue}
                stackId="a"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Box>
  );
};
