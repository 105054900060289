import { Dispatch } from "react";
import { GridColDef } from "@mui/x-data-grid/models";
import { QueryClient, QueryObserverResult } from "@tanstack/react-query";
import slug from "slug";
import { PAGE_SNACKBAR } from "../../../constants";
import { FILTER_OPERATOR_VALUE } from "../../../constants/map";
import {
  AssetInstallEvent,
  AssetInstallStatus,
  AssetTransferEvent,
  Maybe,
  Report,
  TableColumn,
  TableColumnFormat,
  ReportSubscribers,
  AssetReportDataRow,
  FilterListInput,
  FiltersInput,
  DwellHierarchyTableData,
  ReportAlertHistoryTableData,
  AlertSubscribersV2,
} from "../../../graphql/operations";
import { TableGridColDef } from "../../../shared/components/Table";
import {
  checkIsValidDate,
  flattenWildcardStructure,
  formatDate,
  HierarchyNode,
  mapServerErrorCodeToHumanReadableMessage,
} from "../../../utils";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { SchedulingRepeat } from "../../../utils/scheduling";
import { ReportParameters } from "../interfaces";

export const orderColumns = (
  targetIndex: number,
  field: string,
  columns: TableColumn[]
) => {
  const elementIndex = columns.findIndex((el) => el.field === field);
  const element = columns[elementIndex];
  const orderedArr = [...columns];
  orderedArr.splice(elementIndex, 1);
  orderedArr.splice(targetIndex, 0, element);

  return { orderedArr };
};

export const convertTimeTo24Hour = (time12h: string) => {
  if (!time12h || time12h.length === 1) return "";
  const [time, modifier] = time12h.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = (parseInt(hours, 10) + 12).toString();
  } else {
    hours = hours.length === 1 ? "0" + hours : hours;
  }
  return `${hours}:${minutes}`;
};

export const convertTimeTo12Hour = (time: string) => {
  const convertedTime = new Date("1970-01-01T" + time + "Z").toLocaleTimeString(
    "en-US",
    {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    }
  );
  let [hours, minutes] = convertedTime.split(":");
  if (hours.length === 1) {
    hours = "0" + hours;
  }
  return `${hours}:${minutes}`;
};
export const fillHourOptions = () => {
  const hourOptions = [];
  for (let i = 1; i <= 12; i++) {
    const hour = i < 10 ? `0${i}` : i;

    hourOptions.push({
      label: `${hour}:00`,
      value: `${hour}:00`,
    });
    hourOptions.push({
      label: `${hour}:30`,
      value: `${hour}:30`,
    });
  }
  // Move 12:00 and 12:30 to the top of the list
  const secondItemToMove = hourOptions.pop();
  const firstItemToMove = hourOptions.pop();
  hourOptions.splice(0, 0, firstItemToMove!);
  hourOptions.splice(1, 0, secondItemToMove!);
  return hourOptions;
};

export const getAssetInstallStatusText = (status: AssetInstallStatus) => {
  switch (status) {
    case AssetInstallStatus.Installed:
      return "Installed";
    case AssetInstallStatus.Uninstalled:
      return "Uninstalled";
    default:
      return "";
  }
};

export const getColumnsModel = (
  getColumns: (
    timezone: string
  ) => (
    | ReportGridColDef
    | TableGridColDef<ReportGridColTableData | ReportAlertHistoryTableData>
  )[]
) => {
  const columns = getColumns("");
  const model: { [k: string]: TableColumn } = {};
  columns.forEach((col) => {
    model[col.field] = {
      field: col.field,
      label: col.headerName as string,
      format: col.format,
      disableExport: col.disableExport,
    };
  });
  return model;
};

export type ReportGridColDef = GridColDef<
  AssetReportDataRow | AssetTransferEvent | AssetInstallEvent
> & { format: TableColumnFormat };

export type ReportGridColTableData = DwellHierarchyTableData;

export const fillEveryOptions = () => {
  const everyOptions = [];
  for (let i = 1; i <= 99; i++) {
    everyOptions.push({
      label: `${i}`,
      value: `${i}`,
    });
  }
  return everyOptions;
};

export const fillOnOptions = () => {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  return days.map((day, index) => ({
    label: day,
    value: (index + 1).toString(),
  }));
};

export const getScheduleOnParam = (frequency: string) => {
  switch (frequency) {
    case SchedulingRepeat.Daily:
      return "DAY";
    case SchedulingRepeat.Weekly:
      return "WEEKDAY";
    case SchedulingRepeat.Monthly:
      return "MONTH";
    case SchedulingRepeat.Yearly:
      return "YEAR";
    default:
      return "";
  }
};

export const getScheduleEachParam = (
  frequency: string,
  on: number[],
  repeat: string
) => {
  switch (frequency) {
    case SchedulingRepeat.Daily: {
      if (repeat === "1") {
        return on;
      } else {
        return [repeat];
      }
    }
    case SchedulingRepeat.Weekly:
      return "WEEK";
    case SchedulingRepeat.Monthly:
      return "MONTH";
    case SchedulingRepeat.Yearly:
      return "YEAR";
    default:
      return "";
  }
};
type BuildOccurenceStringParamsProps = {
  endDate?: Maybe<Date>;
  frequency?: string;
  on?: string[];
  repeat?: string;
  startDate?: Maybe<Date>;
};

type buildOccurrenceStringParamsReturn = {
  occurrence: string;
  starting: string;
  ending: string;
};
export const buildOccurrenceStringParams = ({
  endDate,
  frequency,
  on,
  repeat,
  startDate,
}: BuildOccurenceStringParamsProps): buildOccurrenceStringParamsReturn => {
  let occurrence = "";
  const starting =
    startDate && checkIsValidDate(startDate)
      ? formatDate(new Date(startDate), "EEEE, MMMM d, yyyy")
      : "";
  const ending =
    endDate && checkIsValidDate(endDate)
      ? formatDate(new Date(endDate), "EEEE, MMMM d, yyyy")
      : "";
  const onString = on?.length
    ? on
        .map((value) => {
          const option = fillOnOptions().find(
            (option) => option.value === value
          );
          return option?.label;
        })
        .join(", ")
    : "";
  switch (frequency) {
    case SchedulingRepeat.Daily: {
      const onLength = on && on.length;
      if (onLength === 7) {
        occurrence = "every day";
        break;
      }
      if (repeat && repeat !== "1") {
        occurrence = `every ${repeat} days`;
      }
      break;
    }
    case SchedulingRepeat.Weekly: {
      occurrence = `every ${repeat} week(s) on ${onString}`;
      break;
    }
    case SchedulingRepeat.Monthly: {
      occurrence = startDate
        ? `on day ${formatDate(
            new Date(startDate),
            "d"
          )} every ${repeat} month(s)`
        : "";
      break;
    }
    case SchedulingRepeat.Yearly: {
      occurrence = startDate
        ? `every ${formatDate(new Date(startDate), "MMMM d")}`
        : "";
      break;
    }
    case SchedulingRepeat.Never: {
      occurrence = "once";
    }
  }

  return {
    occurrence,
    starting,
    ending,
  };
};

type SuccessCallbackProps = {
  report: Report;
  queryClient: QueryClient;
  dispatch: Dispatch<any>;
  setReport: (report: Report) => void;
  setLoading: (loading: boolean) => void;
  availableOrgsHierarchy: HierarchyNode[];
  refetchReportData: () => Promise<QueryObserverResult>;
};

export const onSuccessUpdateCallbackHandler = async ({
  report,
  queryClient,
  dispatch,
  setReport,
  availableOrgsHierarchy,
  setLoading,
  refetchReportData,
}: SuccessCallbackProps) => {
  await queryClient.invalidateQueries(["getReportParamsOrgs"], {
    refetchType: "all",
  });
  await queryClient.invalidateQueries(["getReport"], {
    refetchType: "all",
  });

  refetchReportData();

  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Success!",
      text: "Report was updated successfully!",
      severity: "success",
    },
  });
  const parameters =
    report.parameters &&
    typeof report.parameters === "string" &&
    JSON.parse(report.parameters);
  setReport({
    ...report,
    parameters: {
      ...parameters,
      orgIds: flattenWildcardStructure(
        parameters?.orgIds ?? [],
        availableOrgsHierarchy
      ),
    },
  });
};

type SuccessCallbackCreateProps = {
  dispatch: Dispatch<any>;
  created: Report;
  queryClient: QueryClient;
  shouldCreateReport: boolean;
  navigate: (path: string) => void;
  setLoading: (loading: boolean) => void;
  setShouldCreateReport: (shouldCreateReport: boolean) => void;
};

export const onSuccessCreateCallbackHandler = async ({
  dispatch,
  created,
  queryClient,
  shouldCreateReport,
  navigate,
  setLoading,
  setShouldCreateReport,
}: SuccessCallbackCreateProps) => {
  await queryClient.invalidateQueries(["getReport"], {
    refetchType: "all",
  });
  if (shouldCreateReport) {
    navigate(
      `${NavigationRoutes.Reports}/${slug(created.name)}/${created._id}`
    );
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Report was created successfully!",
        severity: "success",
      },
    });
    setLoading(false);
    setShouldCreateReport(false);
  }
};

export const combineSelectedChips = (
  subscribers?: ReportSubscribers | AlertSubscribersV2
) =>
  subscribers
    ? [...subscribers.users, ...subscribers.roles, ...subscribers.emails]
    : [];

type CreateReportOnErrorHandlerProps = {
  error: unknown;
  setLoading: (loading: boolean) => void;
  dispatch: Dispatch<any>;
};

export const createReportOnErrorHandler = ({
  error,
  setLoading,
  dispatch,
}: CreateReportOnErrorHandlerProps) => {
  setLoading(false);
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Error creating report",
      text: mapServerErrorCodeToHumanReadableMessage(
        error instanceof Error ? error.message : "Something Went Wrong."
      ),
      severity: "error",
    },
  });
};

export const prepareReportParamsForSaving = (
  params: ReportParameters | undefined
) => (params ? JSON.stringify(params) : undefined);

// function to transform the column field to the format expected by the server
// e.g. 'asset[0].name' => 'asset.0.name'
export const transformColumnField = (field: string) => {
  return field.replace(/\[(\d+)\]/g, ".$1");
};
