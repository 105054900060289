import { ReportType } from "../../graphql/operations";

export const ReportTypeTitle: { [key in ReportType]: string } = {
  [ReportType.AssetTransfer]: "Asset Transfer",
  [ReportType.AssetInstall]: "Asset Install",
  [ReportType.AssetInventory]: "Asset Inventory",
  [ReportType.AlertHistory]: "Alert History",
  [ReportType.Mileage]: "Mileage",
  [ReportType.AssetYard]: "Asset Yard",
  [ReportType.OutsideGeofence]: "Outside of Geofence",
  [ReportType.Accounts]: "Accounts",
  [ReportType.DwellHierarchy]: "Dwell Hierarchy",
  [ReportType.MediaActivities]: "Media Activities",
  [ReportType.AssetAbsFaultHistory]: "Asset ABS Fault History",
  [ReportType.Sensors]: "Sensors",
  [ReportType.TravelEvents]: "Travel Events",
  [ReportType.AssetBalance]: "Asset Balance",
  [ReportType.AssetDetention]: "Asset Detention",
};
