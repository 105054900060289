import { DistanceUnit } from "../../graphql/operations";
import { useUserData } from "./useUserData";

export const useGetDistanceUnitPreference = (): DistanceUnit => {
  const userData = useUserData();

  const distanceUnit = userData?.customerOrg?.distance_unit_preference;

  return distanceUnit ?? DistanceUnit.Kilometers;
};
